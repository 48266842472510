import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Proprio from "@mui/icons-material/PermContactCalendar";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EmojiPeople from "@mui/icons-material/EmojiPeople";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import Euro from "@mui/icons-material/Euro";
import FlashOn from "@mui/icons-material/FlashOn";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch.jsx";
import SelectInput from "components/CustomSelect/SelectInput.jsx";
import PhoneInput from "components/PhoneInput/PhoneInput";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import Validation from "Utils/ValidationUtils";
import FinancementLogementForm from "views/Forms/FinancementLogement/FinancementLogementForm";
import ConventionnementLogementForm from "views/Forms/ConventionnementLogement/ConventionnementLogementForm";
import InfoPopover from "components/InfoPopover/InfoPopover";
import TextInput from "components/CustomInput/TextInput";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import LogementUtils from "Utils/LogementUtils";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import UserSelectSearch from "components/CustomSelect/UserSelectSearch";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import { withUserContext } from "context/UserContext";
import AdresseForm from "./Adresse/AdresseForm";

class LogementsForm extends React.Component {
  constructor(props) {
    super(props);
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/logements";
    this.FRONT_URL = "/tiers/logements";
    this.USER = props.user;
    const prospecteurId = this.USER.is("prospection") ? this.USER.id : "";
    this.DPE_VALUE = ["A", "B", "C", "D", "E", "F", "G"];

    this.state = {
      // NESTED DATA
      financements: [],
      conventionnements: [],
      adresse: null,
      // DATA FORM
      type: "",
      typologie_logement_id: "",
      surface_principale: "",
      detail_charges: "",
      montant_charges_mensuelles: "",
      dpe_date: "",
      dpe_date_validite: "",
      dpe_etiquette: "",
      dpe_consommation: "",
      proprietaire_id: +this.QUERY_PARAMS.get("proprietaire_id") || "",
      chances_signature: null,
      numero_lot: "",
      numero_lot_cave: "",
      has_cave: false,
      has_balcon: false,
      has_terrasse: false,
      surface_terrasse: "",
      surface_balcon: "",
      surface_cave: "",
      ascenseur: "",
      acces_pmr: "",
      cuisine_separee: "",
      wc_independant: "",
      sdb: "",
      chaudiere_gaz_individuel: "",
      chaudiere_contrat_estime: "",
      assurance_contrat_estime: "",
      etat_logement_id: "",
      pdl: "",
      digicode: "",
      digicode_code: "",
      syndic_nom: "",
      syndic_tel: "",
      syndic_email: "",
      detecteur_fumee: "",
      description: "",
      ancien_locataire: "",
      loyer_hc_bail_separe: "",
      loyer_hc_parc_prive: "",
      loyer_hc_soliha: "",
      resultat: "",
      refus_date: "",
      refus_description: "",
      date_disponibilite: "",
      prospecteur_id: prospecteurId ?? "",
      convention_id: "",
      stationnement: "",
      numero_lot_stationnement: "",
      pno: "",
      pno_assureur: "",
      pno_numero: "",
      av_contact: "",
      av_chiffrage: "",
      av_visite: "",
      av_en_cours_montage: "",
      av_signature: "",
      origine_contact_id: "",
      origine_contact_info: "",
      situation_appartement: "",
      raison_refus_id: "",
      ref_externe: "",
      numero_fiscal: "",
      infos: "",
      // END DATA
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        state.chances_signature = result.data.chances_signature; // component do not handle ""
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeAdresse = (newAdresse) => {
    this.setState((prevState) => ({
      adresse: { ...prevState.adresse, ...newAdresse },
    }));
  };

  handleChangeNumber = (event) => {
    let val = event.target.value;
    val = val.replace(/,/g, ".");
    this.setState({ [event.target.name]: val });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeCheckbox = (event) => {
    this.handleChangeByName(event.target.name, event.target.checked);
  };

  // Financement
  getDefaultFinancement = () => {
    return {
      id: "",
      financeur_id: "",
      convention_id: "",
      date_debut: "",
      date_fin: "",
    };
  };

  // Conventionnement
  getDefaultConventionnement = () => {
    return {
      id: "",
      date_debut_conventionnement: "",
      date_fin_conventionnement: "",
      duree_conventionnement: "",
      numero_conventionnement: "",
      date_envoi_dossier: "",
      conventionnement_loyer_max: "",
      conventionnement_id: "",
      type_conventionnement_id: "",
      travaux: false,
    };
  };

  handleAddFinancement = () => {
    this.setState((prevState) => {
      let financements = prevState.financements;
      financements.push(this.getDefaultFinancement());
      return { financements: financements };
    });
  };
  handleChangeFinancement = (key, name, value) => {
    this.setState((prevState) => {
      let financements = prevState.financements;
      financements[key][name] = value;
      return { financements: financements };
    });
  };
  handleRemoveFinancement = (key) => {
    this.setState((prevState) => {
      let financements = prevState.financements;
      financements.splice(key, 1);
      return { financements: financements };
    });
  };

  handleAddConventionnement = () => {
    this.setState((prevState) => {
      let conventionnements = prevState.conventionnements;
      conventionnements.push(this.getDefaultConventionnement());
      return { conventionnements: conventionnements };
    });
  };
  handleChangeConventionnement = (key, name, value) => {
    this.setState((prevState) => {
      let conventionnements = prevState.conventionnements;
      conventionnements[key][name] = value;
      return { conventionnements: conventionnements };
    });
  };
  handleRemoveConventionnement = (key) => {
    this.setState((prevState) => {
      let conventionnements = prevState.conventionnements;
      conventionnements.splice(key, 1);
      return { conventionnements: conventionnements };
    });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      adresse: this.state.adresse,
      type: this.state.type,
      typologie_logement_id: this.state.typologie_logement_id,
      surface_principale: this.state.surface_principale,
      detail_charges: this.state.detail_charges,
      montant_charges_mensuelles: this.state.montant_charges_mensuelles,
      dpe_date: this.state.dpe_date,
      dpe_date_validite: this.state.dpe_date_validite,
      dpe_etiquette: this.state.dpe_etiquette,
      dpe_consommation: this.state.dpe_consommation,
      dpe_emission_co2: this.state.dpe_emission_co2,
      proprietaire_id: this.state.proprietaire_id,
      chances_signature: this.state.chances_signature,
      numero_lot: this.state.numero_lot,
      has_cave: this.state.has_cave,
      has_balcon: this.state.has_balcon,
      has_terrasse: this.state.has_terrasse,
      numero_lot_cave: this.state.has_cave ? this.state.numero_lot_cave : "",
      surface_cave: this.state.has_cave ? this.state.surface_cave : "",
      surface_terrasse: this.state.has_terrasse
        ? this.state.surface_terrasse
        : "",
      surface_balcon: this.state.has_balcon ? this.state.surface_balcon : "",
      ascenseur: this.state.ascenseur,
      acces_pmr: this.state.acces_pmr,
      cuisine_separee: this.state.cuisine_separee,
      wc_independant: this.state.wc_independant,
      sdb: this.state.sdb,
      chaudiere_gaz_individuel: this.state.chaudiere_gaz_individuel,
      chaudiere_contrat_estime: this.state.chaudiere_contrat_estime,
      assurance_contrat_estime: this.state.assurance_contrat_estime,
      etat_logement_id: this.state.etat_logement_id,
      pdl: this.state.pdl,
      digicode: this.state.digicode,
      digicode_code: this.state.digicode_code,
      syndic_nom: this.state.syndic_nom,
      syndic_tel: this.state.syndic_tel,
      syndic_email: this.state.syndic_email,
      detecteur_fumee: this.state.detecteur_fumee,
      description: this.state.description,
      ancien_locataire: this.state.ancien_locataire,
      loyer_hc_bail_separe: this.state.loyer_hc_bail_separe,
      loyer_hc_parc_prive: this.state.loyer_hc_parc_prive,
      loyer_hc_soliha: this.state.loyer_hc_soliha,
      resultat: this.state.resultat,
      refus_date: this.state.refus_date,
      refus_description: this.state.refus_description,
      date_disponibilite: this.state.date_disponibilite,
      prospecteur_id: this.state.prospecteur_id,
      convention_id: this.state.convention_id,
      stationnement: this.state.stationnement,
      numero_lot_stationnement: this.state.numero_lot_stationnement,
      pno: this.state.pno,
      pno_assureur: this.state.pno_assureur,
      pno_numero: this.state.pno_numero,
      av_contact: this.state.av_contact,
      av_chiffrage: this.state.av_chiffrage,
      av_visite: this.state.av_visite,
      av_en_cours_montage: this.state.av_en_cours_montage,
      av_signature: this.state.av_signature,
      origine_contact_id: this.state.origine_contact_id,
      origine_contact_info: this.state.origine_contact_info,
      situation_appartement: this.state.situation_appartement,
      financements: this.state.financements,
      conventionnements: this.state.conventionnements,
      raison_refus_id: this.state.raison_refus_id,
      ref_externe: this.state.ref_externe,
      numero_fiscal: this.state.numero_fiscal,
      infos: this.state.infos,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { id, adresse, errors, financements, conventionnements } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Typography variant="h6" component="h5">
            {this.state.id ? (
              <>Modifier le Logement LP{this.state.id}</>
            ) : (
              <>Ajouter un Logement</>
            )}
          </Typography>
        </GridItem>
        {errors && (
          <GridItem xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </GridItem>
        )}
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <LibraryBooks />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <AdresseForm
                  adresse={adresse}
                  onChange={this.handleChangeAdresse}
                  errors={errors}
                  isEditMode={Boolean(id)}
                  hideRegion
                />
                <GridItem xs={12}>
                  <HeaderTitle label="Logement" />
                </GridItem>
                <GridItem xs={12}>
                  <SelectInput
                    label="Type de bien"
                    name="type"
                    onChange={this.handleChange}
                    value={this.state.type}
                    error={Boolean(errors?.type)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {LogementUtils.TYPE.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Numéro fiscal"
                    name="numero_fiscal"
                    onChange={this.handleChange}
                    value={this.state.numero_fiscal}
                    error={Boolean(errors?.numero_fiscal)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Numéro de lot"
                    name="numero_lot"
                    onChange={this.handleChange}
                    value={this.state.numero_lot}
                    error={Boolean(errors?.numero_lot)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Référence externe"
                    name="ref_externe"
                    onChange={this.handleChange}
                    value={this.state.ref_externe}
                    error={Boolean(errors?.ref_externe)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectSearch
                    name="typologie_logement_id"
                    label="Typologie du logement"
                    apiUrl="/config-typologies"
                    onChange={this.handleChangeByName}
                    value={this.state.typologie_logement_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.typologie_logement_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Surface habitable"
                    name="surface_principale"
                    onChange={this.handleChangeNumber}
                    value={this.state.surface_principale}
                    error={Boolean(errors?.surface_principale)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <CheckBoxInput
                    label="Cave"
                    name="has_cave"
                    value={this.state.has_cave}
                    onChange={this.handleChangeByName}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  {Boolean(this.state.has_cave) && (
                    <TextInput
                      label="Surface cave"
                      name="surface_cave"
                      onChange={this.handleChangeNumber}
                      value={this.state.surface_cave}
                      error={Boolean(errors?.surface_cave)}
                    />
                  )}
                </GridItem>
                {Boolean(this.state.has_cave) && (
                  <GridItem xs={12}>
                    <TextInput
                      label="Numéro de lot cave"
                      name="numero_lot_cave"
                      onChange={this.handleChange}
                      value={this.state.numero_lot_cave}
                      error={Boolean(errors?.numero_lot_cave)}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} lg={6}>
                  <CheckBoxInput
                    label="Balcon"
                    name="has_balcon"
                    value={this.state.has_balcon}
                    onChange={this.handleChangeByName}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  {Boolean(this.state.has_balcon) && (
                    <TextInput
                      label="Surface balcon"
                      name="surface_balcon"
                      onChange={this.handleChangeNumber}
                      value={this.state.surface_balcon}
                      error={Boolean(errors?.surface_balcon)}
                    />
                  )}
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <CheckBoxInput
                    label="Terrasse"
                    name="has_terrasse"
                    value={this.state.has_terrasse}
                    onChange={this.handleChangeByName}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  {Boolean(this.state.has_terrasse) && (
                    <TextInput
                      label="Surface terrasse"
                      name="surface_terrasse"
                      onChange={this.handleChangeNumber}
                      value={this.state.surface_terrasse}
                      error={Boolean(errors?.surface_terrasse)}
                    />
                  )}
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Stationnement" />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Stationnement"
                    name="stationnement"
                    onChange={this.handleChange}
                    value={this.state.stationnement}
                    error={Boolean(errors?.stationnement)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {LogementUtils.STATIONNEMENT.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </GridItem>
                {this.state.stationnement !== "" &&
                  this.state.stationnement !==
                    LogementUtils.STATIONNEMENT[0] && (
                    <GridItem xs={12} lg={6}>
                      <TextInput
                        label="Numéro de lot"
                        name="numero_lot_stationnement"
                        onChange={this.handleChange}
                        value={this.state.numero_lot_stationnement}
                        error={Boolean(errors?.numero_lot_stationnement)}
                      />
                    </GridItem>
                  )}
                <GridItem xs={12}>
                  <HeaderTitle label="Prestations" />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Ascenseur"
                    name="ascenseur"
                    onChange={this.handleChange}
                    value={this.state.ascenseur}
                    error={Boolean(errors?.ascenseur)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Acces PMR"
                    name="acces_pmr"
                    onChange={this.handleChange}
                    value={this.state.acces_pmr}
                    error={Boolean(errors?.acces_pmr)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="WC independant"
                    name="wc_independant"
                    onChange={this.handleChange}
                    value={this.state.wc_independant}
                    error={Boolean(errors?.wc_independant)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Salle de Bain"
                    name="sdb"
                    onChange={this.handleChange}
                    value={this.state.sdb}
                    error={Boolean(errors?.sdb)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {LogementUtils.SDB.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Cuisine séparée"
                    name="cuisine_separee"
                    onChange={this.handleChange}
                    value={this.state.cuisine_separee}
                    error={Boolean(errors?.cuisine_separee)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Détecteur de fumée"
                    name="detecteur_fumee"
                    onChange={this.handleChange}
                    value={this.state.detecteur_fumee}
                    error={Boolean(errors?.detecteur_fumee)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Digicode"
                    name="digicode"
                    onChange={this.handleChange}
                    value={this.state.digicode}
                    error={Boolean(errors?.digicode)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                {this.state.digicode === 1 ? (
                  <GridItem xs={12} lg={6}>
                    <TextInput
                      label="Code"
                      name="digicode_code"
                      onChange={this.handleChange}
                      value={this.state.digicode_code}
                      error={Boolean(errors?.digicode_code)}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} lg={6} />
                )}
                <GridItem xs={12}>
                  <HeaderTitle label="Chaudière" />
                </GridItem>
                <GridItem xs={12}>
                  <SelectInput
                    label="Chaudière gaz individuel"
                    name="chaudiere_gaz_individuel"
                    onChange={this.handleChange}
                    value={this.state.chaudiere_gaz_individuel}
                    error={Boolean(errors?.chaudiere_gaz_individuel)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Diagnostic" />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Date DPE"
                    name="dpe_date"
                    type="date"
                    onChange={this.handleChange}
                    value={this.state.dpe_date}
                    error={Boolean(errors?.dpe_date)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Date de fin de validité DPE"
                    name="dpe_date_validite"
                    type="date"
                    onChange={this.handleChange}
                    value={this.state.dpe_date_validite}
                    error={Boolean(errors?.dpe_date_validite)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Etiquette énergétique DPE"
                    name="dpe_etiquette"
                    onChange={this.handleChange}
                    value={this.state.dpe_etiquette}
                    error={Boolean(errors?.dpe_etiquette)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {this.DPE_VALUE.map((value, key) => (
                      <MenuItem key={key} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Consommation Énergétique"
                    name="dpe_consommation"
                    onChange={this.handleChangeNumber}
                    value={this.state.dpe_consommation}
                    error={Boolean(errors?.dpe_consommation)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Assurance Propriétaire" />
                </GridItem>
                <GridItem xs={12}>
                  <SelectInput
                    label="Assurance pno"
                    name="pno"
                    onChange={this.handleChange}
                    value={this.state.pno}
                    error={Boolean(errors?.pno)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                {Boolean(this.state.pno) && (
                  <>
                    <GridItem xs={12}>
                      <TextInput
                        label="Assureur PNO"
                        name="pno_assureur"
                        onChange={this.handleChange}
                        value={this.state.pno_assureur}
                        error={Boolean(errors?.pno_assureur)}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextInput
                        label="Numero PNO"
                        name="pno_numero"
                        onChange={this.handleChange}
                        value={this.state.pno_numero}
                        error={Boolean(errors?.pno_numero)}
                      />
                    </GridItem>
                  </>
                )}
                <GridItem xs={12}>
                  <HeaderTitle label="Divers" />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Ancien locataire"
                    name="ancien_locataire"
                    onChange={this.handleChange}
                    value={this.state.ancien_locataire}
                    error={Boolean(errors?.ancien_locataire)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Point de livraison"
                    name="pdl"
                    onChange={this.handleChange}
                    value={this.state.pdl}
                    error={Boolean(errors?.pdl)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Informations complémentaires"
                    name="infos"
                    multiline
                    minRows={2}
                    maxRows={8}
                    onChange={this.handleChange}
                    value={this.state.infos}
                    error={Boolean(errors?.infos)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Loyer et charges
                  <InfoPopover
                    id="loyer-charges-popover"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <Typography textAlign="center">
                      Informations indicatives pour la Prospection et la Cal.
                    </Typography>
                  </InfoPopover>
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <HeaderTitle label="Loyer" />
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <TextInput
                    label="Loyer HC soliha"
                    name="loyer_hc_soliha"
                    onChange={this.handleChangeNumber}
                    value={this.state.loyer_hc_soliha}
                    error={Boolean(errors?.loyer_hc_soliha)}
                  />
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <TextInput
                    label="Loyer HC parc privé"
                    name="loyer_hc_parc_prive"
                    onChange={this.handleChangeNumber}
                    value={this.state.loyer_hc_parc_prive}
                    error={Boolean(errors?.loyer_hc_parc_prive)}
                  />
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <TextInput
                    label="Loyer HC bail séparé"
                    name="loyer_hc_bail_separe"
                    onChange={this.handleChangeNumber}
                    value={this.state.loyer_hc_bail_separe}
                    error={Boolean(errors?.loyer_hc_bail_separe)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Charges" />
                </GridItem>
                <GridItem xs={12}>
                  <CurrencyInput
                    label="Chaudière contrat estimé"
                    name="chaudiere_contrat_estime"
                    onChange={this.handleChange}
                    value={this.state.chaudiere_contrat_estime}
                    error={Boolean(errors?.chaudiere_contrat_estime)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CurrencyInput
                    label="Assurance contrat estimé"
                    name="assurance_contrat_estime"
                    onChange={this.handleChange}
                    value={this.state.assurance_contrat_estime}
                    error={Boolean(errors?.assurance_contrat_estime)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CurrencyInput
                    label="Charges mensuelles estimées"
                    name="montant_charges_mensuelles"
                    onChange={this.handleChange}
                    value={this.state.montant_charges_mensuelles}
                    error={Boolean(errors?.montant_charges_mensuelles)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Détail des charges"
                    name="detail_charges"
                    multiline
                    maxRows={8}
                    onChange={this.handleChange}
                    value={this.state.detail_charges}
                    error={Boolean(errors?.detail_charges)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <FlashOn />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Description
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <SelectSearch
                    label="État du logement"
                    name="etat_logement_id"
                    apiUrl="/config-etats-logements"
                    onChange={this.handleChangeByName}
                    value={this.state.etat_logement_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.etat_logement_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Situation / Emplacement"
                    name="situation_appartement"
                    onChange={this.handleChange}
                    value={this.state.situation_appartement}
                    error={Boolean(errors?.situation_appartement)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Description"
                    name="description"
                    multiline
                    minRows={2}
                    maxRows={8}
                    onChange={this.handleChange}
                    value={this.state.description}
                    error={Boolean(errors?.description)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <Proprio />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Syndic
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <TextInput
                    label="Nom"
                    name="syndic_nom"
                    onChange={this.handleChange}
                    value={this.state.syndic_nom}
                    error={Boolean(errors?.syndic_nom)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <PhoneInput
                    label="Téléphone"
                    name="syndic_tel"
                    value={this.state.syndic_tel}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.syndic_tel)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Email"
                    name="syndic_email"
                    onChange={this.handleChange}
                    value={this.state.syndic_email}
                    error={Boolean(errors?.syndic_email)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Proprio />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Propriétaire
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <SelectSearch
                    name="proprietaire_id"
                    label="Propriétaire"
                    apiUrl="/proprietaires"
                    onChange={this.handleChangeByName}
                    value={this.state.proprietaire_id}
                    buildOptionLabel={(data) =>
                      (data.prenom ?? "") + " " + (data.nom ?? "")
                    }
                    shrink
                    error={Boolean(errors?.proprietaire_id)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <RecordVoiceOver />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Prospection
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <UserSelectSearch
                    label="Prospecteur"
                    name="prospecteur_id"
                    apiUrl="/user-list/prospecteurs"
                    onChange={this.handleChangeByName}
                    value={this.state.prospecteur_id}
                    error={Boolean(errors?.prospecteur_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectSearch
                    label="Origine contact"
                    name="origine_contact_id"
                    apiUrl="/config-origine-contact-logements"
                    onChange={this.handleChangeByName}
                    value={this.state.origine_contact_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.origine_contact_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Origine contact description"
                    name="origine_contact_info"
                    onChange={this.handleChange}
                    value={this.state.origine_contact_info}
                    error={Boolean(errors?.origine_contact_info)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Avancement" />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Contact"
                    name="av_contact"
                    type="date"
                    onChange={this.handleChange}
                    value={this.state.av_contact}
                    error={Boolean(errors?.av_contact)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    label="Chiffrage"
                    control={
                      <Checkbox
                        name="av_chiffrage"
                        checked={Boolean(this.state.av_chiffrage)}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    labelPlacement="start"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    label="Visite"
                    control={
                      <Checkbox
                        name="av_visite"
                        checked={Boolean(this.state.av_visite)}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    labelPlacement="start"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    label="En cours de montage"
                    control={
                      <Checkbox
                        name="av_en_cours_montage"
                        checked={Boolean(this.state.av_en_cours_montage)}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    labelPlacement="start"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    label="Signature"
                    control={
                      <Checkbox
                        name="av_signature"
                        checked={Boolean(this.state.av_signature)}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    labelPlacement="start"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Résultat" />
                </GridItem>
                <GridItem xs={12}>
                  <Typography gutterBottom>
                    Chances de Signature : {this.state.chances_signature}%
                  </Typography>
                  <Slider
                    name="chances_signature"
                    onChange={(event, value) => {
                      this.handleChangeByName("chances_signature", value);
                    }}
                    value={this.state.chances_signature}
                    aria-labelledby="chances_signature-slider"
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => value + "%"}
                    step={5}
                    marks
                    min={0}
                    max={100}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Date prévisionelle de disponibilité"
                    name="date_disponibilite"
                    type="date"
                    onChange={this.handleChange}
                    value={this.state.date_disponibilite}
                    error={Boolean(errors?.date_disponibilite)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectInput
                    label="Résultat"
                    name="resultat"
                    onChange={this.handleChange}
                    value={this.state.resultat}
                    error={Boolean(errors?.resultat)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {LogementUtils.RESULTAT.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </GridItem>
                {this.state.resultat === "refuse" && (
                  <>
                    <GridItem xs={12} lg={6}>
                      <TextInput
                        label="Date refus"
                        name="refus_date"
                        type="date"
                        onChange={this.handleChange}
                        value={this.state.refus_date}
                        error={Boolean(errors?.refus_date)}
                      />
                    </GridItem>
                    <GridItem xs={12} lg={6}>
                      <SelectSearch
                        label="Raison du refus"
                        name="raison_refus_id"
                        apiUrl="/config-raison-refus"
                        onChange={this.handleChangeByName}
                        value={this.state.raison_refus_id}
                        buildOptionLabel={(data) => data.value}
                        shrink
                        error={Boolean(errors?.raison_refus_id)}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextInput
                        label="Description refus"
                        name="refus_description"
                        multiline
                        maxRows={8}
                        onChange={this.handleChange}
                        value={this.state.refus_description}
                        error={Boolean(errors?.refus_description)}
                      />
                    </GridItem>
                  </>
                )}
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <EmojiPeople />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Conventionnements Anah
                </Typography>
              }
            />
            <CardContent>
              <ConventionnementLogementForm
                conventionnements={conventionnements}
                addRow={this.handleAddConventionnement}
                updateRow={this.handleChangeConventionnement}
                removeRow={this.handleRemoveConventionnement}
                errors={Validation.parse(errors, "conventionnements.")}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <MonetizationOn />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Financements
                </Typography>
              }
            />
            <CardContent>
              <FinancementLogementForm
                financements={financements}
                addRow={this.handleAddFinancement}
                updateRow={this.handleChangeFinancement}
                removeRow={this.handleRemoveFinancement}
                errors={Validation.parse(errors, "financements.")}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <EventNoteIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Commission Attribution Logement
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <SelectSearch
                    name="convention_id"
                    label="Convention"
                    apiUrl="/config-conventions-logements"
                    onChange={this.handleChangeByName}
                    value={this.state.convention_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.convention_id)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

LogementsForm.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(LogementsForm);
