import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import MenuBottom from "components/MenuBottom/MenuBottom";
import ConfigAlertTable from "components/Table/ConfigAlertTable";
import { withUserContext } from "context/UserContext";
import Button from "components/Button/Button";

class AlertImmobilierConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/alertes-immobilier";
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <ConfigAlertTable
            type="immobilier"
            channel="database"
            cardTitle="Alertes Immobilier"
            name="alerte"
            backUrl="/config-alertes"
            frontUrl={this.FRONT_URL}
          />
          <ConfigAlertTable
            type="immobilier"
            channel="mail"
            cardTitle="Emails Immobilier"
            name="email"
            backUrl="/config-alertes"
            frontUrl={this.FRONT_URL}
          />
        </GridItem>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigAlert"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </GridContainer>
    );
  }
}

AlertImmobilierConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(AlertImmobilierConfig);
