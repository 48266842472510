import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import FindInPage from "@mui/icons-material/FindInPage";
import Home from "@mui/icons-material/Home";
import Person from "@mui/icons-material/Person";
import Business from "@mui/icons-material/Business";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";
import Today from "@mui/icons-material/Today";
import Delete from "@mui/icons-material/Delete";
import BarChart from "@mui/icons-material/BarChart";
import Edit from "@mui/icons-material/Edit";
import List from "@mui/icons-material/List";
import ManageSearch from "@mui/icons-material/ManageSearch";
import Description from "@mui/icons-material/Description";

// Services
import SearchBarService from "services/SearchBarService";
import Render from "Utils/RenderUtils";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import TravauxLinkCard from "components/LinkCard/TravauxLinkCard";
import DisableLink from "components/DisableLink/DisableLink";
import Evenements from "components/Evenements/Evenements";
import CheckBoxShow from "components/CheckBoxShow/CheckBoxShow";
import AlertDialog from "components/AlertDialog/AlertDialog";
import AuditResultatView from "./AuditResultatView";
import Ged from "views/Tables/Ged";
import MenuBottom from "components/MenuBottom/MenuBottom";
import InfoPopover from "components/InfoPopover/InfoPopover";
import DocumentTemplate from "components/Table/DocumentTemplate";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import Stack from "@mui/material/Stack";
import AuditAvenantListView from "./AuditAvenantList/AuditAvenantListView";
import AuditUtils from "Utils/AuditUtils";
import DispositifView from "./Dispositif/DispositifView";
import { withUserContext } from "context/UserContext";

class AuditsView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.audit");
    this.CAN_EDIT = this.USER.can("edit.audit");
    this.CAN_VIEW_EVENEMENTS = this.USER.canOne([
      "view.evenement.travaux",
      "edit.evenement.travaux",
    ]);
    this.CAN_EDIT_EVENEMENTS = this.USER.can("edit.evenement.travaux");
    this.CAN_USE_AD = this.USER.can("use.audit");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    // Ged AD permissions
    this.GED_AD_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.travaux.ad"),
      CAN_EDIT: this.USER.can("edit.ged.travaux.ad"),
      CAN_DELETE: this.USER.can("delete.ged.travaux.ad"),
    };

    this.BACKEND_URL = "/audits";
    this.FRONT_URL = "/audits";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);
    this.state = {
      // NESTED DATA
      logement: null,
      technicien: null,
      conseiller: null,
      donneurordres: null,
      dossier_travaux_avant: null,
      auditresultats: [],
      raison_abandon_ad: {},
      avenant: null,
      list_avenants: [],
      all_dispositifs: [],
      // DATA FORM
      code_identification: "",
      annee: "",
      date_redaction: "",
      date_demande: "",
      date_visite: "",
      date_butoir: "",
      date_envoi: "",
      type_audit: "",
      resultat: "",
      description: "",
      statut: "",
      reference_externe: "",
      consommation_energetique: "",
      frais_paiement: "",
      frais_prevu: "",
      frais_facture: "",
      date_facture: "",
      date_paiement: "",
      sous_type: "",
      is_avenant: false,
      etiquette_ges: "",
      co2: "",
      // END DATA FORM
      id: Number(props.match.params.id),
      alert: null,
      dt_actif: null,
      entityLists: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id, {
        params: {
          withAllDispositifs: true,
        },
      })
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        state.entityLists = {
          dossierTravaux: result.data?.logement?.dossiers_travaux || [],
        };
        this.setState(state);
      });
  }

  //Remove Audits
  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const data = err.response.data;
          this.onError(data);
        }
      });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer l'audit ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  onError = (data) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer l'audit : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {data.avenant && (
              <li>
                1 avenant associé{" "}
                <Link to={this.FRONT_URL + "/detail/" + data.avenant.id}>
                  AD{data.avenant.id}
                </Link>
              </li>
            )}
            {data.dt > 0 && <li>{data.dt} dossier(s) travaux associé(s)</li>}
          </ul>
        </AlertDialog>
      ),
    });
  };

  generateAvenantDialog = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Générer un avenant"
          onConfirm={this.generateAvenant}
          confirmLabel="Générer"
          confirmColor="primary"
          confirmDelay={5}
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="gray"
        >
          <Typography>
            Êtes-vous sûr(e) de vouloir générer un avenant pour cet audit ?
            Cette action créera un nouvel audit en utilisant les mêmes données
            que celui-ci. Après avoir cliqué sur le bouton <b>Générer</b>, vous
            serez redirigé(e) vers l&apos;avenant généré. (Les frais ainsi que
            les résultats des bouquets travaux ne seront pas reportés).
          </Typography>
        </AlertDialog>
      ),
    });
  };

  generateAvenant = () => {
    axiosApiBackend
      .post(this.BACKEND_URL + "/create-avenant/" + this.state.id)
      .then((res) => {
        this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
      });
  };

  render() {
    const {
      id,
      logement,
      dossier_travaux_avant,
      technicien,
      conseiller,
      donneurordres,
      frais_facture,
      frais_paiement,
      raison_abandon_ad,
      dt_actif,
      avenant,
      list_avenants,
      is_avenant,
      type_audit,
      all_dispositifs,
    } = this.state;

    const beneficiaire = logement?.beneficiaire;
    const evenementsLink = "/evenements/travaux/audit/" + id;

    const type = AuditUtils.TYPE_AUDIT.find((item) => item.name === type_audit);
    const sousTypes = type?.sous_types;

    const recommandedDispositifs = all_dispositifs.filter(
      (dispositif) => dispositif.is_recommended,
    );

    return (
      <GridContainer>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar
                  sx={{ bgcolor: beneficiaire?.categorie_ressource?.color_mpr }}
                >
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Bénéficiaire
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Prénom : </strong>
                {beneficiaire?.prenom}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {beneficiaire?.nom}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(beneficiaire?.tel)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(beneficiaire?.mail)}
              </Typography>
              <Typography>
                <strong>Statut de propriété : </strong>
                {beneficiaire?.statut_propriete?.value}
              </Typography>
              <Typography>
                <strong>Catégorie de ressources : </strong>
                {beneficiaire?.categorie_ressource?.value}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <FindInPage />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Audit/Diagnostic AD{id} :{" "}
                  <strong>{AuditUtils.renderStatut(this.state.statut)}</strong>
                </Typography>
              }
            />
            <CardContent>
              <Typography variant="h4" align="center">
                {Boolean(is_avenant) && "Avenant "}
                {this.state.type_audit}
              </Typography>
              {sousTypes != null && (
                <Typography align="center">{this.state.sous_type}</Typography>
              )}
              <HeaderTitle label="Suivi" />
              {this.state.statut === AuditUtils.STATUT[5] && (
                <Typography>
                  <strong>Raison abandon : </strong>
                  {raison_abandon_ad?.value}
                </Typography>
              )}
              <Typography>
                <strong>Date de demande : </strong>
                {Render.date(this.state.date_demande)}
              </Typography>
              <Typography>
                <strong>Date de visite : </strong>
                {Render.date(this.state.date_visite)}
              </Typography>
              <Typography>
                <strong>Date butoir : </strong>
                {Render.date(this.state.date_butoir)}
              </Typography>
              <Typography>
                <strong>Date d&apos;envoi : </strong>
                {Render.date(this.state.date_envoi)}
              </Typography>
              <HeaderTitle label="Résultat" />
              <Typography>
                <strong>Date de rédaction : </strong>
                {Render.date(this.state.date_redaction)}
              </Typography>
              <Typography>
                <strong>Résultat : </strong>
                {this.state.resultat}
              </Typography>
              {type_audit === AuditUtils.TYPE_AUDIT[1].name && (
                <>
                  <Typography>
                    <strong>Consommation énergétique : </strong>
                    {Render.ifNotEmpty(
                      this.state.consommation_energetique,
                      "",
                      " kWh/m²/an",
                    )}
                  </Typography>
                  <Typography>
                    <strong>Étiquette GES : </strong>
                    {this.state.etiquette_ges}
                  </Typography>
                  <Typography>
                    <strong>CO2 : </strong>
                    {Render.ifNotEmpty(this.state.co2, "", " CO2/m²/an")}
                  </Typography>
                </>
              )}
              <Typography>
                <strong>Informations complémentaires : </strong>
              </Typography>
              <MultilineTypography sx={{ px: 1 }}>
                {this.state.description}
              </MultilineTypography>
              {!avenant && (
                <Stack direction="row" justifyContent="center">
                  <Button size="small" onClick={this.generateAvenantDialog}>
                    Générer un avenant
                  </Button>
                </Stack>
              )}
            </CardContent>
          </Card>
          {this.state.auditresultats.length > 0 && (
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar>
                    <BarChart />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    Propositions d&apos;améliorations de l&apos;habitat
                  </Typography>
                }
              />
              <CardContent>
                {this.state.auditresultats.map((auditResultat, key) => (
                  <AuditResultatView
                    key={auditResultat.id}
                    data={auditResultat}
                    estimation={key}
                    isConsommationEnergetique={
                      this.state.type_audit === AuditUtils.TYPE_AUDIT[1].name
                    }
                    averageConso={
                      this.state.type_audit === AuditUtils.TYPE_AUDIT[1].name
                        ? AuditUtils.averageConsoEnergy(
                            this.state.consommation_energetique,
                            auditResultat.consommation_energetique,
                          )
                        : null
                    }
                    averageCo2={
                      this.state.type_audit === AuditUtils.TYPE_AUDIT[1].name
                        ? AuditUtils.averageConsoEnergy(
                            this.state.co2,
                            auditResultat.co2,
                          )
                        : null
                    }
                  />
                ))}
              </CardContent>
            </Card>
          )}
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Adresse : </strong>
                {Render.address(logement?.adresse)}
              </Typography>
              <Typography>
                <strong>Ville : </strong>
                {logement?.adresse?.ville?.cp} {logement?.adresse?.ville?.ville}
              </Typography>
              <Typography>
                <strong>Type : </strong>
                {logement?.type?.value}
              </Typography>
              <Typography>
                <strong>Typologie : </strong>
                {logement?.typologie?.value}
              </Typography>
              <Typography>
                <strong>Surface avant travaux : </strong>
                {dossier_travaux_avant?.surface_avant
                  ? dossier_travaux_avant?.surface_avant + " m²"
                  : ""}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <RecordVoiceOver />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Donneur d&apos;ordres
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Donneur d&apos;ordres : </strong>
                {donneurordres && donneurordres.nom}
              </Typography>
              <Typography>
                <strong>Code d&apos;identification : </strong>
                {this.state.code_identification}
              </Typography>
              <Typography>
                <strong>Référence externe / BDC : </strong>
                {this.state.reference_externe}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Business />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  SOLIHA
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Référence : </strong>
                AD{id}
              </Typography>
              <Typography>
                <strong>Technicien en charge : </strong>
                {Render.fullName(technicien)}
              </Typography>
              <Typography>
                <strong>Conseiller en charge : </strong>
                {Render.fullName(conseiller)}
              </Typography>
              <HeaderTitle label="Facturation" />
              <Typography>
                <strong>Frais prévus : </strong>
                {Render.euro(this.state.frais_prevu)}
              </Typography>
              <Typography>
                <strong>Date de facturation : </strong>
                {Render.date(this.state.date_facture)}
              </Typography>
              <Typography>
                <strong>Frais facturés : </strong>
                {Render.euro(frais_facture)}
              </Typography>
              <Typography>
                <strong>Date de paiement : </strong>
                {Render.date(this.state.date_paiement)}
              </Typography>
              <CheckBoxShow
                checked={
                  frais_facture !== 0 && !frais_facture && !frais_paiement
                    ? null
                    : frais_facture === frais_paiement
                }
                label="Frais payés"
                data={Render.euro(frais_paiement || 0)}
              />
            </CardContent>
          </Card>
          <DispositifView
            dispositifs={recommandedDispositifs}
            ville={logement?.adresse?.ville}
          />
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <ManageSearch />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Historique des avenants
                </Typography>
              }
            />
            <CardContent>
              <AuditAvenantListView
                avenants={list_avenants}
                currentAuditId={id}
              />
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <TravauxLinkCard
            beneficiaire={beneficiaire}
            logement={logement}
            dossierTravaux={dt_actif}
            hideAudit={true}
            hideAuditApres={true}
            entityLists={this.state.entityLists}
          />
          {this.CAN_USE_AD && (
            <DocumentTemplate baseURL={this.FRONT_URL} objectId={id} />
          )}
          {this.GED_AD_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="travaux"
              type="AD"
              objectId={id}
              permissions={this.GED_AD_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
          {id && this.CAN_VIEW_EVENEMENTS && (
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar>
                    <Today />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    Évènements{" "}
                    {Boolean(is_avenant) && (
                      <InfoPopover
                        id="evenements-avenant-popover"
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "right",
                        }}
                      >
                        <Typography>
                          Les évènements affichés correspondent à ceux de
                          l&apos;audit d&apos;origine. Tout évènement ajouté sur
                          cet avenant sera créé dans l&apos;audit
                          d&apos;origine.
                        </Typography>
                      </InfoPopover>
                    )}
                  </Typography>
                }
                action={
                  <Link to={evenementsLink + "?form=open"}>
                    <Button size="small">
                      {this.CAN_EDIT_EVENEMENTS ? "Ajouter" : "Voir"}
                    </Button>
                  </Link>
                }
              />
              <CardContent>
                <Link to={evenementsLink}>
                  <Evenements modelId={id} modelType="audit" dense limit={5} />
                </Link>
              </CardContent>
            </Card>
          )}
        </GridItem>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button size="small" color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Audit&id=" + this.state.id}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </GridContainer>
    );
  }
}

AuditsView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(AuditsView);
