import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";

// @mui/icons-material
import GetApp from "@mui/icons-material/GetApp";
import Euro from "@mui/icons-material/Euro";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import FileUtils from "Utils/FileUtils";
import Render from "Utils/RenderUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import MenuBottom from "components/MenuBottom/MenuBottom";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import AgGridUtils from "Utils/AgGridUtils";
import debounce from "@mui/material/utils/debounce";
import CPUtils from "Utils/CPUtils";
import SelectSearch from "components/CustomSelect/SelectSearch";
import IRLUtils from "Utils/IRLUtils";
import SelectInput from "components/CustomSelect/SelectInput";
import SearchBar from "components/SearchBar/SearchBar";

class OrdresPaiementsCP extends Component {
  constructor(props) {
    super(props);

    this.BACKEND_URL = "/contrats-proprietaires";
    this.FRONT_URL = "/banque/ordres/cp";
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.NO_YES = ["Non", "Oui"];
    let now = new Date();
    this.NOWDAY = now.getDate();

    this.gridApi = null;
    this.gridColumnApi = null;

    this.state = {
      bank_label: null,
      bank2_label: null,
      bankNbr: "",
      type: "",
      operationId: "",
      operationData: null,
      total: 0,
      nbTransaction: 0,
      openValidAlert: false,
      transactions: [],
      errors: null,
      loading: false,
      alert: null,
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: "PRENOM",
          field: "logement.proprietaire.prenom",
        },
        {
          headerName: "NOM",
          field: "logement.proprietaire.nom",
        },
        {
          headerName: "MONTANT PAIEMENT",
          field: "solde_du",
        },
        {
          headerName: "SOLDE",
          field: "solde",
        },
        {
          headerName: "JOUR DE PAIEMENT",
          field: "jour_paiement",
          sort: "asc",
        },
        {
          headerName: "DÉBUT DE CONTRAT",
          field: "date_bail",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "FIN DE CONTRAT",
          field: "date_fin",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "TYPE",
          field: "type",
        },
        {
          headerName: "Durée garanties",
          field: "duree_mandat",
          valueFormatter: (params) =>
            params.value < 1200 ? params.value : "illimité",
        },
        {
          headerName: "GLI",
          field: "gli",
          valueFormatter: (params) => this.NO_YES[params.value] ?? "",
        },
        {
          headerName: "ADRESSE",
          field: "adresse",
          valueGetter: (params) =>
            Render.address(params.data.logement?.adresse),
        },
        {
          headerName: "VILLE",
          field: "logement.adresse.ville.ville",
        },
        {
          headerName: "Type Révision",
          field: "type_revision",
        },
        {
          headerName: "Date Révision",
          field: "date_revision",
          valueGetter: (params) =>
            params.data.date_revision +
            (params.data.date_revision === CPUtils.DATE_REVISION[4]
              ? " - " + Render.dayMonth(params.data.date_revision_custom)
              : ""),
        },
        {
          headerName: "Type paiement",
          field: "type_paiement",
        },
        {
          headerName: "LOYER ORIGINE",
          field: "loyer_origine",
        },
        {
          headerName: "IRL origine",
          field: "irl_origine",
          valueGetter: (params) => IRLUtils.irl(params.data.irl_origine),
        },
        {
          headerName: "DEPOT DE GARANTIE",
          field: "depot_de_garantie",
        },
        {
          headerName: "DG CONSERVÉ",
          field: "dg_conserve",
          valueFormatter: (params) => this.NO_YES[params.value],
        },
        {
          headerName: "MENSUALITE LOYER",
          field: "mensualite_loyer",
        },
        {
          headerName: "MENSUALITE CHARGES",
          field: "mensualite_charges",
        },
        {
          headerName: "MENSUALITE GARAGE",
          field: "mensualite_garage",
        },
        {
          headerName: "MENSUALITE CHARGES GARAGE",
          field: "mensualite_charges_garage",
        },
        {
          headerName: "MENSUALITE ORDURES",
          field: "mensualite_ordures",
        },
        {
          headerName: "MENSUALITE GESTION",
          field: "mensualite_gestion",
        },
        {
          headerName: "MOYEN PAIEMENT",
          field: "moyen_paiement",
        },
        {
          headerName: "LOGEMENT ID",
          field: "logement_id",
        },
      ]),
      defaultColDef: {
        resizable: true,
        sortable: true,
      },
      data: null,
      getRowStyle: (params) => {
        if (params.data.jour_paiement <= this.NOWDAY) {
          return { color: "red" };
        }
      },
      quickFilterText: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
    this.getConfigData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL, {
        params: {
          filters: ["ordrespaiements"],
          type: this.state.type,
        },
      })
      .then((result) => {
        this.setState({ data: result.data }, () => {
          setTimeout(() => {
            this.checkPastPaymentDate();
          }, 1);
        });
      });
  }

  getConfigData = () => {
    axiosApiBackend.get("/settings/general").then((result) => {
      this.setState({
        bank_label: result.data?.bank_label ?? null,
        bank2_label: result.data?.bank2_label ?? null,
        bankNbr: 1,
      });
    });
  };

  checkPastPaymentDate = () => {
    if (this.gridApi == null) {
      return;
    }

    const newFormateDate = new Date().getTime();
    this.gridApi.forEachNode((node) => {
      //Date Management

      const contratsLocataires =
        node.data.logement != null
          ? node.data.logement.contratslocataires
          : null;

      const dateBail = node.data.date_bail;

      const lastContratLocataire =
        contratsLocataires.length != 0
          ? contratsLocataires[contratsLocataires.length - 1]
          : null;

      const date_entree = lastContratLocataire
        ? lastContratLocataire.date_entree
        : null;

      const isSelected =
        node.data.jour_paiement &&
        dateBail &&
        date_entree &&
        node.data.jour_paiement <= this.NOWDAY &&
        Date.parse(date_entree) <= newFormateDate &&
        Date.parse(dateBail) <= newFormateDate;

      node.setSelected(Boolean(isSelected));
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeContrat = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.loadAsyncData();
    });
  };

  handleChangeOperation = (name, value, data) => {
    this.setState({
      [name]: value,
      operationData: data,
    });
  };

  resetSearchValue = () => {
    this.setState({ quickFilterText: null });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  onSelectionChanged = debounce(() => {
    let selectedRows = this.gridApi.getSelectedRows();
    let usefullRows = selectedRows.filter((row) => row.solde_du > 0);
    let total = usefullRows.reduce((acc, row) => (acc += row.solde_du), 0);
    this.setState({
      total: total,
      nbTransaction: usefullRows.length,
    });
  }, 100);

  generateFile = () => {
    this.setState({
      loading: true,
      errors: null,
      openValidAlert: false,
    });
    let selectedRows = this.gridApi.getSelectedRows();
    let cpIds = selectedRows.map((row) => row.id);
    axiosApiBackend
      .post("/banque/ordres-paiements/cp", {
        cpIds: cpIds,
        bankNbr: this.state.bankNbr,
        operationId: this.state.operationId,
      })
      .then((res) => {
        const file = res.data.file ?? {};
        const transactions = res.data.transactions ?? [];
        FileUtils.downLoadFile(file.content, file.headers, "file.xml");
        this.echeancierAlert(transactions);
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          const errors = err.response?.data?.errors;
          this.setState({ loading: false, errors: errors });
          if (errors?.cpIds?.length > 0) {
            this.errorAlert({ message: "Veuillez selectionner des Contrats." });
          }
        } else {
          this.errorAlert(err.response?.data?.error);
        }
      });
  };

  echeancierAlert = (transactions) => {
    this.setState({
      loading: false,
      openValidAlert: true,
      transactions: transactions,
    });
  };

  updateEcheanciers = (transactions) => {
    this.setState({ loading: true });

    axiosApiBackend
      .post("/contrats-proprietaires/ordres-paiements", {
        operationId: this.state.operationId,
        paiments: transactions,
      })
      .then(() => {
        this.successAlert();
        this.loadAsyncData();
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          this.setState({ loading: false, errors: err.response?.data?.errors });
        } else {
          this.errorAlert(err.response?.data?.error);
        }
      });
  };

  clearAlert = () => {
    this.setState({ alert: null, openValidAlert: false });
  };

  errorAlert = (err) => {
    const message = err?.message ?? "";
    const cpIds = err?.cps ?? [];

    this.setState({
      loading: false,
      openValidAlert: false,
      alert: (
        <AlertDialog
          title={"Erreur : " + (message ?? "")}
          onCancel={this.clearAlert}
          cancelLabel="OK"
          cancelColor="error"
        >
          {cpIds.length > 0 && (
            <ul>
              {cpIds.map((id, key) => (
                <li key={key}>
                  <Link
                    to={"/contrats-proprietaires/modifier/" + id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CP{id}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </AlertDialog>
      ),
    });
  };

  successAlert = () => {
    this.setState({
      loading: false,
      openValidAlert: false,
      alert: (
        <AlertDialog
          title="Mise à jour des échéanciers réussi !"
          onConfirm={this.clearAlert}
          confirmLabel="OK"
          confirmColor="success"
        />
      ),
    });
  };

  render() {
    const { openValidAlert, operationData, transactions, loading, errors } =
      this.state;

    const operationLabel = operationData?.value ? operationData?.label : null;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Virements Propriétaires
                </Typography>
              }
              action={
                <Button
                  sx={{ mt: 2 }}
                  color="success"
                  onClick={this.generateFile}
                  loading={loading}
                >
                  Générer Fichier Ordres de Paiements
                </Button>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.handleChange}
                    resetSearchValue={this.resetSearchValue}
                  />
                </GridItem>
                <GridItem>
                  <SelectInput
                    displayEmpty
                    sx={{ minWidth: "200px" }}
                    label="Type de contrat"
                    name="type"
                    value={this.state.type}
                    onChange={this.handleChangeContrat}
                    error={Boolean(errors?.type)}
                    margin="dense"
                    size="small"
                  >
                    <MenuItem value="" key="">
                      Tous
                    </MenuItem>
                    {CPUtils.TYPES.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </GridItem>
                <GridItem>TOTAL : {Render.euro(this.state.total)}</GridItem>
                <GridItem>
                  Nombre de transations : {this.state.nbTransaction}
                </GridItem>
                <GridItem>
                  <SelectInput
                    label="Compte bancaire"
                    name="bankNbr"
                    value={this.state.bankNbr}
                    onChange={this.handleChange}
                    margin="dense"
                    size="small"
                  >
                    {this.state.bank_label && (
                      <MenuItem value={1}>{this.state.bank_label}</MenuItem>
                    )}
                    {this.state.bank2_label && (
                      <MenuItem value={2}>{this.state.bank2_label}</MenuItem>
                    )}
                  </SelectInput>
                </GridItem>
                <GridItem>
                  <SelectSearch
                    sx={{ minWidth: "200px" }}
                    label="Opération de Paiement"
                    name="operationId"
                    apiUrl="/config-operations?onlyPaiementProprietaireDebit=1"
                    onChange={this.handleChangeOperation}
                    value={this.state.operationId}
                    buildOptionLabel={(data) => data.label}
                    shrink
                    error={Boolean(errors?.operationId)}
                    margin="dense"
                    size="small"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                style={{
                  minHeight: "300px",
                }}
              >
                <GridItem xs={12} className="ag-theme-material">
                  <AgGridReact
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    rowSelection="multiple"
                    rowMultiSelectWithClick={true}
                    domLayout="autoHeight"
                    paginationPageSize="30"
                    onPaginationChanged={this.autoSizeAll}
                    onSelectionChanged={this.onSelectionChanged}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <MenuBottom>
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
        </MenuBottom>
        {this.state.alert}
        <AlertDialog
          open={openValidAlert}
          title={
            <>
              Assurez-vous que le fichier généré soit valide.
              <br />
              Voulez-vous mettre à jour les échéanciers de tous les Contrats
              Propriétaires{" "}
              {operationLabel ? (
                <>avec le label {operationLabel} ?</>
              ) : (
                <>
                  ? <b>Veuillez sélectionner un label.</b>
                </>
              )}
              <br />
              Un email du décompte sera envoyé aux Propriétaires.
            </>
          }
          onConfirm={() => this.updateEcheanciers(transactions)}
          confirmLabel="Oui"
          confirmColor="success"
          confirmDelay={5}
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="error"
          loading={loading}
        >
          <ul>
            {transactions.map((t, key) => (
              <li key={key}>
                CP{t.id} : {Render.euro(t.amount)}
              </li>
            ))}
          </ul>
        </AlertDialog>
      </GridContainer>
    );
  }
}

OrdresPaiementsCP.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default OrdresPaiementsCP;
