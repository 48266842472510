export default class CPUtils {
  static TYPES = ["Sous-location", "Mandat de gestion", "MGGS"];
  static TYPE_REVISION = [
    "Manuel / Pas de révision",
    "Automatique base CP",
    "Automatique base CL",
    "Automatique dissocié pour le CL",
  ];
  static TYPE_REVISION_FORM = [
    { text: this.TYPE_REVISION[0], tooltip: null },
    {
      text: this.TYPE_REVISION[1],
      tooltip:
        "Permet de faire des révisions automatiques du loyer en utilisant la date de bail/mandat comme date d'entrée.",
    },
    {
      text: this.TYPE_REVISION[2],
      tooltip:
        "Permet de faire des révisions automatiques du loyer en utilisant la date d'entrée du CL comme date d'entrée.",
    },
    {
      text: this.TYPE_REVISION[3],
      tooltip:
        "Permet de configurer des révisions automatiques différentes pour le CP et pour le CL. Pensez à configurer la révision du CL par la suite.",
    },
  ];
  static TYPE_PAIEMENT = ["Paiement à échoir", "Paiement à terme échu"];

  static DATE_REVISION = [
    "Pas de révision",
    "1er janvier",
    "Date de bail du CP",
    "Date d'entrée du CL",
    "Date personnalisée",
  ];

  static REVISION_TOOLTIP =
    "Calcul de révision du loyer : Nouveau loyer = loyer en cours x nouvel IRL du trimestre de référence du contrat / IRL du même trimestre de l'année précédente. Dès 2023, gel des révisions de loyers pour DPE G > 450 kWhEF/m²/an. En 2025, classe F requise. En 2028, classe E requise. En 2034, classe D requise";
  static IRL_TOOLTIP =
    "Permet de choisir le trimestre qui déterminera quels Indices de Révision de Loyer (IRL) utiliser pour le calcul.";
  static DATE_REVISION_TOOLTIP =
    "Permet de choisir la date effective de la révision du loyer.";

  static ETATS = [
    { name: "en_preparation", label: "En préparation" },
    { name: "en_attente", label: "En attente" },
    {
      name: "actif",
      label: "Actif",
      roles: ["chefgla", "gestionlocative", "chefcesf", "social"],
    },
    { name: "termine", label: "Terminé" },
  ];

  static MOYEN_PAIEMENT = [
    "Aucun",
    "Virement",
    "Prélèvement",
    "Transfert Compte Produit",
  ];

  static isTermeEchu = (typePaiement) => {
    return typePaiement === CPUtils.TYPE_PAIEMENT[1];
  };

  static getInitFilter = (user) => {
    for (const statut of this.ETATS) {
      if (statut.roles && user.isOne(statut.roles)) {
        return [statut.name];
      }
    }
    return [];
  };

  static renderCP = (cp) => {
    if (cp == null) {
      return "";
    }
    return `${cp.type ?? "-"} (CP${cp.id})`;
  };
}
