import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";

const style = {
  grid: {
    padding: "0 15px !important",
  },
};

function GridItem({ ...props }) {
  const { classes, children, className, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid + " " + className}>
      {children}
    </Grid>
  );
}
GridItem.propTypes = {
  classes: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
};

/**
 *  use use mui grid
 */
export default withStyles(style)(GridItem);
