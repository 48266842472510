import React, { Component } from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import GetApp from "@mui/icons-material/GetApp";
import AddBox from "@mui/icons-material/AddBox";
import Savings from "@mui/icons-material/Savings";

// core components
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";
import Render from "Utils/RenderUtils";
import ApurementUtils from "Utils/ApurementUtils";
import SearchBarService from "services/SearchBarService";
import Description from "@mui/icons-material/Description";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AgGridUtils from "Utils/AgGridUtils";
import FoyerUtils from "Utils/FoyerUtils";
import { withUserContext } from "context/UserContext";
import Button from "components/Button/Button";

class Apurements extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/apurements";
    this.FRONT_URL = "/tiers/apurements";
    this.gridApi = null;
    this.gridColumnApi = null;
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.filtersRef = React.createRef();

    this.state = {
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          cellDataType: "number",
          sort: "desc",
        },
        {
          headerName: "Locataire",
          field: "referents_name",
          hide: this.props.dense,
          valueGetter: (params) =>
            FoyerUtils.referentsFullName(
              params.data.contratlocataire &&
                params.data.contratlocataire.foyer &&
                params.data.contratlocataire.foyer.referentsactifs,
            ),
        },
        {
          headerName: "Statut",
          field: "statut",
          valueGetter: (params) =>
            ApurementUtils.renderStatut(params.data.statut) ?? "",
        },
        {
          headerName: "Début",
          field: "date_debut",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.monthYear(params.value),
        },
        {
          headerName: "Total",
          field: "total",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Mensualité",
          field: "mensualite",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Fin",
          field: "date_fin",
          cellDataType: "date",
          valueGetter: (params) =>
            ApurementUtils.getDateFin(
              params.data.date_debut,
              params.data.total,
              params.data.mensualite,
            ),
          valueFormatter: (params) => Render.monthYear(params.value),
        },
        {
          headerName: "Durée (mois)",
          field: "nb_mois",
          cellDataType: "number",
          type: "numericColumn",
          valueGetter: (params) =>
            ApurementUtils.getNbMois(params.data.total, params.data.mensualite),
        },
        {
          headerName: "Raison",
          field: "raison.value",
        },
        {
          headerName: "Description",
          field: "description",
        },
        {
          headerName: "Annulation",
          field: "invalid_date",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
      ]),
      getRowStyle: (params) => {
        if (params.data.statut === 0) {
          return { backgroundColor: "#e2efda" };
        }
      },
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    let config = {};
    if (this.props.clId) {
      config.params = { cl_id: this.props.clId };
    }
    if (this.props.dense && !config.params) {
      // si dense et pas d'id specifique alors on affiche rien (ne devrait pas arrvier)
      this.setState({ data: [] });
      return;
    }
    axiosApiBackend.get(this.BACKEND_URL, config).then((result) => {
      this.setState({ data: result.data }, () => {
        this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
      });
    });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    const { dense } = this.props;
    let addUrl = this.FRONT_URL + "/ajouter";
    if (this.props.clId) {
      const toUrl = new URL("http://localhost");
      toUrl.searchParams.append("cl_id", this.props.clId);
      addUrl += toUrl.search;
    }

    return (
      <Grid container sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Savings />
                </CardAvatar>
              }
              title={
                dense ? (
                  <Typography variant="h6" component="h5">
                    Apurements
                  </Typography>
                ) : (
                  <Typography variant="h5">Gestion des Apurements</Typography>
                )
              }
              action={
                dense ? (
                  <Link to={addUrl}>
                    <Button size="small">Ajouter</Button>
                  </Link>
                ) : null
              }
            />
            <CardContent>
              {!dense && (
                <Grid container alignItems="center" ref={this.filtersRef}>
                  <Grid item>
                    <SearchBar
                      label="Rechercher"
                      name="quickFilterText"
                      value={this.state.quickFilterText}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      resetSearchValue={this.resetSearchValue}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                style={{
                  minHeight: "100px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={dense ? "" : this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {!dense && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/ajouter"}>
              <Button size="small" square round>
                <AddBox />
              </Button>
            </Link>
            <Button
              size="small"
              square
              round
              onClick={() => AgGridUtils.exportCSV(this.gridApi)}
            >
              <GetApp />
            </Button>
            {this.CAN_VIEW_LOG_ACTIVITY && (
              <Link to={this.FRONT_URL + "/logs?type=Apurement"}>
                <Button size="small" square round>
                  <Description />
                </Button>
              </Link>
            )}
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

Apurements.defaultProps = {};

Apurements.propTypes = {
  clId: PropTypes.any,
  dense: PropTypes.bool,
  location: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(Apurements);
