import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Edit from "@mui/icons-material/Edit";
import List from "@mui/icons-material/List";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Delete from "@mui/icons-material/Delete";
import EmojiPeople from "@mui/icons-material/EmojiPeople";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";
import Today from "@mui/icons-material/Today";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import Home from "@mui/icons-material/Home";
import FlashOn from "@mui/icons-material/FlashOn";
import Euro from "@mui/icons-material/Euro";
import Description from "@mui/icons-material/Description";

// Services
import SearchBarService from "services/SearchBarService";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import Evenements from "components/Evenements/Evenements";
import ImmobilierLinkCard from "components/LinkCard/ImmobilierLinkCard";
import Render from "Utils/RenderUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Ged from "views/Tables/Ged";
import MenuBottom from "components/MenuBottom/MenuBottom";
import FinancementLogementView from "views/Forms/FinancementLogement/FinancementLogementView";
import ConventionnementLogementView from "views/Forms/ConventionnementLogement/ConventionnementLogementView";
import CheckBoxShow from "components/CheckBoxShow/CheckBoxShow";
import CalListView from "views/Forms/CalList/CalListView";
import InfoPopover from "components/InfoPopover/InfoPopover";
import LogementUtils from "Utils/LogementUtils";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import { withUserContext } from "context/UserContext";
import AdresseView from "./Adresse/AdresseView";
import DispositifUtils from "Utils/DispositifUtils";

class LogementsView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.logement");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/logements";
    this.FRONT_URL = "/tiers/logements";

    this.NO_YES = ["Non", "Oui"];
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    // Ged Logement permissions
    this.GED_LOGEMENT_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.immobilier.logement"),
      CAN_EDIT: this.USER.can("edit.ged.immobilier.logement"),
      CAN_DELETE: this.USER.can("delete.ged.immobilier.logement"),
    };

    this.state = {
      // NESTED DATA
      adresse: null,
      proprietaire: null,
      contratproprietaire: null,
      typologie: null,
      etat_logement: null,
      contratslocataires: [],
      cals: [],
      convention: null,
      financements: [],
      conventionnements: [],
      origine_contact: null,
      raison_refus: null,
      // DATA FORM
      type: "",
      typologie_logement_id: "",
      surface_principale: "",
      detail_charges: "",
      montant_charges_mensuelles: "",
      dpe_date: "",
      dpe_date_validite: "",
      dpe_etiquette: "",
      dpe_consommation: "",
      dpe_emission_co2: "",
      proprietaire_id: "",
      chances_signature: "",
      numero_lot: "",
      numero_lot_cave: "",
      has_cave: "",
      has_balcon: "",
      has_terrasse: "",
      surface_terrasse: "",
      surface_balcon: "",
      surface_cave: "",
      ascenseur: "",
      acces_pmr: "",
      cuisine_separee: "",
      wc_independant: "",
      sdb: "",
      chaudiere_gaz_individuel: "",
      chaudiere_contrat_estime: "",
      assurance_contrat_estime: "",
      etat_du_logement: "",
      pdl: "",
      digicode: "",
      digicode_code: "",
      syndic_nom: "",
      syndic_tel: "",
      syndic_email: "",
      detecteur_fumee: "",
      description: "",
      ancien_locataire: "",
      loyer_hc_bail_separe: "",
      loyer_hc_parc_prive: "",
      loyer_hc_soliha: "",
      resultat: "",
      refus_date: "",
      refus_description: "",
      date_disponibilite: "",
      prospecteur: "",
      stationnement: "",
      numero_lot_stationnement: "",
      pno: "",
      pno_assureur: "",
      pno_numero: "",
      av_contact: "",
      av_chiffrage: "",
      av_visite: "",
      av_en_cours_montage: "",
      av_signature: "",
      origine_contact_id: "",
      origine_contact_info: "",
      situation_appartement: "",
      ref_externe: "",
      numero_fiscal: "",
      infos: "",
      // END DATA FORM
      contratlocataireActif: null,
      alert: null,
      id: Number(props.match.params.id),
      entityLists: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        state.contratlocataireActif = this.getContratlocataireActif(
          result.data.contratslocataires,
        );
        state.entityLists = {
          contratLocataire: result.data.contratslocataires,
        };
        this.setState(state);
      });
  }

  getContratlocataireActif = (contratslocataires) => {
    let now = new Date();
    const nowDay = now.getDate().toString().padStart(2, "0");
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const today = now.getFullYear() + "-" + month + "-" + nowDay;

    let contratlocataireActif = null;
    for (let cl of contratslocataires) {
      if (cl.date_sortie === null) {
        contratlocataireActif = cl;
        break;
      } else if (cl.date_sortie >= today) {
        contratlocataireActif = cl;
      }
    }
    return contratlocataireActif;
  };

  //Remove Logement
  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le logement ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer ce logement : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.cl > 0 && (
              <li>{nbrEntity.cl} contrat(s) locataire(s) associé(s)</li>
            )}
            {nbrEntity.cp > 0 && (
              <li>{nbrEntity.cp} contrat(s) propriétaire(s) associé(s)</li>
            )}
            {nbrEntity.evenements > 0 && (
              <li>{nbrEntity.evenements} évènement(s) associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const {
      id,
      acces_pmr,
      adresse,
      ancien_locataire,
      ascenseur,
      assurance_contrat_estime,
      av_chiffrage,
      av_contact,
      av_en_cours_montage,
      av_signature,
      av_visite,
      cals,
      chaudiere_contrat_estime,
      chaudiere_gaz_individuel,
      chances_signature,
      contratlocataireActif,
      contratproprietaire,
      convention,
      conventionnements,
      cuisine_separee,
      date_disponibilite,
      digicode,
      detecteur_fumee,
      description,
      detail_charges,
      digicode_code,
      dpe_consommation,
      dpe_date,
      dpe_date_validite,
      dpe_etiquette,
      etat_logement,
      financements,
      has_balcon,
      has_cave,
      has_terrasse,
      infos,
      loyer_hc_bail_separe,
      loyer_hc_parc_prive,
      loyer_hc_soliha,
      montant_charges_mensuelles,
      numero_fiscal,
      numero_lot,
      numero_lot_cave,
      numero_lot_stationnement,
      origine_contact,
      origine_contact_info,
      pdl,
      pno_assureur,
      pno_numero,
      proprietaire,
      prospecteur,
      raison_refus,
      ref_externe,
      refus_date,
      refus_description,
      resultat,
      sdb,
      situation_appartement,
      stationnement,
      surface_balcon,
      surface_cave,
      surface_principale,
      surface_terrasse,
      syndic_email,
      syndic_nom,
      syndic_tel,
      type,
      typologie,
      wc_independant,
    } = this.state;

    const evenementsLink = "/evenements/immobilier/logement/" + id;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Typography variant="h5" component="h4">
            Infos logement LP{id}
            {contratproprietaire && (
              <>
                &nbsp;(CP
                {contratproprietaire.id})
              </>
            )}
          </Typography>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <HeaderTitle label="Adresse" />
              <AdresseView adresse={adresse} hideRegion />
              <HeaderTitle label="Logement" />
              <Typography>
                <strong>Type de bien : </strong>
                {type}
              </Typography>
              <Typography>
                <strong>Numéro fiscal : </strong>
                {numero_fiscal}
              </Typography>
              <Typography>
                <strong>Numéro de lot : </strong>
                {numero_lot}
              </Typography>
              <Typography>
                <strong>Référence externe : </strong>
                {ref_externe}
              </Typography>
              <Typography>
                <strong>Type de logement : </strong>
                {typologie?.value}
              </Typography>
              <Typography>
                <strong>Surface habitable : </strong>
                {Render.carre(surface_principale)}
              </Typography>
              {Boolean(has_cave) && (
                <>
                  <Typography>
                    <strong>Surface cave : </strong>
                    {Render.carre(surface_cave)}
                  </Typography>
                  <Typography>
                    <strong>Numéro de lot cave : </strong>
                    {numero_lot_cave}
                  </Typography>
                </>
              )}
              {Boolean(has_balcon) && (
                <Typography>
                  <strong>Surface balcon : </strong>
                  {Render.carre(surface_balcon)}
                </Typography>
              )}
              {Boolean(has_terrasse) && (
                <Typography>
                  <strong>Surface terrasse : </strong>
                  {Render.carre(surface_terrasse)}
                </Typography>
              )}
              <HeaderTitle label="Stationnement" />
              <Typography>
                <strong>Stationnement : </strong>
                {stationnement}
              </Typography>
              {stationnement !== "" &&
                stationnement !== LogementUtils.STATIONNEMENT[0] && (
                  <Typography>
                    <strong>Numéro de lot : </strong>
                    {numero_lot_stationnement}
                  </Typography>
                )}
              <HeaderTitle label="Prestations" />
              <Typography>
                <strong>Ascenseur : </strong>
                {this.NO_YES[ascenseur]}
              </Typography>
              <Typography>
                <strong>Acces PMR : </strong>
                {this.NO_YES[acces_pmr]}
              </Typography>
              <Typography>
                <strong>Cuisine séparée : </strong>
                {this.NO_YES[cuisine_separee]}
              </Typography>
              <Typography>
                <strong>WC independant : </strong>
                {this.NO_YES[wc_independant]}
              </Typography>
              <Typography>
                <strong>Salle de Bain : </strong>
                {sdb}
              </Typography>
              <Typography>
                <strong>Digicode : </strong>
                {this.NO_YES[digicode]}
              </Typography>
              {digicode === 1 && (
                <Typography>
                  <strong>Digicode Code : </strong>
                  {digicode_code}
                </Typography>
              )}
              <Typography>
                <strong>Détecteur de fumée : </strong>
                {this.NO_YES[detecteur_fumee]}
              </Typography>
              <HeaderTitle label="Chaudière" />
              <Typography>
                <strong>Chaudière gaz individuel : </strong>
                {this.NO_YES[chaudiere_gaz_individuel]}
              </Typography>
              <HeaderTitle label="Diagnostic" />
              <Typography>
                <strong>Date DPE : </strong>
                {Render.date(dpe_date)}
              </Typography>
              <Typography>
                <strong>Date de fin de validité DPE : </strong>
                {Render.date(dpe_date_validite)}
              </Typography>
              <Typography>
                <strong>Etiquette énergétique DPE : </strong>
                {dpe_etiquette}
              </Typography>
              <Typography>
                <strong>Consommation Énergétique : </strong>
                {Render.ifNotEmpty(dpe_consommation, "", " kWh/m²/an")}
              </Typography>
              <HeaderTitle label="Assurance Propriétaire" />
              <Typography>
                <strong>PNO : </strong>
                {this.NO_YES[this.state.pno]}
              </Typography>
              <Typography>
                <strong>Assureur : </strong>
                {pno_assureur}
              </Typography>
              <Typography>
                <strong>Numero : </strong>
                {pno_numero}
              </Typography>
              <HeaderTitle label="Divers" />
              <Typography>
                <strong>Ancien locataire : </strong>
                {ancien_locataire}
              </Typography>
              <Typography>
                <strong>Point de livraison : </strong>
                {pdl}
              </Typography>
              <Typography>
                <strong>Informations complémentaires : </strong>
              </Typography>
              <MultilineTypography sx={{ px: 1 }}>{infos}</MultilineTypography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <Today />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Évènements
                </Typography>
              }
              action={
                <Link to={evenementsLink + "?form=open"}>
                  <Button size="small">Ajouter</Button>
                </Link>
              }
            />
            <CardContent>
              <Link to={evenementsLink}>
                <Evenements modelId={id} modelType="logement" dense limit={5} />
              </Link>
            </CardContent>
          </Card>
          {this.GED_LOGEMENT_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="immobilier"
              type="logement"
              objectId={id}
              permissions={this.GED_LOGEMENT_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Loyer et charges
                  <InfoPopover
                    id="loyer-charges-popover"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <Typography textAlign="center">
                      Informations indicatives pour la Prospection et la Cal.
                    </Typography>
                  </InfoPopover>
                </Typography>
              }
            />
            <CardContent>
              <HeaderTitle label="Loyer" />
              <Typography>
                <strong>Loyer HC soliha : </strong>
                {Render.euro(loyer_hc_soliha)}
              </Typography>
              <Typography>
                <strong>Loyer HC parc privé : </strong>
                {Render.euro(loyer_hc_parc_prive)}
              </Typography>
              <Typography>
                <strong>Loyer HC bail séparé : </strong>
                {Render.euro(loyer_hc_bail_separe)}
              </Typography>
              <HeaderTitle label="Charges" />
              <Typography>
                <strong>Contrat chaudière estimé : </strong>
                {Render.euro(chaudiere_contrat_estime)}
              </Typography>
              <Typography>
                <strong>Contrat assurance estimé : </strong>
                {Render.euro(assurance_contrat_estime)}
              </Typography>
              <Typography>
                <strong>Charges mensuelles estimées : </strong>
                {Render.euro(montant_charges_mensuelles)}
              </Typography>
              <Typography>
                <strong>Détail des charges : </strong>
                {detail_charges}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <FlashOn />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Description
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>État du logement : </strong>
                {etat_logement?.value}
              </Typography>
              <Typography>
                <strong>Situation / Emplacement : </strong>
                {situation_appartement}
              </Typography>
              <Typography>
                <strong>Description : </strong>
              </Typography>
              <MultilineTypography sx={{ px: 1 }}>
                {description}
              </MultilineTypography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <LibraryBooks />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Syndic
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Nom : </strong>
                {syndic_nom}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(syndic_tel)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(syndic_email)}
              </Typography>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <ImmobilierLinkCard
            proprietaire={proprietaire}
            logement={{ id: id }}
            contratProprietaire={contratproprietaire}
            contratLocataire={contratlocataireActif}
            hideLogement={true}
            entityLists={this.state.entityLists}
          />
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <RecordVoiceOver />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Prospection
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Prospecteur : </strong>
                {Render.fullName(prospecteur)}
              </Typography>
              <Typography>
                <strong>Origine contact : </strong>
                {origine_contact?.value}
              </Typography>
              <Typography>
                <strong>Origine contact info : </strong>
                {origine_contact_info}
              </Typography>
              <HeaderTitle label="Avancement" />
              <CheckBoxShow
                label="Contact"
                data={Render.date(av_contact)}
                checked={av_contact}
              />
              <CheckBoxShow label="Chiffrage" checked={av_chiffrage} />
              <CheckBoxShow label="Visite" checked={av_visite} />
              <CheckBoxShow
                label="En cours de montage"
                checked={av_en_cours_montage}
              />
              <CheckBoxShow label="Signature" checked={av_signature} />
              <HeaderTitle label="Résultat" />
              <Typography>
                <strong>Chances de Signature : </strong>
                {chances_signature && <>{chances_signature}%</>}
              </Typography>
              <Typography>
                <strong>Date prévisionelle de disponibilité : </strong>
                {Render.date(date_disponibilite)}
              </Typography>
              <Typography>
                <strong>Résultat : </strong>
                {resultat}
              </Typography>
              {resultat === "refuse" && (
                <>
                  <Typography>
                    <strong>Date refus : </strong>
                    {Render.date(refus_date)}
                  </Typography>
                  <Typography>
                    <strong>Raison du refus : </strong>
                    {raison_refus?.value}
                  </Typography>
                  <Typography>
                    <strong>Description refus : </strong>
                  </Typography>
                  <MultilineTypography sx={{ px: 1 }}>
                    {refus_description}
                  </MultilineTypography>
                </>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <EmojiPeople />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Conventionnement Anah
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Zone ANAH : </strong>
                {DispositifUtils.renderAnah(adresse?.ville)}
              </Typography>
              <ConventionnementLogementView
                conventionnements={conventionnements}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <MonetizationOn />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Financement
                </Typography>
              }
            />
            <CardContent>
              <FinancementLogementView financements={financements} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <EventNoteIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Commission Attribution Logement
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Convention : </strong>
                {convention?.value}
              </Typography>
              <CalListView cals={cals} />
            </CardContent>
          </Card>
        </GridItem>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Logement&id=" + id}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {alert}
      </GridContainer>
    );
  }
}

LogementsView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(LogementsView);
