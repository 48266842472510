import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Settings from "@mui/icons-material/Settings";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import SettingsFormContainer from "components/SettingsFormContainer/SettingsFormContainer";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import IbanIMaskInput from "components/CustomInput/IbanIMaskInput";
import NumberInput from "components/CustomInput/NumberInput";
import TextInput from "components/CustomInput/TextInput";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";

class GeneralSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // DATA FORM
      org_nom: "",
      numero_voie: "",
      type_voie: "",
      nom_voie: "",
      cp: "",
      ville: "",
      siret: "",
      code_ape: "",
      ics: "",
      numero_cpi: "",
      bank_label: "",
      bank_name: "",
      bank_bic: "",
      bank_iban: "",
      bank2_label: "",
      bank2_name: "",
      bank2_bic: "",
      bank2_iban: "",
      bi_direction: "",
      bi_cesf: "",
      bi_gla: "",
      bi_travaux: "",
      // END DATA FORM
      errors: null,
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeIban = (event) => {
    let { name, value } = event.target;
    value = value.replace(/[. ,]/g, "");
    this.setState({ [name]: value });
  };

  render() {
    const { errors } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <SettingsFormContainer
            icon={<Settings />}
            title="Modifier les Informations Soliha"
            backendUrl="/settings/general"
            formSetData={(data) => {
              this.setState({
                errors: null,
                org_nom: data.org_nom ?? "",
                numero_voie: data.numero_voie ?? "",
                type_voie: data.type_voie ?? "",
                nom_voie: data.nom_voie ?? "",
                cp: data.cp ?? "",
                ville: data.ville ?? "",
                siret: data.siret ?? "",
                code_ape: data.code_ape ?? "",
                ics: data.ics ?? "",
                numero_cpi: data.numero_cpi ?? "",
                bank_label: data.bank_label ?? "",
                bank_name: data.bank_name ?? "",
                bank_bic: data.bank_bic ?? "",
                bank_iban: data.bank_iban ?? "",
                bank2_label: data.bank2_label ?? "",
                bank2_name: data.bank2_name ?? "",
                bank2_bic: data.bank2_bic ?? "",
                bank2_iban: data.bank2_iban ?? "",
                bi_direction: data.bi_direction ?? "",
                bi_cesf: data.bi_cesf ?? "",
                bi_gla: data.bi_gla ?? "",
                bi_travaux: data.bi_travaux ?? "",
              });
            }}
            formGetData={() => ({
              org_nom: this.state.org_nom,
              numero_voie: this.state.numero_voie,
              type_voie: this.state.type_voie,
              nom_voie: this.state.nom_voie,
              cp: this.state.cp,
              ville: this.state.ville,
              siret: this.state.siret,
              code_ape: this.state.code_ape,
              ics: this.state.ics,
              numero_cpi: this.state.numero_cpi,
              bank_label: this.state.bank_label,
              bank_name: this.state.bank_name,
              bank_bic: this.state.bank_bic,
              bank_iban: this.state.bank_iban,
              bank2_label: this.state.bank2_label,
              bank2_name: this.state.bank2_name,
              bank2_bic: this.state.bank2_bic,
              bank2_iban: this.state.bank2_iban,
              bi_direction: this.state.bi_direction,
              bi_cesf: this.state.bi_cesf,
              bi_gla: this.state.bi_gla,
              bi_travaux: this.state.bi_travaux,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            formContent={
              <GridContainer>
                {errors && (
                  <GridItem xs={12}>
                    <Typography variant="h6" component="h4" color="error">
                      {Object.entries(errors).length === 0 ? (
                        <>Une erreur est survenue.</>
                      ) : (
                        <>
                          Merci de corriger les champs en rouge du formulaire.
                        </>
                      )}
                    </Typography>
                  </GridItem>
                )}
                <GridItem xs={12}>
                  <TextInput
                    label="Nom de votre Soliha"
                    name="org_nom"
                    onChange={this.handleChange}
                    value={this.state.org_nom}
                    error={Boolean(errors?.org_nom)}
                  />
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <TextInput
                    label="Numéro dans la voie"
                    name="numero_voie"
                    onChange={this.handleChange}
                    value={this.state.numero_voie}
                    error={Boolean(errors?.numero_voie)}
                  />
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <TextInput
                    label="Type de voie"
                    name="type_voie"
                    onChange={this.handleChange}
                    value={this.state.type_voie}
                    error={Boolean(errors?.type_voie)}
                  />
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <TextInput
                    label="Nom de la voie"
                    name="nom_voie"
                    onChange={this.handleChange}
                    value={this.state.nom_voie}
                    error={Boolean(errors?.nom_voie)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Code Postal"
                    name="cp"
                    onChange={this.handleChange}
                    value={this.state.cp}
                    error={Boolean(errors?.cp)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Ville"
                    name="ville"
                    onChange={this.handleChange}
                    value={this.state.ville}
                    error={Boolean(errors?.ville)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Siret"
                    name="siret"
                    onChange={this.handleChange}
                    value={this.state.siret}
                    error={Boolean(errors?.siret)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Code APE"
                    name="code_ape"
                    onChange={this.handleChange}
                    value={this.state.code_ape}
                    error={Boolean(errors?.code_ape)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Identifiant Créancier SEPA"
                    name="ics"
                    onChange={this.handleChange}
                    value={this.state.ics}
                    error={Boolean(errors?.ics)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Numéro carte professionnelle CPI"
                    name="numero_cpi"
                    onChange={this.handleChange}
                    value={this.state.numero_cpi}
                    error={Boolean(errors?.numero_cpi)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6} />
                <GridItem xs={12}>
                  <HeaderTitle label="Compte Bancaire 1" />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Nom du compte"
                    name="bank_label"
                    onChange={this.handleChange}
                    value={this.state.bank_label}
                    error={Boolean(errors?.bank_label)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6} />
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Nom RIB"
                    name="bank_name"
                    onChange={this.handleChange}
                    value={this.state.bank_name}
                    error={Boolean(errors?.bank_name)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="BIC"
                    name="bank_bic"
                    onChange={this.handleChange}
                    value={this.state.bank_bic}
                    error={Boolean(errors?.bank_bic)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="IBAN"
                    name="bank_iban"
                    onChange={this.handleChangeIban}
                    value={this.state.bank_iban}
                    InputProps={{
                      inputComponent: IbanIMaskInput,
                    }}
                    error={Boolean(errors?.bank_iban)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Compte Bancaire 2" />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Nom du compte 2"
                    name="bank2_label"
                    onChange={this.handleChange}
                    value={this.state.bank2_label}
                    error={Boolean(errors?.bank2_label)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6} />
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Nom RIB 2"
                    name="bank2_name"
                    onChange={this.handleChange}
                    value={this.state.bank2_name}
                    error={Boolean(errors?.bank2_name)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="BIC 2"
                    name="bank2_bic"
                    onChange={this.handleChange}
                    value={this.state.bank2_bic}
                    error={Boolean(errors?.bank2_bic)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="IBAN 2"
                    name="bank2_iban"
                    onChange={this.handleChangeIban}
                    value={this.state.bank2_iban}
                    InputProps={{
                      inputComponent: IbanIMaskInput,
                    }}
                    error={Boolean(errors?.bank2_iban)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle
                    label={
                      <>
                        Configuration BI{" "}
                        <InfoLightTooltip
                          title={`Si l'URL de votre tableau de bord est "bi.soliha.fr/dashboard/55-direction", vous devez simplement entrer le nombre 55`}
                        />
                      </>
                    }
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <NumberInput
                    label="Direction"
                    name="bi_direction"
                    onChange={this.handleChange}
                    value={this.state.bi_direction}
                    error={Boolean(errors?.bi_direction)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <NumberInput
                    label="Chef CESF"
                    name="bi_cesf"
                    onChange={this.handleChange}
                    value={this.state.bi_cesf}
                    error={Boolean(errors?.bi_cesf)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <NumberInput
                    label="Chef GLA"
                    name="bi_gla"
                    onChange={this.handleChange}
                    value={this.state.bi_gla}
                    error={Boolean(errors?.bi_gla)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <NumberInput
                    label="Chef Travaux"
                    name="bi_travaux"
                    onChange={this.handleChange}
                    value={this.state.bi_travaux}
                    error={Boolean(errors?.bi_travaux)}
                  />
                </GridItem>
              </GridContainer>
            }
          />
        </GridItem>
      </GridContainer>
    );
  }
}

GeneralSettings.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default GeneralSettings;
