import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoJson } from "variables/axiosConfigs";
import { Link } from "react-router-dom";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import Description from "@mui/icons-material/Description";
import EventBusy from "@mui/icons-material/EventBusy";
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";
import Savings from "@mui/icons-material/Savings";
import People from "@mui/icons-material/People";
import Download from "@mui/icons-material/Download";

// core components
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import ConfigTypeTravaux from "components/ConfigComponents/ConfigTypeTravaux";
import Ged from "views/Tables/Ged";
import DocumentTemplatePopup from "components/Popups/DocumentTemplatePopup";
import FileUtils from "Utils/FileUtils";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { withUserContext } from "context/UserContext";
import ConfigStatutPersonnaliseDT from "components/ConfigComponents/ConfigStatutPersonnaliseDT";
import { withUiCustomizationContext } from "context/UiCustomizationContext";
import SelectInput from "components/CustomSelect/SelectInput";

class DossiersTravauxConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    // Ged permissions
    const permission = this.USER.can("config.ged.travaux.dt");
    this.GED_DT_PERMISSIONS = {
      CAN_VIEW: permission,
      CAN_EDIT: permission,
      CAN_DELETE: permission,
    };
    this.FRONT_URL = "/configuration/dossiers-travaux";
    const uiCustomization = props.uiCustomization;
    this.LABEL = "dossiers travaux";
    this.NO_YES = ["Non", "Oui"];

    this.state = {
      // DATA FORM
      id: "",
      value: "",
      gestion_interne: "",
      // END DATA FORM
      errors: null,
      alert: null,
      snackbarError: false,
      // AFFICHAGE
      affichage_statut_personnalise:
        uiCustomization?.affichage_champ_dt?.statut_personnalise ?? false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  exportFinanceur = (financeurId) => {
    axiosApiBackendNoJson
      .get("/configuration/documents/extract-financeur/" + financeurId, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        FileUtils.downLoadFile(res.data, res.headers, "export-financeur.csv");
      })
      .catch(() => {
        this.setState({ snackbarError: true });
      });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarError: false });
  };

  render() {
    const { errors } = this.state;
    return (
      <GridContainer>
        <Snackbar
          open={this.state.snackbarError}
          autoHideDuration={10000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={this.closeSnackbar} severity="error">
            Impossible de générer le fichier.
          </Alert>
        </Snackbar>
        <GridItem xs={12} lg={6}>
          <ConfigTypeTravaux />
          <ConfigContainer
            icon={<EventBusy />}
            title="Raison abandon"
            backendUrl="/config-raison-abandon-dt"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<HourglassEmpty />}
            title="Raison attente"
            backendUrl="/config-raison-attente-dt"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<Savings />}
            title="Type de prêt"
            backendUrl="/config-type-pret"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<People />}
            title="Raison contact"
            backendUrl="/config-raison-contact-dt"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<People />}
            title="Origine contact"
            backendUrl="/config-origine-contact-dt"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<People />}
            title="Mode contact"
            backendUrl="/config-mode-contact-dt"
            label={this.LABEL}
          />
        </GridItem>
        <GridItem xs={12} lg={6}>
          {this.GED_DT_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="templates"
              section="travaux"
              type="DT"
              permissions={this.GED_DT_PERMISSIONS}
              title="Modèles de documents"
              iconColor="config.main"
              buttonColor="config"
              acceptedFileTypes={[
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              popupComponent={DocumentTemplatePopup}
              frontUrl={this.FRONT_URL}
            />
          )}
          <TablePopUpContainer
            icon={<Assignment />}
            title="Financeur"
            backendUrl="/config-financeurs"
            customButtons={(data) => [
              {
                label: <Download />,
                color: "primary",
                square: true,
                onClick: () => {
                  this.exportFinanceur(data.id);
                },
              },
            ]}
            colDef={[
              { field: "id", editable: false, hide: true },
              {
                headerName: "Libellé",
                field: "value",
                flex: 3,
              },
              {
                headerName: "Géré par Soliha",
                field: "gestion_interne",
                valueFormatter: (params) =>
                  this.NO_YES[params.value] ?? "Non renseigné",
              },
            ]}
            formInitData={() => {
              this.setState({
                errors: null,
                id: "",
                value: "",
                gestion_interne: "",
              });
            }}
            formSetData={(data) => {
              this.setState({
                errors: null,
                id: data.id ?? "",
                value: data.value ?? "",
                gestion_interne: data.gestion_interne ?? "",
              });
            }}
            formGetData={() => ({
              id: this.state.id,
              value: this.state.value,
              gestion_interne: this.state.gestion_interne,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            getDeleteContent={(data) => data.value}
            getDeleteError={(data, error) =>
              (data.value ?? "") +
              " est associé à " +
              (error ?? "") +
              " dossiers travaux."
            }
            formTitle={
              this.state.id ? "Modifier le financeur" : "Ajouter un financeur"
            }
            formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
            formContent={
              <GridContainer>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Libellé"
                    name="value"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.value}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.value)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Géré par Soliha"
                    name="gestion_interne"
                    onChange={this.handleChange}
                    value={this.state.gestion_interne}
                    error={Boolean(errors?.gestion_interne)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
              </GridContainer>
            }
          />
          <ConfigContainer
            icon={<People />}
            title="Type loyer"
            backendUrl="/config-type-loyer-dt"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<People />}
            title="Type gestion"
            backendUrl="/config-type-gestion-dt"
            label={this.LABEL}
          />
          {Boolean(this.state.affichage_statut_personnalise) && (
            <ConfigStatutPersonnaliseDT />
          )}
        </GridItem>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigDossierTravaux"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </GridContainer>
    );
  }
}

DossiersTravauxConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
  uiCustomization: PropTypes.object,
};

export default withUserContext(
  withUiCustomizationContext(DossiersTravauxConfig),
);
