import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

// @mui/icons-material

// core components
import CurrencyInput from "components/CustomInput/CurrencyInput";
import GridItem from "components/Grid/GridItem.jsx";
import SelectInput from "components/CustomSelect/SelectInput";
import FormCollection from "components/Form/FormCollection";
import MensualiteUtils from "Utils/MensualiteUtils";

class MensualiteForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/config-operations";

    this.state = {
      operations: [],
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL, { params: { filter: this.props.filter } })
      .then((result) => {
        this.setState({ operations: result.data });
      });
  }

  number = (handleChangeFn) => {
    return (name, value) => handleChangeFn(name, value.replace(/,/g, "."));
  };

  event = (handleChangeFn) => {
    return (event) => handleChangeFn(event.target.name, event.target.value);
  };

  addRow = () => {
    this.props.addRow();
  };
  updateRow = (key, name, value) => {
    this.props.updateRow(key, name, value);
  };
  removeRow = (key) => {
    this.props.removeRow(key);
  };

  renderOperationLabel = (debit, op, key) => {
    // if no filter we display everything
    let shouldRender_debit = true;
    let shouldRender_credit = true;
    if (this.props.filter === "locataire") {
      shouldRender_debit = op.locataire_debit;
      shouldRender_credit = op.locataire_credit;
    } else if (this.props.filter === "proprietaire") {
      shouldRender_debit = op.proprietaire_debit;
      shouldRender_credit = op.proprietaire_credit;
    }

    const shouldRender =
      debit === "débit" ? shouldRender_debit : shouldRender_credit;

    if (shouldRender) {
      return (
        <MenuItem key={key} value={op.id}>
          {op.label}
        </MenuItem>
      );
    }
    return null;
  };

  render() {
    const { mensualites, errors } = this.props;

    return (
      <>
        <GridItem xs={12}>
          <FormCollection
            items={mensualites}
            rowTitle="Mensualité"
            addRow={this.addRow}
            removeRow={this.removeRow}
            tooltipAdd="Ajouter une mensualité"
            tooltipDelete="Supprimer la mensualité"
            dialogDeleteTitle={(rowNumber) =>
              "Voulez-vous supprimer la mensualité n° " + rowNumber + " ?"
            }
            formInputs={(mensualite, key) => (
              <>
                <GridItem xs={12} lg={4}>
                  <SelectInput
                    name="debit"
                    label="Débit ou Crédit ?"
                    value={mensualite.debit}
                    onChange={this.event((name, value) => {
                      this.updateRow(key, "id_op_config", "");
                      this.updateRow(key, name, value);
                    })}
                    shrink
                    error={Boolean(errors?.[key + ".debit"])}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {MensualiteUtils.DEBIT.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={8}>
                  <SelectInput
                    name="id_op_config"
                    label="Label"
                    value={mensualite.id_op_config}
                    onChange={this.event((name, value) =>
                      this.updateRow(key, name, value),
                    )}
                    shrink
                    error={Boolean(errors?.[key + ".id_op_config"])}
                  >
                    {this.state.operations.map((op, opKey) =>
                      this.renderOperationLabel(mensualite.debit, op, opKey),
                    )}
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <CurrencyInput
                    label="Montant"
                    name="montant"
                    fullWidth
                    margin="normal"
                    value={mensualite.montant}
                    onChange={this.event(
                      this.number((name, value) =>
                        this.updateRow(key, name, value),
                      ),
                    )}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.[key + ".montant"])}
                  />
                </GridItem>
                <GridItem xs={12} lg={8}>
                  <TextField
                    label="Description"
                    name="description"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={mensualite.description ?? ""}
                    onChange={this.event((name, value) =>
                      this.updateRow(key, name, value),
                    )}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.[key + ".description"])}
                  />
                </GridItem>
              </>
            )}
          />
        </GridItem>
      </>
    );
  }
}

MensualiteForm.propTypes = {
  filter: PropTypes.string.isRequired,
  mensualites: PropTypes.array.isRequired,
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default MensualiteForm;
