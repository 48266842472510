import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material components

// @mui/lab components
import TimelineDot from "@mui/lab/TimelineDot";

// core components
import Render from "Utils/RenderUtils";
import CustomTimeline from "components/Timeline/CustomTimeline";

class AccompagnementFoyerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { accompagnements } = this.props;

    accompagnements.sort(function (a, b) {
      return new Date(b.date_debut) - new Date(a.date_debut);
    });

    const activeId = accompagnements.length > 0 ? accompagnements[0].id : null;

    return (
      <>
        {accompagnements.length <= 0 ? (
          <Typography>
            <strong>Aucun accompagnement n&apos;a été saisi.</strong>
          </Typography>
        ) : (
          <CustomTimeline
            items={accompagnements}
            visibleItems={1}
            renderDot={(item) => (
              <TimelineDot
                color={activeId === item.id ? "success" : "primary"}
              />
            )}
            renderBody={(item) => {
              return (
                <>
                  <Typography>
                    <strong>Niveau d&apos;accompagnement : </strong>
                    {item.typesuivi?.value}
                  </Typography>
                  <Typography>
                    <strong>Nom du dispositif d&apos;accompagnement : </strong>
                    {item.nom_accompagnement?.value}
                  </Typography>
                  <Typography>
                    <strong>Date de début : </strong>
                    {Render.date(item.date_debut)}
                  </Typography>
                  <Typography>
                    <strong>Date de fin : </strong>
                    {Render.date(item.date_fin)}
                  </Typography>
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

AccompagnementFoyerView.propTypes = {
  accompagnements: PropTypes.array.isRequired,
};

export default AccompagnementFoyerView;
