import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import SelectSearchMultiple from "components/CustomSelect/SelectSearchMultiple.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import TextInput from "components/CustomInput/TextInput";

class DonneurDordresTravauxForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/donneurs-ordres";
    this.FRONT_URL = "/donneurs-ordres";

    this.state = {
      // DATA FORM
      nom: "",
      description: "",
      dashboard_metrics_id: "",
      users: [],
      // END DATA FORM
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      nom: this.state.nom,
      description: this.state.description,
      usersIds: this.state.users.map((u) => u.id),
      dashboard_metrics_id: this.state.dashboard_metrics_id,
    };
    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { errors } = this.state;

    return (
      <GridContainer>
        {errors && (
          <GridItem xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </GridItem>
        )}
        <GridItem xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <RecordVoiceOver />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier le Donneur D&apos;ordres</>
                  ) : (
                    <>Ajouter un Donneur D&apos;ordres</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    value={this.state.nom}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nom)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Description"
                    name="description"
                    multiline
                    maxRows={8}
                    onChange={this.handleChange}
                    value={this.state.description}
                    error={Boolean(errors?.description)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Identifiant dashboard"
                    name="dashboard_metrics_id"
                    onChange={this.handleChange}
                    value={this.state.dashboard_metrics_id}
                    error={Boolean(errors?.dashboard_metrics_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectSearchMultiple
                    apiUrl={`/user-list/donneurOrdres/${this.state.id}`}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    onChange={(event, newValue) => {
                      this.setState({ users: newValue });
                    }}
                    value={this.state.users}
                    label="Utilisateurs"
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

DonneurDordresTravauxForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default DonneurDordresTravauxForm;
