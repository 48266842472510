import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import Autocomplete from "@mui/material/Autocomplete";
import debounce from "@mui/material/utils/debounce";
import TextInput from "./TextInput";

class AdresseInput extends React.Component {
  constructor(props) {
    super(props);

    this.STREET_TYPES = [
      "Allée",
      "Anse",
      "Avenue",
      "Boulevard",
      "Carrefour",
      "Chaussée",
      "Chemin",
      "Cité",
      "Clos",
      "Corniche",
      "Côte",
      "Cour",
      "Cours",
      "Descente",
      "Domaine",
      "Degré",
      "Drève",
      "Ecart",
      "Esplanade",
      "Faubourg",
      "Gaffe",
      "Grand Rue",
      "Grand'Rue",
      "Grand-Rue",
      "Grande route",
      "Grande-route",
      "Grande Rue",
      "Grande-Rue",
      "Halle",
      "Hameau",
      "Impasse",
      "Liaison",
      "Lieu dit",
      "Lieu-dit",
      "Lotissement",
      "Mail",
      "Marché",
      "Montée",
      "Parvis",
      "Passage",
      "Place",
      "Placette",
      "Plaine",
      "Plateau",
      "Pont",
      "Promenade",
      "Quai",
      "Quartier",
      "Rang",
      "Rampe",
      "Résidence",
      "Rocade",
      "Rond point",
      "Rond-point",
      "Route",
      "Rue",
      "Ruelle",
      "Sente",
      "Sentier",
      "Square",
      "Terre plein",
      "Terre-plein",
      "Traboule",
      "Traverse",
      "Venelle",
      "Villa",
      "Village",
      "Voie",
    ];

    this.state = {
      options: [],
      inputValue: "",
    };
  }

  fetchAdresses = debounce((query) => {
    if (!query || query.length < 4) {
      return;
    }

    axios
      .get(
        `https://api-adresse.data.gouv.fr/search/?q=${query}&autocomplete=1&limit=10`,
      )
      .then((response) => {
        this.setState({ options: response.data.features });
      })
      .catch(() => {});
  }, 500);

  handleChange = (event, value, reason) => {
    if (reason === "input") {
      const query = value;
      this.setState({ inputValue: query });
      this.fetchAdresses(query);
    }
  };
  // La fonction prend en paramètre le nom complet d'une rue (par exemple "Avenue des Champs Élysées")
  extractStreetType = (fullStreetName) => {
    if (!fullStreetName) {
      return { typeVoie: "", nomVoie: "" };
    }

    // Filtrage des types de voies présents dans le nom complet de la rue
    // La méthode `startsWith` est utilisée pour vérifier si le nom de la rue commence par un type spécifique
    // Le résultat est un tableau de types qui correspondent ['Rue', 'Ruelle']...
    const typeVoie = this.STREET_TYPES.filter((type) =>
      fullStreetName.toLowerCase().startsWith(type.toLowerCase()),
    )
      // On utilise `reduce` pour trouver le type de voie ayant le plus grand nombre de caractères
      // Cela permet de prendre en compte les cas où plusieurs types pourraient correspondre
      // par exemple, "Rue" et "Ruelle"
      .reduce((max, curr) => (curr.length > max.length ? curr : max), "");

    // On extrait le nom de la rue sans son type (ex: "des Champs Élysées" pour "Avenue des Champs Élysées")
    // en utilisant `substring` et `trim` pour enlever les espaces blancs éventuels
    const nomVoie = fullStreetName.substring(typeVoie.length).trim();

    return { typeVoie, nomVoie };
  };

  handleSelection = (event, value) => {
    if (!value?.properties) {
      this.setState({ options: [] });
      return;
    }

    this.setState({ options: [value] });

    if (this.props.onAdresseSelected) {
      const properties = value.properties;
      const { typeVoie, nomVoie } = this.extractStreetType(properties?.street);
      const context =
        properties.context?.split(", ").map((part) => part.trim()) ?? [];

      const codeDep = context?.shift() ?? "";
      const region = context?.pop() ?? "";
      const coordinates = value.geometry?.coordinates ?? null;

      this.props.onAdresseSelected({
        numero_voie: properties?.housenumber ?? "",
        type_voie: typeVoie,
        nom_voie: nomVoie,
        code_insee: properties.citycode?.replace(/^0+/, "") ?? "", // Remove first 0 if exists
        region: region,
        dep_code: codeDep,
        cp: properties?.postcode ?? "",
        nom_complet: properties?.city ?? "",
        latitude: coordinates?.[1].toString() ?? "",
        longitude: coordinates?.[0].toString() ?? "",
        ban_id: properties?.id ?? "",
      });
    }
  };

  render() {
    return (
      <Autocomplete
        freeSolo
        options={this.state.options}
        filterOptions={(x) => x}
        getOptionLabel={(o) => o?.properties?.label ?? ""}
        onChange={this.handleSelection}
        onInputChange={this.handleChange}
        inputValue={this.state.inputValue}
        renderInput={(params) => (
          <TextInput {...params} label="Rechercher une adresse" />
        )}
      />
    );
  }
}

AdresseInput.propTypes = {
  onAdresseSelected: PropTypes.func,
};

export default AdresseInput;
