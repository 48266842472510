import React from "react";
import PropTypes from "prop-types";

// @mui/material
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import ChatIcon from "@mui/icons-material/Chat";
import ListIcon from "@mui/icons-material/List";

// core components
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import Evenements from "components/Evenements/Evenements.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";
import SelectSearch from "components/CustomSelect/SelectSearch";
import Render from "Utils/RenderUtils";
import QueryUtils from "Utils/QueryUtils";
import { withUserContext } from "context/UserContext";
import SelectInput from "components/CustomSelect/SelectInput";

class EvenementsList extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    let defaultId = this.USER.can("edit.evenement") ? this.USER.id : "";
    this.evenementList = null;

    this.QUERY_PARAMS = new URLSearchParams(props.location.search);

    this.state = {
      user_id: +(this.QUERY_PARAMS.get("user_id") ?? defaultId) || "",
      user: +(this.QUERY_PARAMS.get("user_id") ?? defaultId) || "",
      resolu: this.QUERY_PARAMS.get("resolu") ?? "0",
      origine_id: +this.QUERY_PARAMS.get("origine_id") || "",
      lineClamp: true,
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  buildQueryFilters = () => {
    const { user_id, resolu, origine_id } = this.state;

    const queryFilters = {
      user_id: user_id,
      resolu: resolu,
      origine_id: origine_id,
    };

    QueryUtils.replaceSearchParams(queryFilters);

    if (user_id === "") {
      queryFilters.user_id = null;
    }
    if (resolu === "tous") {
      queryFilters.resolu = null;
    }
    if (origine_id === "") {
      queryFilters.origine_id = null;
    }

    return queryFilters;
  };

  reloadEvenementList = () => {
    if (this.evenementList) {
      this.evenementList.loadAsyncData();
    }
  };

  // handleChange = (name, value) => {
  //   this.setState({ [name]: value });
  // };
  // handleChangeEvent = (event) => {
  //   this.handleChange(event.target.name, event.target.value);
  // };

  handleChangeFilter = (name, value) => {
    this.setState({ [name]: value }, this.reloadEvenementList);
  };
  handleChangeEventFilter = (event) => {
    this.handleChangeFilter(event.target.name, event.target.value);
  };

  render() {
    const { user_id, origine_id, resolu, lineClamp } = this.state;
    const { section } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <ChatIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Tous les Évènements
                </Typography>
              }
            />
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item lg={4} xs={12}>
                  <SelectSearch
                    name="user_id"
                    label="Utilisateur"
                    apiUrl={
                      "/usersManager?etat=all&withPermissions=edit.evenement." +
                      section
                    }
                    onChange={this.handleChangeFilter}
                    value={user_id}
                    sortResponseData={(a, b) => b.is_active - a.is_active}
                    buildOptionLabel={(data) => Render.fullName(data)}
                    buildOptionAdditionalData={(data) => ({
                      group_by: data.is_active ? "Actif" : "Inactif",
                    })}
                    groupBy={(option) => option.group_by}
                    shrink
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <SelectSearch
                    name="origine_id"
                    label="Origine"
                    apiUrl="/config-origine-evenements"
                    emptyLabelValue="Tous"
                    value={origine_id}
                    onChange={this.handleChangeFilter}
                    buildOptionLabel={(data) => data.value}
                    shrink
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <SelectInput
                    name="resolu"
                    label="Suivi"
                    value={resolu}
                    onChange={this.handleChangeEventFilter}
                    displayEmpty
                  >
                    <MenuItem value="tous">Tous</MenuItem>
                    <MenuItem value="">Sans Suivi</MenuItem>
                    <MenuItem value="0">Suivi en cours</MenuItem>
                    <MenuItem value="1">Résolu</MenuItem>
                  </SelectInput>
                </Grid>
                {/* <Grid item lg={4} xs={12}>
                  <SelectInput
                    name="lineClamp"
                    label="Affichage"
                    value={lineClamp}
                    onChange={this.handleChangeEvent}
                    displayEmpty
                    // error={Boolean(this.state.errors?.lineClamp)}
                  >
                    <MenuItem value={true}>Court</MenuItem>
                    <MenuItem value={false}>Complet</MenuItem>
                  </SelectInput>
                </Grid> */}
                <Grid item xs={12}>
                  <Evenements
                    ref={(ref) => (this.evenementList = ref)}
                    getQueryFilters={this.buildQueryFilters}
                    displaySearch
                    displayTotal
                    displayEntityBtn
                    lineClamp={lineClamp}
                    section={section}
                    withCountReplies
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Button size="small" square round onClick={this.goBack}>
            <ListIcon />
          </Button>
        </MenuBottom>
      </Grid>
    );
  }
}

EvenementsList.propTypes = {
  section: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  location: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(EvenementsList);
