// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import Description from "@mui/icons-material/Description";
import Euro from "@mui/icons-material/Euro";
import FindInPage from "@mui/icons-material/FindInPage";
import Home from "@mui/icons-material/Home";
import Savings from "@mui/icons-material/Savings";
import People from "@mui/icons-material/People";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import Person from "@mui/icons-material/Person";
import Settings from "@mui/icons-material/Settings";
import Chat from "@mui/icons-material/Chat";
import Build from "@mui/icons-material/Build";
import SensorOccupied from "@mui/icons-material/SensorOccupied";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Place from "@mui/icons-material/Place";
import Business from "@mui/icons-material/Business";
import Apartment from "@mui/icons-material/Apartment";
import ListAlt from "@mui/icons-material/ListAlt";

// core components
import HomeConfig from "views/ViewConfiguration/HomeConfig";
import ApurementConfig from "views/ViewConfiguration/Immobilier/ApurementConfig";
import LogementConfig from "views/ViewConfiguration/Immobilier/LogementConfig";
import OrigineEvenementConfig from "views/ViewConfiguration/Transverse/OrigineEvenementConfig";
import ProprietaireConfig from "views/ViewConfiguration/Immobilier/ProprietaireConfig";
import FoyerConfig from "views/ViewConfiguration/Immobilier/FoyerConfig";
import AuditsConfig from "views/ViewConfiguration/Travaux/AuditsConfig";
import BeneficiairesConfig from "views/ViewConfiguration/Travaux/BeneficiairesConfig";
import DossiersTravauxConfig from "views/ViewConfiguration/Travaux/DossiersTravauxConfig";
import LogementTravauxConfig from "views/ViewConfiguration/Travaux/LogementTravauxConfig";
import TransverseLogementConfig from "views/ViewConfiguration/Transverse/TransverseLogementConfig";
import GeneralSettings from "views/ViewConfiguration/Transverse/GeneralSettings";
import ComptabiliteConfig from "views/ViewConfiguration/Immobilier/ComptabiliteConfig";
import UsersManager from "views/ViewConfiguration/Transverse/UsersManager";
import ContratProprietaireConfig from "views/ViewConfiguration/Immobilier/ContratProprietaireConfig";
import DispositifConfig from "views/ViewConfiguration/Travaux/DispositifConfig";
import ContratLocataireConfig from "views/ViewConfiguration/Immobilier/ContratLocataireConfig";
import RolesManager from "views/ViewConfiguration/Transverse/RolesManager";
import AlertImmobilierConfig from "views/ViewConfiguration/Immobilier/AlertImmobilierConfig";
import AlertTravauxConfig from "views/ViewConfiguration/Travaux/AlertTravauxConfig";
import EvenementImmobilierConfig from "views/ViewConfiguration/Immobilier/EvenementConfig";
import EvenementTravauxConfig from "views/ViewConfiguration/Travaux/EvenementTravauxConfig";
import ImmobilierConfig from "views/ViewConfiguration/Immobilier/ImmobilierConfig";
import TravauxConfig from "views/ViewConfiguration/Travaux/TravauxConfig";
import AnahConfig from "views/ViewConfiguration/Transverse/AnahConfig";
import EpciConfig from "views/ViewConfiguration/Transverse/EpciConfig";
import VilleConfig from "views/ViewConfiguration/Transverse/VilleConfig";
import UiCustomizationSettings from "views/ViewConfiguration/Transverse/UiCustomizationSettings";
import ProcedureConfig from "views/ViewConfiguration/Immobilier/ProcedureConfig";
import DisplaySettings from "@mui/icons-material/DisplaySettings";

var configDashboardRoutes = [
  {
    permission: [
      "edit.configuration.general",
      "view.configuration.immobilier",
      "edit.configuration.immobilier",
      "view.configuration.travaux",
      "edit.configuration.travaux",
    ],
    icon: Settings,
    path: "/home",
    name: "Configuration",
    component: HomeConfig,
  },
  {
    divider: true,
    permission: [
      "edit.configuration.general",
      "edit.configuration.immobilier",
      "edit.configuration.travaux",
    ],
    name: "HAPY",
  },
  {
    permission: ["edit.configuration.general"],
    path: "/hapy-informations",
    name: "Informations Soliha",
    icon: Settings,
    component: GeneralSettings,
  },
  {
    permission: ["edit.configuration.uicustomization"],
    path: "/customisation-ui",
    name: "Customisation UI",
    icon: DisplaySettings,
    component: UiCustomizationSettings,
  },
  {
    permission: ["config.logement", "config.logementtravaux"],
    path: "/hapy-logement",
    name: "Logements",
    icon: Home,
    component: TransverseLogementConfig,
    logs: true,
  },
  {
    permission: ["config.evenement"],
    path: "/hapy-evenement",
    name: "Évènement",
    icon: Chat,
    component: OrigineEvenementConfig,
    logs: true,
  },
  {
    permission: ["edit.usersmanager"],
    path: "/users",
    name: "Gestion des utilisateurs",
    icon: People,
    component: UsersManager,
    logs: true,
  },
  {
    permission: ["edit.role"],
    path: "/roles",
    name: "Gestion des rôles",
    icon: SensorOccupied,
    component: RolesManager,
    logs: true,
  },
  {
    permission: ["config.ville"],
    path: "/communes",
    name: "Communes",
    icon: Place,
    component: VilleConfig,
    logs: false,
  },
  {
    permission: ["config.zoneanah"],
    path: "/anah",
    name: "ANAH",
    icon: Business,
    component: AnahConfig,
    logs: true,
  },
  {
    permission: ["config.epci"],
    path: "/epci",
    name: "EPCI",
    icon: Apartment,
    component: EpciConfig,
    logs: true,
  },
  {
    divider: true,
    permission: ["edit.configuration.immobilier"],
    name: "Immobilier",
  },
  {
    permission: ["edit.configuration.immobilier"],
    path: "/immobilier-informations",
    name: "Informations Immobilier",
    icon: Settings,
    component: ImmobilierConfig,
  },
  {
    permission: ["config.internalnotification.immobilier"],
    path: "/alertes-immobilier",
    name: "Alertes",
    icon: NotificationsIcon,
    component: AlertImmobilierConfig,
    logs: true,
  },
  {
    permission: ["config.evenement.immobilier"],
    path: "/evenements-immobilier",
    name: "Évènement",
    icon: Chat,
    component: EvenementImmobilierConfig,
    logs: true,
  },
  {
    permission: [
      "edit.configuration.comptabilite",
      "config.operationlocataire",
      "config.operationproprietaire",
    ],
    path: "/comptabilite",
    name: "Comptabilité",
    icon: Euro,
    component: ComptabiliteConfig,
    logs: true,
  },
  {
    permission: ["config.contratlocataire"],
    path: "/contrats-locataires",
    name: "Contrats Locataire",
    icon: PeopleAlt,
    component: ContratLocataireConfig,
  },
  {
    permission: ["config.contratproprietaire"],
    path: "/contrats-propriétaires",
    name: "Contrats Propriétaire",
    icon: Description,
    component: ContratProprietaireConfig,
    logs: true,
  },
  {
    permission: ["config.proprietaire"],
    path: "/proprietaires",
    name: "Proprietaires",
    icon: Person,
    component: ProprietaireConfig,
    logs: true,
  },
  {
    permission: ["config.logement"],
    path: "/logements",
    name: "Logements",
    icon: Home,
    component: LogementConfig,
    logs: true,
  },
  {
    permission: ["config.apurement"],
    path: "/apurements",
    name: "Apurements",
    icon: Savings,
    component: ApurementConfig,
    logs: true,
  },
  {
    permission: ["config.foyer"],
    path: "/foyers",
    name: "Foyers",
    icon: People,
    component: FoyerConfig,
    logs: true,
  },
  {
    permission: ["config.procedure"],
    path: "/procedure",
    name: "Procédures",
    icon: ListAlt,
    component: ProcedureConfig,
    logs: true,
  },
  {
    divider: true,
    permission: ["edit.configuration.travaux"],
    name: "Travaux",
  },
  {
    permission: ["edit.configuration.travaux"],
    path: "/travaux-informations",
    name: "Informations Travaux",
    icon: Settings,
    component: TravauxConfig,
  },
  {
    permission: ["config.internalnotification.travaux"],
    path: "/alertes-travaux",
    name: "Alertes",
    icon: NotificationsIcon,
    component: AlertTravauxConfig,
    logs: true,
  },
  {
    permission: ["config.evenement.travaux"],
    path: "/evenements-travaux",
    name: "Évènement",
    icon: Chat,
    component: EvenementTravauxConfig,
    logs: true,
  },
  {
    permission: ["config.beneficiaire"],
    path: "/beneficiaires",
    name: "Bénéficiaires",
    icon: PeopleAlt,
    component: BeneficiairesConfig,
    logs: true,
  },
  {
    permission: ["config.logementtravaux"],
    path: "/logement-travaux",
    name: "Logements",
    icon: Home,
    component: LogementTravauxConfig,
    logs: true,
  },
  {
    permission: ["config.dossiertravaux"],
    path: "/dossiers-travaux",
    name: "Dossiers Travaux",
    icon: Assignment,
    component: DossiersTravauxConfig,
    logs: true,
  },
  {
    permission: ["config.audit"],
    path: "/audit",
    name: "Audits",
    icon: FindInPage,
    component: AuditsConfig,
    logs: true,
  },
  {
    permission: ["config.dispositif"],
    path: "/dispositif",
    name: "Dispositifs et opérateurs",
    icon: Build,
    component: DispositifConfig,
    logs: true,
  },
  // redirects
  {
    redirect: true,
    permission: ["edit.configuration.general"],
    path: "/hapy-informations",
    pathTo: "/hapy-informations",
  },
  {
    redirect: true,
    permission: ["config.logement", "config.logementtravaux"],
    path: "/hapy-logement",
    pathTo: "/hapy-logement",
  },
  {
    redirect: true,
    permission: ["config.evenement"],
    path: "/hapy-evenement",
    pathTo: "/hapy-evenement",
  },
  {
    redirect: true,
    permission: ["edit.usersmanager"],
    path: "/users",
    pathTo: "/users",
  },
  {
    redirect: true,
    permission: ["edit.role"],
    path: "/roles",
    pathTo: "/roles",
  },
  {
    redirect: true,
    permission: ["config.ville"],
    path: "/communes",
    pathTo: "/communes",
  },
  {
    redirect: true,
    permission: ["config.zoneanah"],
    path: "/anah",
    pathTo: "/anah",
  },
  {
    redirect: true,
    permission: ["config.epci"],
    path: "/epci",
    pathTo: "/epci",
  },
  {
    redirect: true,
    permission: ["edit.configuration.immobilier"],
    path: "/immobilier-informations",
    pathTo: "/immobilier-informations",
  },
  {
    redirect: true,
    permission: ["config.internalnotification.immobilier"],
    path: "/alertes-immobilier",
    pathTo: "/alertes-immobilier",
  },
  {
    redirect: true,
    permission: ["config.evenement.immobilier"],
    path: "/evenements-immobilier",
    pathTo: "/evenements-immobilier",
  },
  {
    redirect: true,
    permission: [
      "edit.configuration.comptabilite",
      "config.operationlocataire",
      "config.operationproprietaire",
    ],
    path: "/comptabilite",
    pathTo: "/comptabilite",
  },
  {
    redirect: true,
    permission: ["config.contratlocataire"],
    path: "/contrats-locataires",
    pathTo: "/contrats-locataires",
  },
  {
    redirect: true,
    permission: ["config.contratproprietaire"],
    path: "/contrats-propriétaires",
    pathTo: "/contrats-propriétaires",
  },
  {
    redirect: true,
    permission: ["config.proprietaire"],
    path: "/proprietaires",
    pathTo: "/proprietaires",
  },
  {
    redirect: true,
    permission: ["config.logement"],
    path: "/logements",
    pathTo: "/logements",
  },
  {
    redirect: true,
    permission: ["config.apurement"],
    path: "/apurements",
    pathTo: "/apurements",
  },
  {
    redirect: true,
    permission: ["config.foyer"],
    path: "/foyers",
    pathTo: "/foyers",
  },
  {
    redirect: true,
    permission: ["edit.configuration.travaux"],
    path: "/travaux-informations",
    pathTo: "/travaux-informations",
  },
  {
    redirect: true,
    permission: ["config.internalnotification.travaux"],
    path: "/alertes-travaux",
    pathTo: "/alertes-travaux",
  },
  {
    redirect: true,
    permission: ["config.evenement.travaux"],
    path: "/evenements-travaux",
    pathTo: "/evenements-travaux",
  },
  {
    redirect: true,
    permission: ["config.beneficiaire"],
    path: "/beneficiaires",
    pathTo: "/beneficiaires",
  },
  {
    redirect: true,
    permission: ["config.logementtravaux"],
    path: "/logement-travaux",
    pathTo: "/logement-travaux",
  },
  {
    redirect: true,
    permission: ["config.dossiertravaux"],
    path: "/dossiers-travaux",
    pathTo: "/dossiers-travaux",
  },
  {
    redirect: true,
    permission: ["config.audit"],
    path: "/audit",
    pathTo: "/audit",
  },
  {
    redirect: true,
    permission: ["config.dispositif"],
    path: "/dispositif",
    pathTo: "/dispositif",
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/home",
  },
];
export default configDashboardRoutes;
