import React, { Component } from "react";
import PropTypes from "prop-types";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";

class SimpleTreeView extends Component {
  constructor(props) {
    super(props);
    this.defaultExpanded = props.defaultExpanded?.map(String) ?? [];
    this.state = { expanded: this.defaultExpanded };
  }

  handleToggle = (event, nodeIds) => {
    this.setState({ expanded: nodeIds });
  };

  handleExpandClick = () => {
    this.setState((prevState) => {
      return {
        expanded:
          this.props?.defaultExpanded && prevState.expanded.length === 0
            ? this.defaultExpanded
            : [],
      };
    });
  };

  renderTree = (node) => {
    if (node) {
      return (
        <TreeItem
          sx={{
            "& .MuiTreeItem-group": {
              borderLeft: "1px dashed black",
            },
          }}
          key={node.id}
          nodeId={node.id.toString()}
          label={node.value}
        >
          {Array.isArray(node.children)
            ? node.children.map((child) => this.renderTree(child))
            : null}
        </TreeItem>
      );
    }
  };

  render() {
    const { tree, defaultExpanded, collapseAll, label } = this.props;
    const { expanded } = this.state;

    return (
      <>
        <Typography sx={{ mb: 1 }}>
          {label && <strong>{label} : </strong>}
          {defaultExpanded && collapseAll && (
            <Button
              onClick={this.handleExpandClick}
              sx={{ fontSize: 10 }}
              size="small"
              startIcon={expanded?.length === 0 ? <Add /> : <Remove />}
            >
              {expanded?.length === 0 ? "Tout ouvrir" : "Tout fermer"}
            </Button>
          )}
        </Typography>

        <TreeView
          disableSelection
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={this.handleToggle}
        >
          {tree && tree.map((item) => this.renderTree(item))}
        </TreeView>
      </>
    );
  }
}

SimpleTreeView.propTypes = {
  classes: PropTypes.any,
  tree: PropTypes.array,
  defaultExpanded: PropTypes.array,
  collapseAll: PropTypes.bool,
  label: PropTypes.string,
};

export default SimpleTreeView;
