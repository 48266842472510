import React from "react";
import PropTypes from "prop-types";

// @mui/icons-material
import ChatIcon from "@mui/icons-material/Chat";
import ListIcon from "@mui/icons-material/List";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import Evenements from "components/Evenements/Evenements.jsx";
import EvenementForm from "components/Evenements/EvenementForm.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";
import EvenementUtils from "Utils/EvenementUtils";
import Typography from "@mui/material/Typography";
import { withUserContext } from "context/UserContext";

class EvenementsPage extends React.Component {
  constructor(props) {
    super(props);
    this.CAN_EDIT_EVENEMENTS = props.user.can("edit.evenement");
    this.evenementList = null;

    this.QUERY_PARAMS = new URLSearchParams(props.location.search);

    this.state = {
      eventable_id: Number(props.match.params.modelId),
      eventable_type_readable: props.match.params.modelType,
      openForm: this.QUERY_PARAMS.get("form") === "open",
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  reloadEvenementList = () => {
    this.evenementList.loadAsyncData();
  };

  render() {
    const { eventable_id, eventable_type_readable, openForm } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <ChatIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h5" component="h4">
                  {EvenementUtils.evenementsPageTitle(
                    eventable_id,
                    eventable_type_readable,
                  )}
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                {this.CAN_EDIT_EVENEMENTS && (
                  <GridItem xs={12}>
                    <EvenementForm
                      collapsable
                      openCollapse={openForm}
                      modelId={eventable_id}
                      modelType={eventable_type_readable}
                      onSuccess={this.reloadEvenementList}
                    />
                  </GridItem>
                )}
                <GridItem xs={12}>
                  <Evenements
                    ref={(ref) => (this.evenementList = ref)}
                    modelId={eventable_id}
                    modelType={eventable_type_readable}
                    displaySearch
                    lineClamp
                    withCountReplies
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>

        <MenuBottom>
          <Button size="small" square round onClick={this.goBack}>
            <ListIcon />
          </Button>
        </MenuBottom>
      </GridContainer>
    );
  }
}

EvenementsPage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
  location: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(EvenementsPage);
