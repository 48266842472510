import React, { Component } from "react";
import { axiosApiBackend, axiosApiBackendNoJson } from "variables/axiosConfigs";

// @mui/material components
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// @mui/icons-material
import Apartment from "@mui/icons-material/Apartment";
import Download from "@mui/icons-material/Download";

// core components
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import SelectSearch from "components/CustomSelect/SelectSearch";
import Render from "Utils/RenderUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import CheckBoxTable from "components/Table/CheckBoxTable";
import FileUtils from "Utils/FileUtils";
import ButtonRenderer from "components/CustomAgRenderer/ButtonRenderer";

class ConfigDispositif extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/config-dispositif";
    this.TABLE_CONTAINER = React.createRef();

    this.state = {
      // NESTED DATA
      epci: [],
      villes: [],
      categoriesressources: [],
      // DATA FORM
      id: 0,
      value: "",
      operateur_id: "",
      date_debut: "",
      date_fin: "",
      description: "",
      // END DATA FORM
      alert: null,
      errors: null,
      errorMessage: "",
      snackbarError: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeValue = (name, value) => {
    this.setState({ [name]: value });
  };

  handleDialogConfirm = (type) => {
    const data = {
      ids: this.state[type],
      type: type,
    };

    axiosApiBackend
      .put(this.BACKEND_URL + "/" + this.state.id + "/associate", data)
      .then(() => {
        this.TABLE_CONTAINER.current.loadAsyncData();
        this.clearAlert();
      });
  };

  checkBoxTableDialog = (type, title, checkBoxTable) => {
    return (
      <AlertDialog
        title={title}
        onConfirm={() => this.handleDialogConfirm(type)}
        confirmLabel="Ajouter"
        confirmColor="success"
        onCancel={this.clearAlert}
        cancelLabel="Annuler"
        cancelColor="primary"
        fullWidth
        maxWidth="md"
      >
        {checkBoxTable}
      </AlertDialog>
    );
  };

  epciCheckBoxTable = (epciIds) => {
    return (
      <CheckBoxTable
        backendUrl="/config-epci"
        selectedIds={epciIds}
        onSelectionChanged={(ids) => this.handleChangeValue("epci", ids)}
        colDef={[
          {
            headerName: "EPCI",
            field: "value",
            checkboxSelection: true,
            flex: 1,
          },
          {
            headerName: "Communes",
            field: "villes",
            flex: 1,
            cellStyle: {
              lineHeight: 2,
            },
            wrapText: true,
            autoHeight: true,
            valueGetter: (params) =>
              params.data.villes.map((ville) => ville.ville).join(", "),
          },
        ]}
      />
    );
  };

  villesCheckBoxTable = (villeIds) => {
    return (
      <CheckBoxTable
        backendUrl="/villes"
        selectedIds={villeIds}
        onSelectionChanged={(ids) => this.handleChangeValue("villes", ids)}
        colDef={[
          {
            headerName: "Code Postal",
            field: "cp",
            checkboxSelection: true,
            flex: 1,
          },
          {
            headerName: "Ville",
            field: "ville",
            flex: 1,
          },
        ]}
      />
    );
  };

  categoriesRessourcesCheckBoxTable = (categorieRessourceIds) => {
    return (
      <CheckBoxTable
        backendUrl="/config-categorie-ressource"
        selectedIds={categorieRessourceIds}
        onSelectionChanged={(ids) =>
          this.handleChangeValue("categoriesressources", ids)
        }
        colDef={[
          {
            headerName: "Catégorie de ressources",
            field: "value",
            checkboxSelection: true,
            flex: 1,
          },
        ]}
      />
    );
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  exportDispositif = (dispositifId) => {
    axiosApiBackendNoJson
      .get("/configuration/documents/extract-dispositif/" + dispositifId, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        FileUtils.downLoadFile(res.data, res.headers, "export-dispositif.csv");
      })
      .catch(() => {
        this.setState({ snackbarError: true });
      });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarError: false });
  };

  render() {
    const { errors } = this.state;

    return (
      <>
        {this.state.alert}
        <Snackbar
          open={this.state.snackbarError}
          autoHideDuration={10000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={this.closeSnackbar} severity="error">
            Impossible de générer le fichier. Vérifiez que les dates de début et
            de fin ont été correctement renseignées.
          </Alert>
        </Snackbar>
        <TablePopUpContainer
          ref={this.TABLE_CONTAINER}
          icon={<Apartment />}
          dialogProps={{
            fullWidth: true,
          }}
          title="Dispositifs"
          backendUrl={this.BACKEND_URL}
          searchBar
          customButtons={(data) => [
            {
              label: <Download />,
              color: "info",
              square: true,
              onClick: () => {
                this.exportDispositif(data.id);
              },
            },
          ]}
          colDef={[
            { field: "id", editable: false, hide: true },
            {
              headerName: "Libellé",
              field: "value",
              cellStyle: { lineHeight: 2, wordBreak: "break-word" },
              wrapText: true,
              autoHeight: true,
              maxWidth: 150,
              minWidth: 150,
            },
            {
              headerName: "Operateurs",
              field: "operateur.value",
              cellStyle: { lineHeight: 2, wordBreak: "break-word" },
              wrapText: true,
              autoHeight: true,
              maxWidth: 200,
              minWidth: 200,
            },
            {
              headerName: "Date de début",
              field: "date_debut",
              cellStyle: { lineHeight: 2, wordBreak: "break-word" },
              wrapText: true,
              autoHeight: true,
              minWidth: 100,
              valueGetter: (params) => Render.date(params.data.date_debut),
            },
            {
              headerName: "Date de fin",
              field: "date_fin",
              cellStyle: { lineHeight: 2, wordBreak: "break-word" },
              wrapText: true,
              autoHeight: true,
              minWidth: 100,
              valueGetter: (params) => Render.date(params.data.date_fin),
            },
            {
              headerName: "EPCI",
              field: "epci",
              cellStyle: { lineHeight: 2, wordBreak: "break-word" },
              wrapText: true,
              autoHeight: true,
              maxWidth: 250,
              minWidth: 250,
              cellRenderer: ButtonRenderer,
              cellRendererParams: (params) => {
                const titleEpci = "Définir des EPCI";
                const epciIds = params.data.epci.map((epci) => epci.id);
                return {
                  text: params.data.epci.map((epci) => epci.value).join(", "),
                  buttons: [
                    {
                      size: "small",
                      square: false,
                      label: titleEpci,
                      color: "primary",
                      onClick: () => {
                        this.setState({
                          id: params.data.id,
                          alert: this.checkBoxTableDialog(
                            "epci",
                            titleEpci,
                            this.epciCheckBoxTable(epciIds),
                          ),
                        });
                      },
                    },
                  ],
                };
              },
            },
            {
              headerName: "Commune",
              field: "villes",
              cellStyle: { lineHeight: 2, wordBreak: "break-word" },
              wrapText: true,
              autoHeight: true,
              maxWidth: 250,
              minWidth: 250,
              cellRenderer: ButtonRenderer,
              cellRendererParams: (params) => {
                const titleVilles = "Définir des Communes";
                const villeIds = params.data.villes.map((ville) => ville.id);
                return {
                  text: params.data.villes
                    .map((ville) => ville.ville)
                    .join(", "),
                  buttons: [
                    {
                      size: "small",
                      square: false,
                      label: titleVilles,
                      color: "primary",
                      onClick: () => {
                        this.setState({
                          id: params.data.id,
                          alert: this.checkBoxTableDialog(
                            "villes",
                            titleVilles,
                            this.villesCheckBoxTable(villeIds),
                          ),
                        });
                      },
                    },
                  ],
                };
              },
            },
            {
              headerName: "Catégorie de ressources",
              field: "categoriesressources",
              cellStyle: { lineHeight: 2, wordBreak: "break-word" },
              wrapText: true,
              autoHeight: true,
              maxWidth: 200,
              minWidth: 200,
              cellRenderer: ButtonRenderer,
              cellRendererParams: (params) => {
                const titleCategorieRessource = "Définir des catégories";
                const crIds = params.data.categoriesressources.map(
                  (cr) => cr.id,
                );
                return {
                  text: params.data.categoriesressources
                    .map((categoriesressources) => categoriesressources.value)
                    .join(", "),
                  buttons: [
                    {
                      size: "small",
                      square: false,
                      label: titleCategorieRessource,
                      color: "primary",
                      onClick: () => {
                        this.setState({
                          id: params.data.id,
                          alert: this.checkBoxTableDialog(
                            "categoriesressources",
                            titleCategorieRessource,
                            this.categoriesRessourcesCheckBoxTable(crIds),
                          ),
                        });
                      },
                    },
                  ],
                };
              },
            },
            {
              headerName: "Description",
              field: "description",
              cellStyle: { lineHeight: 2, wordBreak: "break-word" },
              wrapText: true,
              autoHeight: true,
              maxWidth: 190,
              minWidth: 190,
            },
          ]}
          formInitData={() => {
            this.setState({
              id: 0,
              value: "",
              operateur_id: "",
              date_debut: "",
              date_fin: "",
              description: "",
              epci: [],
              villes: [],
              categoriesressources: [],
              errors: null,
            });
          }}
          formSetData={(data) => {
            this.setState({
              id: data.id ?? 0,
              value: data.value ?? "",
              operateur_id: data.operateur.id ?? "",
              date_debut: data.date_debut ?? "",
              date_fin: data.date_fin ?? "",
              description: data.description ?? "",
              epci: data.epci.map((epci) => epci.id) ?? [],
              villes: data.villes.map((ville) => ville.id) ?? [],
              categoriesressources:
                data.categoriesressources.map((cr) => cr.id) ?? [],
              errors: null,
            });
          }}
          formGetData={() => ({
            id: this.state.id,
            value: this.state.value,
            operateur_id: this.state.operateur_id,
            date_debut: this.state.date_debut,
            date_fin: this.state.date_fin,
            description: this.state.description,
            epci: this.state.epci,
            villes: this.state.villes,
            categoriesressources: this.state.categoriesressources,
          })}
          formSetErrors={(err) => {
            this.setState({
              errors: err,
            });
          }}
          getDeleteContent={(data) => data.value}
          formTitle={
            this.state.id ? "Modifier le dispositif" : "Ajouter un dispositif"
          }
          formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
          formContent={
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Libellé"
                  name="value"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={this.state.value}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors?.value)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SelectSearch
                  name="operateur_id"
                  label="Operateur"
                  margin="normal"
                  apiUrl="/config-operateur"
                  onChange={this.handleChangeValue}
                  buildOptionLabel={(data) => data.value}
                  value={this.state.operateur_id}
                  shrink
                  enableNoneValue={false}
                  disableClearable
                  error={Boolean(errors?.operateur_id)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Date de début"
                  name="date_debut"
                  variant="outlined"
                  type="date"
                  margin="normal"
                  fullWidth
                  onChange={this.handleChange}
                  value={this.state.date_debut}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors?.date_debut)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Date de fin"
                  name="date_fin"
                  variant="outlined"
                  type="date"
                  margin="normal"
                  fullWidth
                  onChange={this.handleChange}
                  value={this.state.date_fin}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors?.date_fin)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Description"
                  name="description"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={this.state.description}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors?.description)}
                />
              </Grid>
            </Grid>
          }
        />
      </>
    );
  }
}

export default ConfigDispositif;
