import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import EuroSymbol from "@mui/icons-material/EuroSymbol";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import Home from "@mui/icons-material/Home";
import Info from "@mui/icons-material/Info";
import FamilyRestroom from "@mui/icons-material/FamilyRestroom";
import TrendingUp from "@mui/icons-material/TrendingUp";

// core components
import DatePicker from "components/DatePicker/DatePicker.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import Render from "Utils/RenderUtils";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import MensualiteForm from "views/Forms/Mensualite/MensualiteForm";
import Validation from "Utils/ValidationUtils";
import IbanIMaskInput from "components/CustomInput/IbanIMaskInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DateUtils from "Utils/DateUtils";
import CPUtils from "Utils/CPUtils";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import LightTooltip from "components/Tooltip/LightTooltip";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import TextInput from "components/CustomInput/TextInput";
import FoyerUtils from "Utils/FoyerUtils";
import IRLUtils from "Utils/IRLUtils";
import SelectInput from "components/CustomSelect/SelectInput";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import CLUtils from "Utils/CLUtils";

class ContratsLocatairesForm extends React.Component {
  constructor(props) {
    super(props);
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/contrats-locataires";
    this.FRONT_URL = "/contrats-locataires";

    this.state = {
      // NESTED DATA
      logement: null,
      mensualites: [],
      // DATA FORM
      foyer_id: "",
      id_ciel: "",
      id_ciel_dg: "",
      date_entree: "",
      date_sortie: "",
      montant_caf: "",
      logement_id: +this.QUERY_PARAMS.get("logement_id") || "",
      actif: "",
      last_echeance_mail: "",
      mensualite_loyer: +this.QUERY_PARAMS.get("mensualite_loyer") || "",
      mensualite_charges: +this.QUERY_PARAMS.get("mensualite_charges") || "",
      mensualite_garage: +this.QUERY_PARAMS.get("mensualite_garage") || "",
      mensualite_charges_garage:
        +this.QUERY_PARAMS.get("mensualite_charges_garage") || "",
      mensualite_ordures: +this.QUERY_PARAMS.get("mensualite_ordures") || "",
      mensualite_assurance: "",
      label_loyer: CLUtils.LABEL_LOYER[0],
      loyer_origine: +this.QUERY_PARAMS.get("loyer_origine") || "",
      depot_de_garantie: +this.QUERY_PARAMS.get("depot_de_garantie") || "",
      origine_fin_contrat_id: "",
      date_preavis: "",
      type_paiement_id: "",
      nom_rib: "",
      bic: "",
      iban: "",
      mandat_signature: 0,
      mandat_date_signature: "",
      jour_paiement: +this.QUERY_PARAMS.get("jour_paiement") || "",
      irl_origine_id: "",
      date_revision: "",
      date_revision_custom: new Date(),
      chaudiere_numero_contrat: "",
      chaudiere_debut_contrat: "",
      chaudiere_derniere_revision: "",
      ass_hab_nom: "",
      ass_hab_num: "",
      ass_hab_debut: "",
      ass_hab_fin: "",
      avance_caf: false,
      // END DATA FORM
      loading: false,
      logements: [],
      openMensualiteDialog: false,
      files: [],
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        state.date_revision_custom = new Date(
          result.data.date_revision_custom ?? Date.now(),
        );
        this.setState(state);
      });
  }

  findLogement = (id, logements = null) => {
    logements = logements ?? this.state.logements;
    if (logements != null) {
      return logements.find((l) => l.id === id);
    }
    return null;
  };

  handleOnResponseLogement = (data) => {
    this.setState((prevState) => ({
      logements: data,
      logement:
        this.findLogement(prevState.logement_id, data) ?? prevState.logement,
    }));
  };

  handleChangeLogement = (name, value) => {
    this.setState((prevState) => ({
      [name]: value,
      logement: this.findLogement(value) ?? prevState.logement,
    }));
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeNumber = (event) => {
    let val = event.target.value;
    val = val.replace(/,/g, ".");
    this.setState({ [event.target.name]: val });
  };

  handleChangeCheckbox = (event) => {
    this.handleChangeByName(event.target.name, event.target.checked);
  };

  handleChangeEventIban = (event) => {
    let { name, value } = event.target;
    value = value.replace(/[. ,]/g, "");
    this.setState({ [name]: value });
  };

  // Mensualite
  getDefaultMensualite = () => {
    return {
      id_op_config: "",
      montant: "",
      debit: 1,
      description: "",
    };
  };
  handleAddMensualite = () => {
    this.setState((prevState) => {
      let mensualites = prevState.mensualites;
      mensualites.push(this.getDefaultMensualite());
      return { mensualites: mensualites };
    });
  };
  handleChangeMensualite = (key, name, value) => {
    this.setState((prevState) => {
      let mensualites = prevState.mensualites;
      mensualites[key][name] = value;
      return { mensualites: mensualites };
    });
  };
  handleRemoveMensualite = (key) => {
    this.setState((prevState) => {
      let mensualites = prevState.mensualites;
      mensualites.splice(key, 1);
      return { mensualites: mensualites };
    });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      foyer_id: this.state.foyer_id,
      id_ciel: this.state.id_ciel,
      id_ciel_dg: this.state.id_ciel_dg,
      date_entree: this.state.date_entree,
      date_sortie: this.state.date_sortie,
      montant_caf: this.state.montant_caf,
      logement_id: this.state.logement_id,
      actif: this.state.actif,
      last_echeance_mail: this.state.last_echeance_mail,
      mensualite_loyer: this.state.mensualite_loyer,
      mensualite_charges: this.state.mensualite_charges,
      mensualite_garage: this.state.mensualite_garage,
      mensualite_charges_garage: this.state.mensualite_charges_garage,
      mensualite_ordures: this.state.mensualite_ordures,
      mensualite_assurance: this.state.mensualite_assurance,
      label_loyer: this.state.label_loyer,
      loyer_origine: this.state.loyer_origine,
      depot_de_garantie: this.state.depot_de_garantie,
      date_preavis: this.state.date_preavis,
      origine_fin_contrat_id: this.state.origine_fin_contrat_id,
      type_paiement_id: this.state.type_paiement_id,
      irl_origine_id: this.state.irl_origine_id,
      date_revision: this.state.date_revision,
      date_revision_custom: DateUtils.toDBString(
        this.state.date_revision_custom,
      ),

      // nested entities
      mensualites: this.state.mensualites.map((mensualite) => ({
        ...mensualite,
        config: undefined,
      })),
      financements: this.state.financements,
      nom_rib: this.state.nom_rib,
      bic: this.state.bic,
      iban: this.state.iban,
      mandat_signature: this.state.mandat_signature,
      mandat_date_signature: this.state.mandat_date_signature,
      jour_paiement: this.state.jour_paiement,
      chaudiere_numero_contrat: this.state.chaudiere_numero_contrat,
      chaudiere_debut_contrat: this.state.chaudiere_debut_contrat,
      chaudiere_derniere_revision: this.state.chaudiere_derniere_revision,
      ass_hab_nom: this.state.ass_hab_nom,
      ass_hab_num: this.state.ass_hab_num,
      ass_hab_debut: this.state.ass_hab_debut,
      ass_hab_fin: this.state.ass_hab_fin,
      avance_caf: this.state.avance_caf,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  buildFoyerOptionLabel = (data) => {
    return (
      "FO" + data.id + " " + FoyerUtils.referentsFullName(data.referentsactifs)
    );
  };

  render() {
    const { errors } = this.state;
    const { logement } = this.state;
    const cp = logement?.contratproprietaire;

    const typeRevisionTootip =
      CPUtils.TYPE_REVISION_FORM[cp?.type_revision]?.tooltip;

    return (
      <GridContainer>
        {errors && (
          <GridItem xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </GridItem>
        )}
        <GridItem xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <Info />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Infos Contrat
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Date d'entrée"
                    name="date_entree"
                    type="date"
                    value={this.state.date_entree}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_entree)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Date de préavis"
                    name="date_preavis"
                    type="date"
                    onChange={this.handleChange}
                    value={this.state.date_preavis}
                    error={Boolean(errors?.date_preavis)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Date de sortie"
                    name="date_sortie"
                    type="date"
                    value={this.state.date_sortie}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_sortie)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    label="Origine fin de contrat "
                    name="origine_fin_contrat_id"
                    apiUrl="/config-origine_fin-contrat-cl"
                    onChange={this.handleChangeByName}
                    value={this.state.origine_fin_contrat_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.origine_fin_contrat_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Assurance Habitation Locataire" />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Assureur"
                    name="ass_hab_nom"
                    value={this.state.ass_hab_nom}
                    onChange={this.handleChange}
                    error={Boolean(errors?.ass_hab_nom)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Numéro de contrat"
                    name="ass_hab_num"
                    value={this.state.ass_hab_num}
                    onChange={this.handleChange}
                    error={Boolean(errors?.ass_hab_num)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Date de début assurance"
                    name="ass_hab_debut"
                    type="date"
                    value={this.state.ass_hab_debut}
                    onChange={this.handleChange}
                    error={Boolean(errors?.ass_hab_debut)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Date de fin assurance"
                    name="ass_hab_fin"
                    type="date"
                    value={this.state.ass_hab_fin}
                    onChange={this.handleChange}
                    error={Boolean(errors?.ass_hab_fin)}
                  />
                </GridItem>
                {Boolean(logement?.chaudiere_gaz_individuel) && (
                  <>
                    <GridItem xs={12}>
                      <HeaderTitle label="Chaudière" />
                    </GridItem>
                    <GridItem xs={12} lg={6}>
                      <TextInput
                        label="Numéro de contrat"
                        name="chaudiere_numero_contrat"
                        onChange={this.handleChange}
                        value={this.state.chaudiere_numero_contrat}
                        error={Boolean(errors?.chaudiere_numero_contrat)}
                      />
                    </GridItem>
                    <GridItem xs={12} lg={6}>
                      <TextInput
                        label="Date de debut de contrat"
                        name="chaudiere_debut_contrat"
                        type="date"
                        onChange={this.handleChange}
                        value={this.state.chaudiere_debut_contrat}
                        error={Boolean(errors?.chaudiere_debut_contrat)}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextInput
                        label="Dernière révision"
                        name="chaudiere_derniere_revision"
                        type="date"
                        onChange={this.handleChange}
                        value={this.state.chaudiere_derniere_revision}
                        error={Boolean(errors?.chaudiere_derniere_revision)}
                      />
                    </GridItem>
                  </>
                )}
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="info">
                  <TrendingUp />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  <LightTooltip title={CPUtils.REVISION_TOOLTIP}>
                    <span>
                      Révision du loyer <Info fontSize="small" />
                    </span>
                  </LightTooltip>
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography>
                    <strong>
                      Type de révision{" "}
                      {typeRevisionTootip && (
                        <InfoLightTooltip title={typeRevisionTootip} />
                      )}{" "}
                      :{" "}
                    </strong>
                    {cp?.type_revision}
                  </Typography>
                </GridItem>
                {cp?.type_revision === CPUtils.TYPE_REVISION[3] && (
                  <>
                    <GridItem xs={12} lg={6}>
                      <SelectSearch
                        label={
                          <LightTooltip title={CPUtils.IRL_TOOLTIP}>
                            <span>
                              Choisir un IRL d&apos;origine{" "}
                              <Info fontSize="small" />
                            </span>
                          </LightTooltip>
                        }
                        name="irl_origine_id"
                        apiUrl="/irls"
                        value={this.state.irl_origine_id}
                        onChange={this.handleChangeByName}
                        sortOptions={(a, b) => b.key - a.key}
                        buildOptionLabel={(irl) => IRLUtils.irl(irl)}
                        shrink
                        error={Boolean(errors?.irl_origine_id)}
                      />
                    </GridItem>
                    <GridItem xs={12} lg={6}>
                      <SelectInput
                        label={
                          <LightTooltip title={CPUtils.DATE_REVISION_TOOLTIP}>
                            <span>
                              Date de révision <Info fontSize="small" />
                            </span>
                          </LightTooltip>
                        }
                        name="date_revision"
                        value={this.state.date_revision}
                        onChange={this.handleChange}
                        error={Boolean(errors?.date_revision)}
                      >
                        <MenuItem value="">
                          <em>Non renseigné</em>
                        </MenuItem>
                        {CPUtils.DATE_REVISION.map((type) => (
                          <MenuItem value={type} key={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </SelectInput>
                    </GridItem>
                    {this.state.date_revision === CPUtils.DATE_REVISION[4] && (
                      <GridItem xs={12} lg={6}>
                        <DatePicker
                          label="Date de révision"
                          name="date_revision_custom"
                          value={this.state.date_revision_custom}
                          onChange={this.handleChangeByName}
                          views={["month", "day"]}
                          inputFormat="dd/MM"
                          error={Boolean(errors?.date_revision_custom)}
                        />
                      </GridItem>
                    )}
                  </>
                )}
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="info">
                  <EuroSymbol />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Infos Bancaires
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Nom RIB"
                    name="nom_rib"
                    value={this.state.nom_rib}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nom_rib)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="BIC"
                    name="bic"
                    value={this.state.bic}
                    onChange={this.handleChange}
                    error={Boolean(errors?.bic)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="IBAN"
                    name="iban"
                    onChange={this.handleChangeEventIban}
                    value={this.state.iban}
                    InputProps={{
                      inputComponent: IbanIMaskInput,
                    }}
                    error={Boolean(errors?.iban)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Jour de paiement"
                    name="jour_paiement"
                    onChange={this.handleChange}
                    value={this.state.jour_paiement}
                    error={Boolean(errors?.jour_paiement)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(this.state.mandat_signature)}
                        onChange={this.handleChangeCheckbox}
                        name="mandat_signature"
                      />
                    }
                    label="Mandat de prélèvement signé"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Date de signature du mandat"
                    name="mandat_date_signature"
                    type="date"
                    value={this.state.mandat_date_signature}
                    onChange={this.handleChange}
                    error={Boolean(errors?.mandat_date_signature)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <FamilyRestroom />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Foyer
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <SelectSearch
                    name="foyer_id"
                    label="Choisir un foyer"
                    apiUrl="/foyers?dense=true"
                    value={this.state.foyer_id}
                    onChange={this.handleChangeByName}
                    sortOptions={(a, b) => b.key - a.key}
                    buildOptionLabel={this.buildFoyerOptionLabel}
                    shrink
                    error={Boolean(errors?.foyer_id)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <SelectSearch
                    name="logement_id"
                    label="Choisir un logement"
                    apiUrl="/logements?has=cp&dense=true"
                    value={this.state.logement_id}
                    onChange={this.handleChangeLogement}
                    onResponse={this.handleOnResponseLogement}
                    sortOptions={(a, b) => b.key - a.key}
                    buildOptionLabel={(data) =>
                      Render.fullAddress(data.adresse) + " LP" + data.id
                    }
                    shrink
                    error={Boolean(errors?.logement_id)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <EuroSymbol />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Mensualités
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center" sx={{ paddingBottom: 1 }}>
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    label="Type de paiement"
                    name="type_paiement_id"
                    apiUrl="/config-type-paiement-cl"
                    onChange={this.handleChangeByName}
                    value={this.state.type_paiement_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.type_paiement_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Dépôt de garantie"
                    name="depot_de_garantie"
                    onChange={this.handleChangeNumber}
                    value={this.state.depot_de_garantie}
                    error={Boolean(errors?.depot_de_garantie)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Loyer d'origine"
                    name="loyer_origine"
                    onChange={this.handleChangeNumber}
                    value={this.state.loyer_origine}
                    error={Boolean(errors?.loyer_origine)}
                  />
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <SelectInput
                    label="Label du loyer"
                    name="label_loyer"
                    value={this.state.label_loyer}
                    onChange={this.handleChange}
                    error={Boolean(errors?.label_loyer)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {CLUtils.LABEL_LOYER.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <TextInput
                    label={this.state.label_loyer ?? CLUtils.LABEL_LOYER[0]}
                    name="mensualite_loyer"
                    onChange={this.handleChangeNumber}
                    value={this.state.mensualite_loyer}
                    error={Boolean(errors?.mensualite_loyer)}
                  />
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <TextInput
                    label="Charges"
                    name="mensualite_charges"
                    onChange={this.handleChangeNumber}
                    value={this.state.mensualite_charges}
                    error={Boolean(errors?.mensualite_charges)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Garage"
                    name="mensualite_garage"
                    onChange={this.handleChangeNumber}
                    value={this.state.mensualite_garage}
                    error={Boolean(errors?.mensualite_garage)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Charges garage"
                    name="mensualite_charges_garage"
                    onChange={this.handleChangeNumber}
                    value={this.state.mensualite_charges_garage}
                    error={Boolean(errors?.mensualite_charges_garage)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="TOM"
                    name="mensualite_ordures"
                    onChange={this.handleChangeNumber}
                    value={this.state.mensualite_ordures}
                    error={Boolean(errors?.mensualite_ordures)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Assurance"
                    name="mensualite_assurance"
                    onChange={this.handleChangeNumber}
                    value={this.state.mensualite_assurance}
                    error={Boolean(errors?.mensualite_assurance)}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <CheckBoxInput
                    label="Avance CAF"
                    name="avance_caf"
                    value={this.state.avance_caf}
                    onChange={this.handleChangeByName}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <TextInput
                    label="CAF estimée (APL ou AL)"
                    name="montant_caf"
                    value={this.state.montant_caf}
                    onChange={this.handleChange}
                    error={Boolean(errors?.montant_caf)}
                  />
                </GridItem>
                <MensualiteForm
                  filter="locataire"
                  mensualites={this.state.mensualites}
                  addRow={this.handleAddMensualite}
                  updateRow={this.handleChangeMensualite}
                  removeRow={this.handleRemoveMensualite}
                  errors={Validation.parse(errors, "mensualites.")}
                />
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <FormatListBulleted />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Comptable
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <TextInput
                    label="Numéro identifiant comptable"
                    name="id_ciel"
                    value={this.state.id_ciel}
                    onChange={this.handleChange}
                    error={Boolean(errors?.id_ciel)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Numéro identifiant comptable - Dépôt de garantie"
                    name="id_ciel_dg"
                    value={this.state.id_ciel_dg}
                    onChange={this.handleChange}
                    error={Boolean(errors?.id_ciel_dg)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

ContratsLocatairesForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default ContratsLocatairesForm;
