import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";

// core components
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import Mail from "@mui/icons-material/Mail";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import AlertDialog from "components/AlertDialog/AlertDialog";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import PlaceholderDescription from "components/Popups/PlaceholderDescription";
import ButtonGroup from "@mui/material/ButtonGroup";
import CPUtils from "Utils/CPUtils";
import LightTooltip from "components/Tooltip/LightTooltip";
import { withUserContext } from "context/UserContext";
import SelectInput from "components/CustomSelect/SelectInput";
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

class EmailSender extends Component {
  constructor(props) {
    super(props);
    this.CAN_USE_DOCUMENTATION_PLACEHOLDER = props.user.can(
      "use.documentation.placeholder",
    );

    this.DESTINATAIRES = {
      immobilier: [
        {
          slug: "locataires_with_cl_actifs",
          text: "Locataires avec CL actifs",
        },
        {
          slug: "proprietaires_with_cp_actifs",
          text: "Propriétaires avec CP actifs",
        },
      ],
      travaux: [
        {
          slug: "beneficiaires_with_ad_actifs",
          text: "Bénéficiaires avec AD actifs",
        },
        {
          slug: "beneficiaires_with_dt_actifs",
          text: "Bénéficiaires avec DT actifs",
        },
      ],
    };
    this.DEST_TYPES = {
      locataires_with_cl_actifs: "CL",
      proprietaires_with_cp_actifs: "CP",
      beneficiaires_with_ad_actifs: "AD",
      beneficiaires_with_dt_actifs: "DT",
    };
    this.FILTRES = {
      immobilier: [
        {
          label: "Type de contrat",
          field: "type",
          values: CPUtils.TYPES,
        },
      ],
      travaux: [],
    };

    this.state = {
      import_files: [],
      htmlData: "",
      filledSubject: "",
      recipient: "",
      subject: "",
      templatePath: "",
      loading: false,
      errors: null,
      error: false,
      errorMessage: "Erreur.",
      alert: null,
      email_count: 0,
      openSendAlert: false,
      snackbar: false,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {}

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeResetPreview = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      htmlData: "",
      filledSubject: "",
      templatePath: "",
    });
  };

  buildFiltersJson = () => {
    const { section } = this.props;
    var filters = {};
    this.FILTRES[section].forEach((f) => {
      const val = this.state[f.field] ?? null;
      if (val !== null && val !== "") {
        filters[f.field] = val;
      }
    });
    return JSON.stringify(filters);
  };

  uploadDocument = () => {
    if (this.state.recipient === "") {
      this.setState({
        error: true,
        errorMessage: "Veuillez choisir un destinataire",
      });
      return;
    } else if (this.state.subject === "") {
      this.setState({
        error: true,
        errorMessage: "Veuillez saisir un sujet",
      });
      return;
    }

    const dataIn = {
      subject: this.state.subject,
      recipient: this.state.recipient,
      filters: this.buildFiltersJson(),
      document: this.state.import_files[0],
    };

    axiosApiBackendNoJson
      .post("/custom-email/preview", dataIn, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.setState({
          loading: false,
          htmlData: res.data.html,
          filledSubject: res.data.filled_subject,
          email_count: res.data.email_count,
          templatePath: res.data.template_path,
        });
      })
      .catch((error) => {
        let msg =
          error.response?.status === 400
            ? (error.response?.data?.error?.message ??
              error.response?.data?.error)
            : null;

        this.setState({
          loading: false,
          error: true,
          errorMessage: msg ?? "Une erreur est survenue coté serveur.",
        });
      });

    this.setState({
      htmlData: "",
      filledSubject: "",
      templatePath: "",
      loading: true,
      error: false,
      errorMessage: null,
    });
  };

  sendEmail = () => {
    const dataIn = {
      subject: this.state.subject,
      recipient: this.state.recipient,
      filters: this.buildFiltersJson(),
      template_path: this.state.templatePath,
    };

    axiosApiBackendNoJson
      .post("/custom-email/send-confirmation", dataIn, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        this.setState({
          loading: false,
          openSendAlert: false,
          email_count: 0,
          templatePath: "",
          snackbar: true,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errors: error.response?.data?.errors ?? {},
        });
      });

    this.setState({
      loading: true,
      errors: null,
    });
  };

  alertSendEmail = () => {
    const { email_count } = this.state;
    const { section } = this.props;
    const recipient = this.DESTINATAIRES[section].find(
      (d) => d.slug == this.state.recipient,
    );
    const filtres = this.FILTRES[section]
      .map((f) => {
        let val = f.values[this.state[f.field]] ?? null;
        return val !== null && val !== "" ? f.field + " : " + val : null;
      })
      .filter((v) => v)
      .join(", ");

    return (
      <AlertDialog
        open={this.state.openSendAlert}
        loading={this.state.loading}
        title="Envoyer un email"
        content={
          "Vous êtes sur le point d'envoyer un email à " +
          email_count +
          " " +
          recipient?.text +
          " " +
          filtres
        }
        onConfirm={() => this.sendEmail()}
        confirmLabel="Envoyer"
        confirmColor="primary"
        confirmDelay={5}
        onCancel={() => this.hideAlert()}
        cancelLabel="Annuler"
        cancelColor="onyx"
      />
    );
  };

  hideAlert = () => {
    this.setState({
      openSendAlert: false,
    });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: false });
  };

  sendEmailButton = () => {
    const { user } = this.props;
    return (
      <Button
        disabled={!user.isChef()}
        onClick={() => this.setState({ openSendAlert: true })}
      >
        Envoyer l&apos;email
      </Button>
    );
  };

  render() {
    const { section, user } = this.props;
    const {
      recipient,
      subject,
      errors,
      error,
      errorMessage,
      import_files,
      loading,
      htmlData,
      filledSubject,
      email_count,
    } = this.state;

    return (
      <>
        <Snackbar
          open={this.state.snackbar}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={this.closeSnackbar} severity="success">
            Les emails ont bien été envoyés
          </Alert>
        </Snackbar>
        {this.alertSendEmail()}
        <Card>
          <CardHeader
            avatar={
              <CardAvatar color="social">
                <Mail />
              </CardAvatar>
            }
            title={<Typography variant="h5">Courriel</Typography>}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <SelectInput
                  label="Destinataire"
                  name="recipient"
                  value={recipient}
                  onChange={this.handleChangeResetPreview}
                  margin="none"
                >
                  {this.DESTINATAIRES[section].map((recipient, index) => (
                    <MenuItem key={index} value={recipient.slug}>
                      {recipient.text}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
              {this.FILTRES[section].map((filter, index) => (
                <Grid key={index} item xs={12} lg={6}>
                  <SelectInput
                    label={filter.label}
                    name={filter.field}
                    value={this.state[filter.field] ?? ""}
                    onChange={this.handleChangeResetPreview}
                    displayEmpty
                    margin="none"
                  >
                    <MenuItem value="">Tous</MenuItem>
                    {filter.values.map((value, index) => (
                      <MenuItem key={index} value={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
              ))}
              <Grid item xs={12}>
                <TextField
                  label="Sujet de l'email"
                  name="subject"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  onChange={this.handleChange}
                  value={subject}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors?.subject)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                {error && (
                  <Typography color="error.main">{errorMessage}</Typography>
                )}
                <FilePond
                  labelIdle="Ajoutez un document .docx<br>(MAX 10Mo)"
                  files={import_files}
                  maxTotalFileSize={10000000}
                  acceptedFileTypes={[
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ]}
                  onupdatefiles={(fileItems) => {
                    this.setState({
                      import_files: fileItems.map((fileItem) => fileItem.file),
                      htmlData: "",
                      filledSubject: "",
                      templatePath: "",
                    });
                  }}
                />
                <ButtonGroup variant="contained" fullWidth>
                  {this.CAN_USE_DOCUMENTATION_PLACEHOLDER && (
                    <PlaceholderDescription
                      section={section}
                      type={this.DEST_TYPES[recipient] ?? null}
                      frontUrl={"/courriel/" + section}
                    />
                  )}
                  <LoadingButton
                    loading={loading}
                    onClick={this.uploadDocument}
                  >
                    Voir l&apos;aperçu de l&apos;email
                  </LoadingButton>
                </ButtonGroup>
              </Grid>
            </Grid>
            {Boolean(htmlData) && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography gutterBottom variant="h5">
                  Sujet de l&apos;email :
                </Typography>
                <Typography gutterBottom>{filledSubject}</Typography>
                <Typography gutterBottom variant="h5">
                  Corps de l&apos;email :
                </Typography>
                <Typography
                  gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: htmlData,
                  }}
                />
                {email_count > 0 &&
                  (user.isChef() ? (
                    this.sendEmailButton()
                  ) : (
                    <LightTooltip title="Seuls les chefs de service peuvent envoyer un email.">
                      <span>{this.sendEmailButton()}</span>
                    </LightTooltip>
                  ))}
              </Grid>
            )}
          </CardContent>
        </Card>
      </>
    );
  }
}

EmailSender.propTypes = {
  section: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default withUserContext(EmailSender);
