import React, { Component } from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Notifications from "@mui/icons-material/Notifications";

// core components
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBarService from "services/SearchBarService";
import SearchBar from "components/SearchBar/SearchBar";
import CheckboxRenderer from "components/CustomAgRenderer/CheckboxRenderer";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import ButtonRenderer from "components/CustomAgRenderer/ButtonRenderer";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import AgGridUtils from "Utils/AgGridUtils";

class UnreadNotifications extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/notifications/internal";
    this.FRONT_URL = "/notifications";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      data: [],
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
      getRowStyle: (params) => {
        if (params.data.lu == 0) {
          return { fontWeight: "bold" };
        }
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "lu",
          field: "read_at",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.read_at),
          cellRenderer: CheckboxRenderer,
          cellRendererParams: (params) => ({
            checked: params.value,
            onChange: () => this.onClickCheckbox(params),
          }),
        },
        {
          headerName: "Message",
          field: "data.message",
        },
        {
          minWidth: 100,
          headerName: "Action",
          field: "data.url",
          cellRenderer: ButtonRenderer,
          cellRendererParams: (params) => ({
            buttons: [
              {
                label: "Voir",
                color: "primary",
                onClick: () => {
                  this.props.history.push(params.value);
                },
              },
            ],
          }),
        },
      ]),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }
  loadAsyncData() {
    axiosApiBackend.get(this.BACKEND_URL).then((result) => {
      this.setState({ data: result.data });
    });
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };
  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };
  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };
  onClickCheckbox = (params) => {
    params.data.read_at = params.data.read_at ? null : Date.now();
    axiosApiBackend.put(
      this.BACKEND_URL + "/" + params.data.id + "/readtoogle",
    );
  };
  notificationsAllRead = () => {
    axiosApiBackend.put(this.BACKEND_URL + "/read");
    const now = Date.now();
    this.state.data.map((data) => (data.read_at = now));
    this.gridApi.redrawRows();
  };
  render() {
    return (
      <Grid container sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Notifications />
                </CardAvatar>
              }
              title={<Typography variant="h5">Notifications</Typography>}
            />
            <CardContent>
              <Grid
                container
                alignItems="center"
                columnSpacing={2}
                ref={this.filtersRef}
              >
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
                <Grid item>
                  <Button
                    color="tumblr"
                    size="sm"
                    onClick={this.notificationsAllRead}
                  >
                    Marquer tout comme lu
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

UnreadNotifications.propTypes = {
  history: PropTypes.any,
};

export default UnreadNotifications;
