import React from "react";
import PropTypes from "prop-types";

// @mui/material components

// @mui/icons-material

// core components
import GridItem from "components/Grid/GridItem.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch";
import FormCollection from "components/Form/FormCollection";
import TextInput from "components/CustomInput/TextInput";

class AccompagnementFoyerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  event = (handleChangeFn) => {
    return (event) => handleChangeFn(event.target.name, event.target.value);
  };

  render() {
    const { accompagnements, addRow, updateRow, removeRow, errors } =
      this.props;

    return (
      <FormCollection
        items={accompagnements}
        rowTitle="Accompagnement"
        addRow={addRow}
        removeRow={removeRow}
        tooltipAdd="Ajouter un accompagnement"
        tooltipDelete="Supprimer l'accompagnement"
        dialogDeleteTitle={(rowNumber) =>
          "Voulez-vous supprimer l'accompagnement n° " + rowNumber + " ?"
        }
        formInputs={(accompagnement, key) => {
          return (
            <>
              <GridItem xs={12} lg={6}>
                <SelectSearch
                  name="suivi_id"
                  label="Niveau d'accompagnement"
                  apiUrl="/config-types-suivi"
                  value={accompagnement.suivi_id ?? ""}
                  onChange={(name, value) => updateRow(key, name, value)}
                  buildOptionLabel={(data) => data.value}
                  shrink
                  error={Boolean(errors?.[key + ".suivi_id"])}
                />
              </GridItem>
              <GridItem xs={12} lg={6}>
                <SelectSearch
                  name="nom_accompagnement_id"
                  label="Nom du dispositif d'accompagnement"
                  apiUrl="/config-nom-accompagnement"
                  value={accompagnement.nom_accompagnement_id ?? ""}
                  onChange={(name, value) => updateRow(key, name, value)}
                  buildOptionLabel={(data) => data.value}
                  shrink
                  error={Boolean(errors?.[key + ".nom_accompagnement_id"])}
                />
              </GridItem>
              <GridItem xs={12} lg={6}>
                <TextInput
                  label="Date de début"
                  name="date_debut"
                  type="date"
                  value={accompagnement.date_debut ?? ""}
                  onChange={this.event((name, value) => {
                    updateRow(key, name, value);
                    // Add n+1 to date_fin
                    if (!accompagnement.date_fin) {
                      let dateDebut = new Date(value);
                      dateDebut.setFullYear(dateDebut.getFullYear() + 1);
                      const endDate = dateDebut.toISOString().split("T")[0];
                      updateRow(key, "date_fin", endDate);
                    }
                  })}
                  error={Boolean(errors?.[key + ".date_debut"])}
                />
              </GridItem>
              <GridItem xs={12} lg={6}>
                <TextInput
                  label="Date de fin"
                  name="date_fin"
                  type="date"
                  value={accompagnement.date_fin ?? ""}
                  onChange={this.event((name, value) =>
                    updateRow(key, name, value),
                  )}
                  error={Boolean(errors?.[key + ".date_fin"])}
                />
              </GridItem>
            </>
          );
        }}
      />
    );
  }
}

AccompagnementFoyerForm.propTypes = {
  accompagnements: PropTypes.array.isRequired,
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default AccompagnementFoyerForm;
