import React from "react";
import {
  axiosApiBackend,
  axiosApiBackendNoJson,
} from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Delete from "@mui/icons-material/Delete";
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Edit from "@mui/icons-material/Edit";

// core components
import Button from "components/Button/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import echeancierStyles from "components/Echeancier/echeancierStyles.jsx";
import EcheancierFormPopup from "components/Echeancier/EcheancierFormPopup.jsx";
import DetailsPopover from "components/Echeancier/DetailsPopover.jsx";
import InfoPopover from "components/InfoPopover/InfoPopover.jsx";
import Typography from "@mui/material/Typography";
import FileUtils from "Utils/FileUtils";
import Render from "Utils/RenderUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import DateUtils from "Utils/DateUtils";
import { withUserContext } from "context/UserContext";
import classNames from "classnames";
import CPUtils from "Utils/CPUtils";
import LightTooltip from "components/Tooltip/LightTooltip";

class Month extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: Boolean(props.openedRows.find((val) => props.selector === val)),
    };
  }

  componentDidMount() {}

  generateEcheance = () => {
    const { contractId, annee, mois } = this.props;
    const url =
      "/contrats-locataires/" +
      contractId +
      "/documents/avis-echeance/" +
      annee +
      "/" +
      mois;

    axiosApiBackendNoJson
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        FileUtils.downLoadFile(res.data, res.headers, "avis-echeance.pdf");
      });
  };

  generateRecu = () => {
    const { contractId, annee, mois } = this.props;
    const url =
      "/contrats-locataires/" +
      contractId +
      "/documents/recu/" +
      annee +
      "/" +
      mois;

    axiosApiBackendNoJson
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        FileUtils.downLoadFile(res.data, res.headers, "recu.pdf");
      });
  };

  toggleRow = () => {
    if (this.state.open) {
      this.setState({ open: false });
      this.props.onRowClose(this.props.selector);
    } else {
      this.setState({ open: true });
      this.props.onRowOpen(this.props.selector);
    }
  };

  buildIndicatifPopover = (selector) => {
    return (
      <InfoPopover
        id={"indicatif-popover" + selector}
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        transformOrigin={{ vertical: "center", horizontal: "right" }}
      >
        <Typography>
          Cette opération est présente à titre indicatif. Elle n&apos;est pas
          prise en compte dans les calculs.
        </Typography>
      </InfoPopover>
    );
  };

  buildInFuturePopover = (selector) => {
    return (
      <InfoPopover
        id={"in-future-popover" + selector}
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        transformOrigin={{ vertical: "center", horizontal: "right" }}
        Icon={HourglassEmpty}
        iconColor="error.main"
      >
        <Typography>
          Cette opération est dans le futur. Elle n&apos;est pas prise en compte
          dans les calculs.
        </Typography>
      </InfoPopover>
    );
  };

  buildInFutureRowPopover = (selector) => {
    return (
      <InfoPopover
        id={"in-future-popover" + selector}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
        Icon={HourglassEmpty}
      >
        <Typography>
          Ces operations sont dans le futur et ne sont donc pas encore pris en
          compte dans la dette.
        </Typography>
      </InfoPopover>
    );
  };

  onReverseDate = (valA, valB) => {
    if (valA.date > valB.date) return -1;
    if (valA.date < valB.date) return 1;
    return 0;
  };

  renderMontant = (operation, selector) => {
    return (
      <>
        {Boolean(operation.indicatif) && this.buildIndicatifPopover(selector)}
        {!operation.active_pour_solde && this.buildInFuturePopover(selector)}
        {Render.number(operation.montant)}
      </>
    );
  };

  render() {
    const {
      classes,
      data,
      // isInFuture,
      isCurrentMonth,
      isSuperUser,
      // date,
      openOperationModal,
      openDeleteModal,
      selector,
    } = this.props;

    const { open } = this.state;

    const operations = data.operations
      ?.sort(this.onReverseDate)
      .map((operation, key) => (
        <TableRow
          key={key}
          className={classNames({
            [classes.notCountedRow]:
              operation.indicatif || !operation.active_pour_solde,
          })}
        >
          <TableCell>{Render.date(operation.date)}</TableCell>
          <TableCell>{operation.config.label}</TableCell>
          <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
            {operation.debit === 1 && this.renderMontant(operation, selector)}
          </TableCell>
          <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
            {operation.debit === 0 && this.renderMontant(operation, selector)}
          </TableCell>
          <TableCell>
            {[operation.moyen, operation.id_paiement, operation.description]
              .filter((a) => a)
              .join(", ")}
          </TableCell>
          <TableCell>
            <div className={classes.noWrap}>
              <div>
                <DetailsPopover
                  operation={operation}
                  entityLink="contrats-proprietaires"
                  entityName="CP"
                />
              </div>
              {((isCurrentMonth && !operation.genere_auto) || isSuperUser) && (
                <IconButton
                  size="small"
                  onClick={() =>
                    openOperationModal(operation.debit, null, operation)
                  }
                >
                  <Edit />
                </IconButton>
              )}
              {isSuperUser && (
                <IconButton
                  size="small"
                  onClick={() => openDeleteModal(operation)}
                >
                  <Delete />
                </IconButton>
              )}
            </div>
          </TableCell>
        </TableRow>
      ));

    const resume = data.resume ?? {};

    const hasActiveSolde = data.operations?.some((op) => op.active_pour_solde);

    let classMonthRow = classNames({
      [classes.unsetRow]: true,
      [classes.notCountedRow]: !hasActiveSolde,
      [classes.focusedRow]: open && hasActiveSolde,
    });
    let classDetailRow = classNames({
      [classes.notCountedRow]: !hasActiveSolde,
      [classes.focusedRow]: open && hasActiveSolde,
    });

    return (
      <>
        <TableRow className={classMonthRow} onClick={this.toggleRow}>
          <TableCell style={{ whiteSpace: "nowrap" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              style={{ padding: "0" }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {data.monthName}{" "}
            {!hasActiveSolde && this.buildInFutureRowPopover(selector)}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.loyer, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.charges, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.assurance, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.tom, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.loyer_complet, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.montant_caf, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.residuel, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.autres_operations, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.total_a_payer, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.reglements, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {Render.number(resume.solde_mensuel, 2, "-")}
          </TableCell>
          <TableCell align="right">
            {!hasActiveSolde ? <>-</> : Render.number(resume.solde, 2, "-")}
          </TableCell>
        </TableRow>
        <TableRow className={classDetailRow}>
          <TableCell className={classes.detailCell} colSpan={13}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableContainer
                component={Paper}
                className={classes.detailTableContainer}
              >
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow className={classes.headerDetail}>
                      <TableCell>Date</TableCell>
                      <TableCell>Label</TableCell>
                      <TableCell align="right">Débit</TableCell>
                      <TableCell align="right">Crédit</TableCell>
                      <TableCell>Informations Suplementaires</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>{operations}</TableBody>
                </Table>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                  sx={{ p: 1 }}
                >
                  <Grid item>
                    <Button color="primary" onClick={this.generateEcheance}>
                      Avis d&apos;echeance
                    </Button>
                  </Grid>
                  {Boolean(hasActiveSolde) && (
                    <Grid item>
                      <Button color="primary" onClick={this.generateRecu}>
                        Recu
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

Month.propTypes = {
  contractId: PropTypes.any.isRequired,
  data: PropTypes.object,
  annee: PropTypes.any,
  mois: PropTypes.any,
  date: PropTypes.any,
  isInFuture: PropTypes.bool,
  isCurrentMonth: PropTypes.bool,
  isSuperUser: PropTypes.bool,
  selector: PropTypes.any,
  openedRows: PropTypes.array,
  openOperationModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  onRowOpen: PropTypes.func.isRequired,
  onRowClose: PropTypes.func.isRequired,
  classes: PropTypes.any.isRequired,
};

class Year extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  onMonthNbr = (valA, valB) => {
    if (valA.monthNbr > valB.monthNbr) return -1;
    if (valA.monthNbr < valB.monthNbr) return 1;
    return 0;
  };

  render() {
    const {
      classes,
      contractId,
      data,
      openOperationModal,
      openDeleteModal,
      openedRows,
      onRowOpen,
      onRowClose,
      isSuperUser,
      typePaiement,
    } = this.props;

    let now = new Date();
    const nowDay = now.getDate().toString().padStart(2, "0");
    let monthsToAdd = CPUtils.isTermeEchu(typePaiement) ? 0 : 1;
    let month = (now.getMonth() + monthsToAdd).toString().padStart(2, "0");
    const yearMonthStr = now.getFullYear() + "-" + month;

    return (
      <>
        <TableRow className={classes.unsetRow}>
          <TableCell colSpan={13} className={classes.yearCell}>
            <GridContainer justifyContent="flex-start" alignItems="center">
              <GridItem xs={1}>{data.year}</GridItem>
              <GridItem xs>
                <Divider />
              </GridItem>
            </GridContainer>
          </TableCell>
        </TableRow>
        {data.months.sort(this.onMonthNbr).map((month, y) => (
          <Month
            key={y}
            selector={data.year + "-" + month.monthNbr}
            data={month}
            annee={data.year}
            mois={month.monthNbr}
            classes={classes}
            contractId={contractId}
            date={
              now.getFullYear() === Number(data.year) &&
              now.getMonth() + 1 === Number(month.monthNbr)
                ? data.year + "-" + month.monthNbr + "-" + nowDay
                : data.year + "-" + month.monthNbr + "-01"
            }
            isInFuture={yearMonthStr < data.year + "-" + month.monthNbr}
            isCurrentMonth={yearMonthStr <= data.year + "-" + month.monthNbr}
            isSuperUser={isSuperUser}
            openOperationModal={openOperationModal}
            openDeleteModal={openDeleteModal}
            openedRows={openedRows}
            onRowOpen={onRowOpen}
            onRowClose={onRowClose}
          />
        ))}
      </>
    );
  }
}

Year.propTypes = {
  contractId: PropTypes.any.isRequired,
  data: PropTypes.object,
  openedRows: PropTypes.array,
  onRowOpen: PropTypes.func.isRequired,
  onRowClose: PropTypes.func.isRequired,
  openOperationModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  isSuperUser: PropTypes.bool,
  typePaiement: PropTypes.any,
  classes: PropTypes.any.isRequired,
};

class Echeancier extends React.Component {
  constructor(props) {
    super(props);
    this.IS_SUPERUSER = props.user.can("delete.operationlocataire");

    this.state = {
      contractId: props.contractId,
      data: [],
      openedRows: [],
      alert: null,
    };
    this.operationModal = null;
    this.containerTableNode = null;
    this.tableNode = null;
  }

  addOpenedRow = (key) => {
    this.setState((prevState) => {
      return {
        openedRows: prevState.openedRows.concat([key]),
      };
    });
  };

  removeOpenedRow = (key) => {
    this.setState((prevState) => {
      return {
        openedRows: prevState.openedRows.filter((value) => value !== key),
      };
    });
  };

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData = () => {
    axiosApiBackend
      .get(this.props.dataURL, {
        params: {
          contractId: this.state.contractId,
          vueComptable: 0,
          organiseByYearAndMonth: 1,
        },
      })
      .then((result) => {
        let data = result.data;
        let now = new Date();
        const year = now.getFullYear();
        const monthnb = now.getMonth() + 1;
        const month = monthnb.toString().padStart(2, "0");

        let dataBuilt = this.addCurrentMonth(data, year, month);
        dataBuilt = this.buildDataEcheancier(dataBuilt);

        this.setState({ data: dataBuilt });
      });
  };

  addCurrentMonth = (data, nowYear, nowMonth) => {
    let year = data.find((year) => year.year == nowYear);
    if (year == null) {
      year = { year: nowYear, months: [] };
      data.push(year);
    }
    let month = year.months.find((month) => month.monthNbr == nowMonth);
    if (month == null) {
      month = { monthNbr: nowMonth, resume: {}, operations: [] };
      year.months.push(month);
    }

    return data;
  };

  buildDataEcheancier = (data) => {
    data.forEach((year) => {
      year.months.forEach((month) => {
        let dateMonth = new Date(0, parseInt(month.monthNbr), 0);
        month.monthName = dateMonth.toLocaleString("fr-FR", { month: "long" });
      });
    });

    return data;
  };

  openOperationModal = (isDebit, presetData, operation, overrideData) => {
    this.operationModal.openNew(isDebit, presetData, operation, overrideData);
  };

  //Remove Operation
  openDeleteModal = (operation, loading = false) => {
    this.setState({
      alert: (
        <AlertDialog
          title={
            "Voulez-vous supprimer l'opération " +
            operation.config.label +
            " du " +
            Render.date(operation.date) +
            " de " +
            Render.euro(operation.montant) +
            " au " +
            (operation.debit ? "débit" : "crédit") +
            " ?"
          }
          onConfirm={() => this.handleAlertRemove(operation)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlertRemove}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
          loading={loading}
        >
          {operation.operation != null && (
            <>
              Attention l&apos;opération associée dans l&apos;écheancier du
              propriétaire sera aussi supprimée.
            </>
          )}
        </AlertDialog>
      ),
    });
  };

  clearAlertRemove = () => {
    this.setState({ alert: null });
  };

  handleAlertRemove = (operation) => {
    this.openDeleteModal(operation, true);
    axiosApiBackend.delete(this.props.dataURL + "/" + operation.id).then(() => {
      this.loadAsyncData();
      this.clearAlertRemove();
    });
  };

  onYear = (valA, valB) => {
    if (valA.year > valB.year) return -1;
    if (valA.year < valB.year) return 1;
    return 0;
  };

  render() {
    const { classes, dataURL, typePaiement } = this.props;
    const { contractId } = this.state;

    const fixContainerForSticky =
      this.containerTableNode !== null &&
      this.tableNode !== null &&
      this.containerTableNode.clientWidth >= this.tableNode.clientWidth
        ? classes.fixContainerForSticky
        : "";

    let tmpDate = new Date();
    const dateCpt = DateUtils.toDBString(tmpDate);
    tmpDate.setDate(1);
    if (!CPUtils.isTermeEchu(typePaiement)) {
      tmpDate = DateUtils.addMonths(tmpDate, 1);
    }
    const dateAe = DateUtils.toDBString(tmpDate);
    tmpDate = DateUtils.addMonths(tmpDate, -1);
    const dateAePaiement = DateUtils.toDBString(tmpDate);

    const opData = { date: dateCpt, date_ae: dateAe };
    const paymentData = { date: dateCpt, date_ae: dateAePaiement };

    return (
      <>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          sx={{ py: 1 }}
        >
          <Grid item>
            <Button
              color="primary"
              onClick={() => this.openOperationModal(true, null, null, opData)}
            >
              Ajouter un débit
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={() => this.openOperationModal(false, null, null, opData)}
            >
              Ajouter un crédit
            </Button>
          </Grid>
          <Grid item>
            <LightTooltip
              title="Un paiement correspond à un transfert d'argent depuis ou vers le locataire"
              placement="top"
            >
              <Button
                color="success"
                onClick={() =>
                  this.openOperationModal(null, "PAIEMENT", null, paymentData)
                }
              >
                Ajouter un paiement
              </Button>
            </LightTooltip>
          </Grid>
        </Grid>
        <TableContainer
          component={Paper}
          className={fixContainerForSticky}
          ref={(r) => {
            this.containerTableNode = r;
          }}
        >
          <Table
            size="small"
            aria-label="Echeancier"
            ref={(r) => {
              this.tableNode = r;
            }}
          >
            <TableHead className={classes.header}>
              <TableRow>
                <TableCell>Mois</TableCell>
                <TableCell align="right">Loyer</TableCell>
                <TableCell align="right">Charges</TableCell>
                <TableCell align="right">Assurance</TableCell>
                <TableCell align="right">TOM</TableCell>
                <TableCell align="right">
                  <InfoPopover
                    id="loyer-popover"
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                  >
                    <Typography>
                      Comprends : Loyer, Charges, Assurance, Taxe ordures
                      ménagères, Garage, Charges Garage
                    </Typography>
                  </InfoPopover>
                  Loyer complet
                </TableCell>
                <TableCell align="right">
                  <InfoPopover
                    id="caf-popover"
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                  >
                    <Typography>
                      Montant CAF estimé additionné au montant réel
                    </Typography>
                  </InfoPopover>
                  CAF
                </TableCell>
                <TableCell align="right">Résiduel</TableCell>
                <TableCell align="right">Autres operations</TableCell>
                <TableCell align="right">Total à payer</TableCell>
                <TableCell align="right">Règlements</TableCell>
                <TableCell align="right">Solde mensuel</TableCell>
                <TableCell align="right">Cumul</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.sort(this.onYear).map((year, i) => (
                <Year
                  key={i}
                  data={year}
                  classes={classes}
                  contractId={contractId}
                  openOperationModal={this.openOperationModal}
                  openDeleteModal={this.openDeleteModal}
                  openedRows={this.state.openedRows}
                  onRowOpen={this.addOpenedRow}
                  onRowClose={this.removeOpenedRow}
                  isSuperUser={this.IS_SUPERUSER}
                  typePaiement={typePaiement}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EcheancierFormPopup
          ref={(r) => {
            this.operationModal = r;
          }}
          dataURL={dataURL}
          contractId={contractId}
          typePaiement={typePaiement}
          onSuccess={() => {
            this.loadAsyncData();
            this.props.onSoldeUpdate();
          }}
        />
        {this.state.alert}
      </>
    );
  }
}

Echeancier.propTypes = {
  dataURL: PropTypes.any.isRequired,
  contractId: PropTypes.any.isRequired,
  typePaiement: PropTypes.any,
  onSoldeUpdate: PropTypes.func.isRequired,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withStyles(echeancierStyles)(withUserContext(Echeancier));
