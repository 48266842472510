import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
import DateUtils from "Utils/DateUtils";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import GetApp from "@mui/icons-material/GetApp";
import Euro from "@mui/icons-material/Euro";

// core components
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";
import SearchBarService from "services/SearchBarService";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AgGridUtils from "Utils/AgGridUtils";
import AbortRequest from "services/AbortRequest";
import Render from "Utils/RenderUtils";
import { withUserContext } from "context/UserContext";
import DatePicker from "components/DatePicker/DatePicker.jsx";
import DetailsPopover from "components/Echeancier/DetailsPopover";
import OperationUtils from "Utils/OperationUtils";

class OperationsProprietaire extends Component {
  constructor(props) {
    super(props);
    // this.USER = props.user;
    // this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.ABORT_REQUEST = new AbortRequest();
    this.BACKEND_URL = "/operations-proprietaires";
    this.FRONT_URL = "/comptabilite/operations-proprietaire";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    const now = new Date();

    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      startDate: DateUtils.addMonths(now, -1),
      endDate: now,
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "Propriétaire",
          field: "proprietaire",
          valueGetter: (params) => {
            const pp = params.data.contrat?.logement?.proprietaire;
            return (pp?.nom ?? "") + " " + (pp?.prenom ?? "");
          },
        },
        {
          headerName: "CP",
          field: "id_contrat",
          cellDataType: "number",
        },
        {
          headerName: "Type Contrat",
          field: "contrat.type",
        },
        {
          headerName: "Nom contrat",
          field: "contrat.nom_contrat.value",
        },
        {
          headerName: "Label",
          field: "config.label",
        },
        {
          headerName: "Date",
          field: "date",
          cellDataType: "date",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Montant",
          field: "montant",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Débit/Crébit",
          field: "debit",
          valueGetter: (params) =>
            OperationUtils.renderDebitCredit(params.data.debit),
        },
        {
          headerName: "Moyen de paiement",
          field: "moyen",
        },
        {
          headerName: "Date d'application",
          field: "date_ae",
          cellDataType: "date",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Numéro de paiement",
          field: "id_paiement",
          type: "number",
        },
        {
          headerName: "Description",
          field: "description",
        },
        {
          headerName: "Indicatif",
          field: "indicatif",
          valueFormatter: (params) => Render.boolean(params.value),
        },
        {
          headerName: "Infos",
          field: "popover",
          cellRenderer: (params) => (
            <DetailsPopover
              operation={params.data}
              entityLink="contrats-locataire"
              entityName="CL"
            />
          ),
        },
      ]),
      getRowStyle: (params) => {
        return {
          ...(params.data.indicatif && {
            backgroundColor: OperationUtils.INDICATIF_COLOR,
          }),
        };
      },
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  formatDate(date) {
    let formattedDate = DateUtils.toDBString(date);
    return formattedDate;
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    const params = {
      vueComptable: 1,
      withProprietaire: 1,
      startDate: this.formatDate(this.state.startDate),
      endDate: this.formatDate(this.state.endDate),
    };
    axiosApiBackend
      .get(this.BACKEND_URL, {
        signal: this.ABORT_REQUEST.abortAndGetSignal(),
        params: params,
      })
      .then((result) => {
        this.setState({ data: result.data }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      });
  }

  handleDate = (name, value) => {
    const newState2 = { [name]: value };
    this.setState(newState2, () => this.loadAsyncData());
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(
      "/contrats-proprietaires/detail/" + event.node.data.id_contrat,
    );
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    return (
      <Grid container sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h5">
                  Opérations des Contrats Propriétaires
                </Typography>
              }
            />
            <CardContent>
              <Grid
                container
                alignItems="center"
                columnSpacing={2}
                ref={this.filtersRef}
              >
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    label="Date de debut"
                    name="startDate"
                    value={this.state.startDate}
                    onChange={this.handleDate}
                    textFieldProps={{ margin: "dense", size: "small" }}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    label="Date de fin"
                    name="endDate"
                    value={this.state.endDate}
                    onChange={this.handleDate}
                    textFieldProps={{ margin: "dense", size: "small" }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    startDate={this.state.startDate}
                    pagination={true}
                    onRowDoubleClicked={this.goToView}
                    getRowStyle={this.state.getRowStyle}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
        </MenuBottom>
      </Grid>
    );
  }
}

OperationsProprietaire.propTypes = {
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(OperationsProprietaire);
