import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import Home from "@mui/icons-material/Home";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import Man from "@mui/icons-material/Man";
import Apartment from "@mui/icons-material/Apartment";
import Work from "@mui/icons-material/Work";

// core components
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import Ged from "views/Tables/Ged";
import DocumentTemplatePopup from "components/Popups/DocumentTemplatePopup";
import ColorPicker from "components/ColorPicker/ColorPicker";
import BoxColorRenderer from "components/CustomAgRenderer/BoxColorRenderer";
import { withUserContext } from "context/UserContext";

class BeneficiairesConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    // Ged permissions
    const permission = this.USER.can("config.ged.travaux.beneficiaire");
    this.GED_BENEFICIAIRE_PERMISSIONS = {
      CAN_VIEW: permission,
      CAN_EDIT: permission,
      CAN_DELETE: permission,
    };
    this.FRONT_URL = "/configuration/beneficiaires";
    this.LABEL = "bénéficaire(s)";

    this.state = {
      // DATA FORM
      id: "",
      value: "",
      color_mpr: "",
      // END DATA FORM
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeColor = (value) => {
    this.setState({ color_mpr: value });
  };

  render() {
    const { id, value, color_mpr, errors } = this.state;

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<PeopleAlt />}
            title="Situation"
            backendUrl="/config-situations"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<Home />}
            title="Statut de propriété"
            backendUrl="/config-statut-propriete"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<Work />}
            title="Situation professionelle"
            backendUrl="/config-situation-professionelle"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<Work />}
            title="Caisse retraite principale"
            backendUrl="/config-beneficiaire-crp"
            label={this.LABEL}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {this.GED_BENEFICIAIRE_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="templates"
              section="travaux"
              type="beneficiaire"
              permissions={this.GED_BENEFICIAIRE_PERMISSIONS}
              title="Modèles de documents"
              iconColor="config.main"
              buttonColor="config"
              acceptedFileTypes={[
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              popupComponent={DocumentTemplatePopup}
              frontUrl={this.FRONT_URL}
            />
          )}
          <ConfigContainer
            icon={<Man />}
            title="Civilité"
            backendUrl="/config-civilite-beneficiaire"
            label={this.LABEL}
          />
          <TablePopUpContainer
            dialogProps={{
              fullWidth: true,
            }}
            icon={<Apartment />}
            title="Catégorie de ressources"
            backendUrl="/config-categorie-ressource"
            autoSize={false}
            colDef={[
              { field: "id", editable: false, hide: true },
              {
                headerName: "Libellé",
                field: "value",
                flex: 3,
              },
              {
                headerName: "Couleur MPR",
                field: "color_mpr",
                flex: 1,
                cellRenderer: BoxColorRenderer,
                cellRendererParams: (params) => ({
                  color: params.data.color_mpr,
                }),
              },
            ]}
            formInitData={() => {
              this.setState({
                errors: null,
                id: "",
                value: "",
                color_mpr: "",
              });
            }}
            formSetData={(data) => {
              this.setState({
                errors: null,
                id: data.id ?? "",
                value: data.value ?? "",
                color_mpr: data.color_mpr ?? "",
              });
            }}
            formGetData={() => ({
              id: id,
              value: value,
              color_mpr: color_mpr,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            getDeleteContent={(data) => data.value}
            getDeleteError={(data, error) => (
              <>
                {error?.countBeneficiaires > 0 && (
                  <li>
                    {error.countBeneficiaires} {this.LABEL} associée(s)
                  </li>
                )}
                {error?.countDispositifs > 0 && (
                  <li>{error.countDispositifs} dispositif(s) associé(s)</li>
                )}
              </>
            )}
            formTitle={
              id
                ? "Modifier la Catégorie de ressources"
                : "Ajouter une Catégorie de ressources"
            }
            formConfirmLabel={id ? "Modifier" : "Ajouter"}
            formContent={
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Libellé"
                    name="value"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={value}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ColorPicker
                    label="Couleur MPR"
                    name="color_mpr"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={color_mpr}
                    onChange={this.handleChange}
                    onChangeColor={this.handleChangeColor}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.color_mpr)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BoxColorRenderer color={color_mpr} size={60} />
                </Grid>
              </Grid>
            }
          />
          <ConfigContainer
            icon={<Work />}
            title="Retraite complémentaire"
            backendUrl="/config-retraite-complementaire"
            label={this.LABEL}
          />
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigBeneficiaire"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

BeneficiairesConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(BeneficiairesConfig);
