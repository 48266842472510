import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// @mui/icons-material
import List from "@mui/icons-material/List";
import Edit from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import Description from "@mui/icons-material/Description";

// Services
import SearchBarService from "services/SearchBarService";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Delete from "@mui/icons-material/Delete";
import MenuBottom from "components/MenuBottom/MenuBottom";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import { withUserContext } from "context/UserContext";

class DonneurDordresTravauxView extends React.Component {
  constructor(props) {
    super(props);
    // this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.donneurordres");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/donneurs-ordres";
    this.FRONT_URL = "/donneurs-ordres";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // DATA FORM
      nom: "",
      description: "",
      dashboard_metrics_id: "",
      users: [],
      // END DATA FORM
      errors: null,
      id: props.match.params.id,
      alert: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  //Remove Donneur d'ordres
  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le donneur d'ordres ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer le donneur d'ordres : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.audits > 0 && (
              <li>{nbrEntity.audits} audit(s) associé(s)</li>
            )}
            {nbrEntity.dt > 0 && (
              <li>{nbrEntity.dt} dossier(s) travaux associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const { dashboard_url } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <RecordVoiceOverIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Donneur D&apos;ordres
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Nom : </strong>
                {this.state.nom}
              </Typography>
              <Typography>
                <strong>Description : </strong>
              </Typography>
              <MultilineTypography sx={{ px: 1 }}>
                {this.state.description}
              </MultilineTypography>
              <Typography>
                <strong>Identifiant dashboard : </strong>
                {this.state.dashboard_metrics_id}
              </Typography>
              <Typography>
                <strong>Utilisateurs : </strong>
              </Typography>
              {this.state.users.map((user) => {
                return (
                  <MultilineTypography key={user.id} sx={{ px: 1 }}>
                    {user.first_name} {user.last_name}
                  </MultilineTypography>
                );
              })}
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Box
            sx={{
              backgroundColor: "white",
            }}
          >
            <iframe
              id="metrics"
              src={dashboard_url}
              style={{ border: "0" }}
              width="100%"
              allowFullScreen
              height="600px"
            />
          </Box>
        </GridItem>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={
                this.FRONT_URL + "/logs?type=DonneurOrdres&id=" + this.state.id
              }
            >
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </GridContainer>
    );
  }
}

DonneurDordresTravauxView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(DonneurDordresTravauxView);
