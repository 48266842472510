import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// @mui/icons-material
import Home from "@mui/icons-material/Home";
import Info from "@mui/icons-material/Info";
import LinkIcon from "@mui/icons-material/Link";
import Build from "@mui/icons-material/Build";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch";
import SelectInput from "components/CustomSelect/SelectInput";
import TextInput from "components/CustomInput/TextInput";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import LightTooltip from "components/Tooltip/LightTooltip";
import SectionCadastraleInput from "components/CustomInput/SectionCadastraleInput";
import ParcelleCadastraleInput from "components/CustomInput/ParcelleCadastraleInput";
import DossierTravauxUtils from "Utils/DossierTravauxUtils";
import AuditUtils from "Utils/AuditUtils";
import LogementTravauxUtils from "Utils/LogementTravauxUtils";
import LogementSubTable from "./TravauxSubTables/LogementSubTable";
import AdresseForm from "./Adresse/AdresseForm";
import NumberInput from "components/CustomInput/NumberInput";

class LogementsTravauxForm extends React.Component {
  constructor(props) {
    super(props);
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/logements-travaux";
    this.FRONT_URL = "/logements-travaux";
    this.state = {
      // NESTED DATA
      secteur: null,
      vacant: null,
      type: null,
      typologie: null,
      dossiers_travaux: [],
      audits: [],
      adresse: null,
      // DATA FORM
      beneficiaire_id: +this.QUERY_PARAMS.get("beneficiaire_id") || "",
      type_id: "",
      typologie_id: "",
      annee_construction: "",
      dpe: "",
      is_vacant: 0,
      nombre_total_occupants: "",
      nombre_enfants_occupants: "",
      prefixe_cadastrale: "",
      section_cadastrale: "",
      numero_parcelle_cadastrale: "",
      type_propriete: "",
      secteur_id: "",
      vacant_id: "",
      type_zone_id: "",
      // END DATA FORM
      loading: false,
      dt_actif: null,
      audit_actif: null,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        if (result.data.dossiers_travaux !== null) {
          state.entityLists = {
            audit: result.data.audits,
            dossierTravaux: result.data.dossiers_travaux,
          };
        }
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeAdresse = (newAdresse) => {
    this.setState((prevState) => ({
      adresse: { ...prevState.adresse, ...newAdresse },
    }));
  };

  handleChangeCheckbox = (event) => {
    this.handleChangeByName(event.target.name, event.target.checked);
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      beneficiaire_id: this.state.beneficiaire_id,
      adresse: this.state.adresse,
      type_id: this.state.type_id,
      typologie_id: this.state.typologie_id,
      annee_construction: this.state.annee_construction,
      dpe: this.state.dpe,
      is_vacant: this.state.is_vacant,
      nombre_total_occupants: this.state.nombre_total_occupants,
      nombre_enfants_occupants: this.state.nombre_enfants_occupants,
      prefixe_cadastrale: this.state.prefixe_cadastrale,
      section_cadastrale: this.state.section_cadastrale,
      numero_parcelle_cadastrale: this.state.numero_parcelle_cadastrale,
      type_propriete: this.state.type_propriete,
      secteur_id: this.state.secteur_id,
      type_zone_id: this.state.type_zone_id,
      vacant_id: this.stateis_vacant ? this.state.vacant_id : "",
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      id,
      adresse,
      errors,
      dt_actif,
      audit_actif,
      audits,
      dossiers_travaux,
    } = this.state;

    return (
      <GridContainer>
        {errors && (
          <GridItem xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </GridItem>
        )}
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier le Logement</>
                  ) : (
                    <>Ajouter un Logement</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <AdresseForm
                  adresse={adresse}
                  onChange={this.handleChangeAdresse}
                  errors={errors}
                  isEditMode={Boolean(id)}
                  hideRegion
                />
                <GridItem xs={12}>
                  <HeaderTitle label="Détails de la zone" />
                </GridItem>
                <GridItem xs={6}>
                  <NumberInput
                    label={
                      <LightTooltip
                        title={LogementTravauxUtils.PREFIXE_CADASTRALE}
                      >
                        <span>
                          Préfixe cadastrale <Info fontSize="small" />
                        </span>
                      </LightTooltip>
                    }
                    name="prefixe_cadastrale"
                    value={this.state.prefixe_cadastrale}
                    onChange={this.handleChange}
                    error={Boolean(errors?.prefixe_cadastrale)}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <SectionCadastraleInput
                    label={
                      <LightTooltip
                        title={LogementTravauxUtils.SECTION_CADASTRALE}
                      >
                        <span>
                          Section cadastrale <Info fontSize="small" />
                        </span>
                      </LightTooltip>
                    }
                    name="section_cadastrale"
                    value={this.state.section_cadastrale}
                    onChange={this.handleChange}
                    error={Boolean(errors?.section_cadastrale)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <ParcelleCadastraleInput
                    label={
                      <LightTooltip
                        title={LogementTravauxUtils.PARCELLE_CADASTRALE}
                      >
                        <span>
                          Numéro parcelle cadastrale <Info fontSize="small" />
                        </span>
                      </LightTooltip>
                    }
                    name="numero_parcelle_cadastrale"
                    value={this.state.numero_parcelle_cadastrale}
                    onChange={this.handleChange}
                    error={Boolean(errors?.numero_parcelle_cadastrale)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectSearch
                    name="secteur_id"
                    label="Secteur Géographique"
                    apiUrl="/config-logement-travaux-secteur"
                    onChange={this.handleChangeByName}
                    value={this.state.secteur_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.secteur_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectSearch
                    name="type_zone_id"
                    label="Type de zone"
                    apiUrl="/config-logement-travaux-type-zone"
                    onChange={this.handleChangeByName}
                    value={this.state.type_zone_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.type_zone_id)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Info />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Caractéristiques
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12}>
                  <SelectSearch
                    name="type_id"
                    label="Type de logement"
                    apiUrl="/config-types-logement"
                    onChange={this.handleChangeByName}
                    value={this.state.type_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.type_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectSearch
                    name="typologie_id"
                    label="Typologie du logement"
                    apiUrl="/config-typologies"
                    onChange={this.handleChangeByName}
                    value={this.state.typologie_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.typologie_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectInput
                    name="type_propriete"
                    label="Type de propriété"
                    value={this.state.type_propriete}
                    onChange={this.handleChange}
                    error={Boolean(errors?.type_propriete)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value="Individuel">Individuel</MenuItem>
                    <MenuItem value="Collectif">Collectif</MenuItem>
                  </SelectInput>
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Année de construction"
                    name="annee_construction"
                    value={this.state.annee_construction}
                    onChange={this.handleChange}
                    error={Boolean(errors?.annee_construction)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="DPE"
                    name="dpe"
                    value={this.state.dpe}
                    onChange={this.handleChange}
                    error={Boolean(errors?.dpe)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Occupants" />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Nombre total occupants"
                    name="nombre_total_occupants"
                    value={this.state.nombre_total_occupants}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nombre_total_occupants)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Nombre enfants occupants"
                    name="nombre_enfants_occupants"
                    value={this.state.nombre_enfants_occupants}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nombre_enfants_occupants)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    label="Vacant"
                    control={
                      <Checkbox
                        name="is_vacant"
                        checked={Boolean(this.state.is_vacant)}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    labelPlacement="start"
                  />
                </GridItem>
                {Boolean(this.state.is_vacant) && (
                  <>
                    <GridItem xs={12}>
                      <SelectSearch
                        name="vacant_id"
                        label="Vacant depuis "
                        apiUrl="/config-logement-travaux-vacant"
                        onChange={this.handleChangeByName}
                        value={this.state.vacant_id}
                        buildOptionLabel={(data) => data.value}
                        shrink
                        error={Boolean(errors?.vacant_id)}
                      />
                    </GridItem>
                  </>
                )}
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <LinkIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Liens actifs
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12}>
                  <SelectSearch
                    name="beneficiaire_id"
                    label="Bénéficiaire"
                    apiUrl="/beneficiaires"
                    onChange={this.handleChangeByName}
                    value={this.state.beneficiaire_id}
                    buildOptionLabel={(data) =>
                      (data.prenom ?? "") + " " + (data.nom ?? "")
                    }
                    shrink
                    error={Boolean(errors?.beneficiaire_id)}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <Typography>
                <strong>Audits/Diagnostics : </strong>
                {audit_actif && (
                  <>
                    AD {audit_actif.id} - {audit_actif.type_audit} (
                    {AuditUtils.STATUT[audit_actif.statut]})
                  </>
                )}
              </Typography>
              <br />
              <Typography>
                <strong>Dossier Travaux : </strong>
                {dt_actif && (
                  <>
                    DT{dt_actif.id} (
                    {DossierTravauxUtils.renderStatut(dt_actif.statut)})
                  </>
                )}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Build />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Dossiers Travaux / Audits
                </Typography>
              }
            />
            <CardContent>
              <br />
              <LogementSubTable
                withLinks={false}
                audits={audits}
                dossiers_travaux={dossiers_travaux}
              />
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

LogementsTravauxForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  uiCustomization: PropTypes.object,
};

export default LogementsTravauxForm;
