import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// @mui/material components
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import LinkIcon from "@mui/icons-material/Link";

// core components
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import Button from "components/Button/Button.jsx";
import TimelineLinkCard from "components/Timeline/TimelineLinkCard";

class LinkCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  isNotNullOrEmpty = (a) => {
    return a != null && a !== "";
  };

  timelineCollapse = (key) => {
    this.setState({
      ["collapse" + key]: !this.state["collapse" + key],
    });
  };

  seeAllTextButton = (list, stateKey) => {
    return this.state[stateKey] ? "Réduire" : "Voir tous (" + list.length + ")";
  };

  render() {
    const { links } = this.props;

    return (
      <Card>
        <CardHeader
          avatar={
            <CardAvatar>
              <LinkIcon />
            </CardAvatar>
          }
          title={
            <Typography variant="h6" component="h5">
              Liens actifs
            </Typography>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            {links.map((link, key) => (
              <Grid item xs={12} key={key}>
                <Typography component="span" variant="body1">
                  <strong>{link.header}</strong>
                  {this.isNotNullOrEmpty(link.text) && (
                    <Link to={link.to}>
                      {!link.button === true ? (
                        <>{link.text}</>
                      ) : (
                        <Button color="primary" size="small">
                          {link.text}
                        </Button>
                      )}
                    </Link>
                  )}
                  {link.seeAll && link.seeAll.length > 0 && (
                    <>
                      <Button
                        style={{ marginLeft: 8 }}
                        color="primary"
                        size="small"
                        onClick={() => this.timelineCollapse(key)}
                      >
                        {this.seeAllTextButton(link.seeAll, "collapse" + key)}
                      </Button>
                      <Collapse in={this.state["collapse" + key]}>
                        <TimelineLinkCard items={link.seeAll} />
                      </Collapse>
                    </>
                  )}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

LinkCard.propTypes = {
  links: PropTypes.array.isRequired,
};

export default LinkCard;
