import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

// @mui/icons-material
import Person from "@mui/icons-material/Person";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import FeedIcon from "@mui/icons-material/Feed";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import Euro from "@mui/icons-material/EuroSymbol";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import LinkIcon from "@mui/icons-material/Link";
import AccountBox from "@mui/icons-material/AccountBox";
import Home from "@mui/icons-material/Home";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import SelectInput from "components/CustomSelect/SelectInput";
import PhoneInput from "components/PhoneInput/PhoneInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import TextInput from "components/CustomInput/TextInput";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import AlertDialog from "components/AlertDialog/AlertDialog";
import InfoFiscaleForm from "./InfoFiscale/InfoFiscaleForm";
import Validation from "Utils/ValidationUtils";
import Render from "Utils/RenderUtils";
import DossierTravauxUtils from "Utils/DossierTravauxUtils";
import BeneficiaireSubTable from "views/Forms/TravauxSubTables/BeneficiaireSubTable";
import AdresseForm from "./Adresse/AdresseForm";
import AdresseUtils from "Utils/AdresseUtils";
import BeneficiaireUtils from "Utils/BeneficiaireUtils";
import AbortRequest from "services/AbortRequest";

class BeneficiairesForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/beneficiaires";
    this.FRONT_URL = "/beneficiaires";
    this.ABORT_REQUEST = new AbortRequest();

    this.state = {
      // NESTED DATA
      infos_fiscales: [],
      logementstravaux: [],
      listBeneficiaire: [],
      adresse: null,
      // DATA FORM
      nom: "",
      prenom: "",
      is_address_different: 0,
      contact_info: "",
      tel: "",
      mail: "",
      contact_info_2: "",
      tel_2: "",
      mail_2: "",
      crp_id: "",
      crp_conjoint_id: "",
      ddn: "",
      carte_invalidite: "",
      apa: "",
      situation_id: "",
      handicap: "",
      taux_zero: "",
      nir: "",
      num_pension: "",
      civilite_id: "",
      statut_propriete_id: "",
      categorie_ressource_id: "",
      primo_accedant: false,
      gir: "",
      acc_social_renforce: false,
      situation_professionelle_id: "",
      retraite_complementaire_id: "",
      acces_numerique: true,
      // END DATA FORM
      alert: null,
      dt_actif: null,
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
    // this.getListBeneficiaire();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        if (result.data.logementstravaux !== null) {
          state.entityLists = {
            logementTravaux: result.data.logementstravaux,
          };
        }
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeAdresse = (newAdresse) => {
    this.setState((prevState) => ({
      adresse: { ...prevState.adresse, ...newAdresse },
    }));
  };

  handleChangeCheckbox = (event) => {
    this.handleChangeByName(event.target.name, event.target.checked);
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  getDefaultInfoFiscale = () => {
    return {
      id: "",
      annee: "",
      revenu_fiscal: "",
      revenu_brut: "",
      nb_parts: "",
    };
  };

  handleAddCollection = (field, getDefault) => {
    return () => {
      this.setState((prevState) => {
        let items = prevState[field];
        items.push(getDefault());
        return { [field]: items };
      });
    };
  };

  handleChangeCollection = (field) => {
    return (key, name, value) => {
      this.setState((prevState) => {
        let items = prevState[field];
        items[key][name] = value;
        return { [field]: items };
      });
    };
  };

  handleDeleteCollection = (field) => {
    return (key) => {
      this.setState((prevState) => {
        let items = prevState[field];
        items.splice(key, 1);
        return { [field]: items };
      });
    };
  };

  checkDoublonBeneficiaire = async () => {
    const { nom, prenom, id } = this.state;

    if (nom && prenom) {
      const result = await axiosApiBackend.get(this.BACKEND_URL, {
        signal: this.ABORT_REQUEST.abortAndGetSignal(),
        params: {
          nom: nom,
          prenom: prenom,
        },
      });

      const beneficiaireTrouve = BeneficiaireUtils.findDuplicateBeneficiary(
        result.data,
        nom,
        prenom,
        id,
      );

      if (beneficiaireTrouve) {
        this.setState({
          alert: (
            <AlertDialog
              title="Bénéficiaire déjà enregistré avec le même nom et prénom"
              onCancel={this.clearAlert}
              cancelLabel="Fermer"
              cancelColor="gray"
            >
              <Typography>
                <strong>ID : </strong>
                {beneficiaireTrouve.id}
              </Typography>
              <Typography>
                <strong>Bénéficiaire : </strong>
                <Link
                  to={`${this.BACKEND_URL}/detail/${beneficiaireTrouve.id}`}
                >
                  {BeneficiaireUtils.getNomComplet(beneficiaireTrouve)}
                </Link>
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {beneficiaireTrouve.tel ? (
                  Render.telephoneAsLink(beneficiaireTrouve.tel)
                ) : (
                  <span>Non renseigné</span>
                )}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {beneficiaireTrouve.mail ? (
                  Render.email(beneficiaireTrouve.mail)
                ) : (
                  <span>Non renseigné</span>
                )}
              </Typography>
            </AlertDialog>
          ),
        });
      }
    }
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      nom: this.state.nom,
      prenom: this.state.prenom,
      is_address_different: this.state.is_address_different,
      adresse: this.state.is_address_different
        ? this.state.adresse
        : AdresseUtils.clearAddress(),
      contact_info: this.state.contact_info,
      tel: this.state.tel,
      mail: this.state.mail,
      contact_info_2: this.state.contact_info_2,
      tel_2: this.state.tel_2,
      mail_2: this.state.mail_2,
      crp_id: this.state.crp_id,
      crp_conjoint_id: this.state.crp_conjoint_id,
      ddn: this.state.ddn,
      carte_invalidite: this.state.carte_invalidite,
      apa: this.state.apa,
      situation_id: this.state.situation_id,
      handicap: this.state.handicap,
      taux_zero: this.state.taux_zero,
      nir: this.state.nir,
      num_pension: this.state.num_pension,
      civilite_id: this.state.civilite_id,
      statut_propriete_id: this.state.statut_propriete_id,
      categorie_ressource_id: this.state.categorie_ressource_id,
      primo_accedant: this.state.primo_accedant,
      gir: this.state.gir,
      acc_social_renforce: this.state.acc_social_renforce,
      situation_professionelle_id: this.state.situation_professionelle_id,
      retraite_complementaire_id: this.state.retraite_complementaire_id,
      infos_fiscales: this.state.infos_fiscales,
      acces_numerique: this.state.acces_numerique,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleError = (error) => {
    if (error.response.status == 428) {
      let errorData = error.response?.data?.errors || [];
      let infosFiscales = Object.values(errorData).map(
        (info) =>
          `L'information fiscale de l'année ${info.annee} (${Render.euro(
            info.revenu_fiscal,
          )}) est associée à un dossier travaux`,
      );

      this.setState({
        alert: (
          <AlertDialog
            title="Vous ne pouvez pas supprimer ces informations fiscales :"
            onCancel={this.clearAlert}
            cancelLabel="Ok"
            cancelColor="info"
          >
            <ul>
              {infosFiscales.map((info, key) => (
                <li key={key}>{info}</li>
              ))}
            </ul>
          </AlertDialog>
        ),
      });
    }

    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { id, adresse, infos_fiscales, errors, logementstravaux, dt_actif } =
      this.state;
    const logementtravaux =
      logementstravaux.length > 0
        ? logementstravaux[logementstravaux.length - 1]
        : null;

    return (
      <GridContainer>
        {this.state.alert}
        {errors && (
          <GridItem xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
            {errors.nir && (
              <Typography variant="h6" component="h4" color="error">
                Ce nir est déjà associé à un bénéficiaire.
              </Typography>
            )}
          </GridItem>
        )}
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier le Bénéficiaire</>
                  ) : (
                    <>Ajouter un Bénéficiaire</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12}>
                  <SelectSearch
                    label="Civilité"
                    name="civilite_id"
                    apiUrl="/config-civilite-beneficiaire"
                    onChange={this.handleChangeByName}
                    value={this.state.civilite_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.civilite_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Prénom"
                    name="prenom"
                    value={this.state.prenom}
                    onChange={this.handleChange}
                    onBlur={this.checkDoublonBeneficiaire}
                    error={Boolean(errors?.prenom)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    value={this.state.nom}
                    onChange={this.handleChange}
                    onBlur={this.checkDoublonBeneficiaire}
                    error={Boolean(errors?.nom)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Date de naissance"
                    name="ddn"
                    type="date"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.ddn}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.ddn)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="NIR (numéro sécurité sociale)"
                    name="nir"
                    value={this.state.nir}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nir)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    label="Adresse différente du logement"
                    control={
                      <Checkbox
                        name="is_address_different"
                        checked={Boolean(this.state.is_address_different)}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    labelPlacement="start"
                  />
                </GridItem>
                {Boolean(this.state.is_address_different) && (
                  <AdresseForm
                    adresse={adresse}
                    onChange={this.handleChangeAdresse}
                    errors={errors}
                    isEditMode={Boolean(id)}
                    hideRegion
                  />
                )}
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <PermContactCalendarIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Coordonnées de Contact
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12}>
                  <HeaderTitle label="Contact 1" />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Information contact"
                    name="contact_info"
                    value={this.state.contact_info}
                    onChange={this.handleChange}
                    error={Boolean(errors?.contact_info)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <PhoneInput
                    label="Téléphone"
                    name="tel"
                    value={this.state.tel}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.tel)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label={
                      <>
                        Email{" "}
                        <InfoLightTooltip title="Si l'email est modifié alors que le compte est actif, une réactivation du compte sera nécessaire." />
                      </>
                    }
                    name="mail"
                    value={this.state.mail}
                    onChange={this.handleChange}
                    error={Boolean(errors?.mail)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Contact 2" />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Information contact 2"
                    name="contact_info_2"
                    value={this.state.contact_info_2}
                    onChange={this.handleChange}
                    error={Boolean(errors?.contact_info_2)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <PhoneInput
                    label="Téléphone 2"
                    name="tel_2"
                    value={this.state.tel_2}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.tel_2)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Email 2"
                    name="mail_2"
                    value={this.state.mail_2}
                    onChange={this.handleChange}
                    error={Boolean(errors?.mail_2)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <AccessibilityIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Handicap
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Carte d'invalidité"
                    name="carte_invalidite"
                    value={this.state.carte_invalidite}
                    onChange={this.handleChange}
                    shrink
                    error={Boolean(errors?.carte_invalidite)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Type de handicap"
                    name="handicap"
                    value={this.state.handicap}
                    onChange={this.handleChange}
                    error={Boolean(errors?.handicap)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectInput
                    label="Allocation personnalisée d'autonomie (APA)"
                    name="apa"
                    value={this.state.apa}
                    onChange={this.handleChange}
                    shrink
                    error={Boolean(errors?.apa)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Groupe Iso-Ressources (GIR)"
                    name="gir"
                    value={this.state.gir}
                    onChange={this.handleChange}
                    error={Boolean(errors?.gir)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <FeedIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Situation Professionnelle et Sociale
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    label="Situation professionnelle"
                    name="situation_professionelle_id"
                    apiUrl="/config-situation-professionelle"
                    onChange={this.handleChangeByName}
                    value={this.state.situation_professionelle_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.situation_professionelle_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    name="situation_id"
                    label="Situation familliale"
                    apiUrl="/config-situations"
                    onChange={this.handleChangeByName}
                    value={this.state.situation_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.situation_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    label="Caisse de retraite principale"
                    name="crp_id"
                    apiUrl="/config-beneficiaire-crp"
                    onChange={this.handleChangeByName}
                    value={this.state.crp_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.crp_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    label="Caisse de retraite principale du conjoint"
                    name="crp_conjoint_id"
                    apiUrl="/config-beneficiaire-crp"
                    onChange={this.handleChangeByName}
                    value={this.state.crp_conjoint_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.crp_conjoint_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    label="Caisse de retraite complémentaire"
                    name="retraite_complementaire_id"
                    apiUrl="/config-retraite-complementaire"
                    onChange={this.handleChangeByName}
                    value={this.state.retraite_complementaire_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.retraite_complementaire_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Numéro de pension"
                    name="num_pension"
                    value={this.state.num_pension}
                    onChange={this.handleChange}
                    error={Boolean(errors?.num_pension)}
                  />
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <CheckBoxInput
                    label="Accompagnement social renforcé"
                    name="acc_social_renforce"
                    value={this.state.acc_social_renforce}
                    onChange={this.handleChangeByName}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <AttachFileIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Informations Fiscales
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12}>
                  <InfoFiscaleForm
                    infosFiscales={infos_fiscales}
                    addRow={this.handleAddCollection(
                      "infos_fiscales",
                      this.getDefaultInfoFiscale,
                    )}
                    updateRow={this.handleChangeCollection("infos_fiscales")}
                    removeRow={this.handleDeleteCollection("infos_fiscales")}
                    errors={Validation.parse(errors, "infos_fiscales.")}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Ressources
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12}>
                  <SelectSearch
                    name="statut_propriete_id"
                    label="Statut de propriété"
                    apiUrl="/config-statut-propriete"
                    onChange={this.handleChangeByName}
                    value={this.state.statut_propriete_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.statut_propriete_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectSearch
                    name="categorie_ressource_id"
                    label="Catégorie de ressources"
                    apiUrl="/config-categorie-ressource"
                    onChange={this.handleChangeByName}
                    value={this.state.categorie_ressource_id}
                    buildOptionLabel={(data) => data.value}
                    buildOptionAdditionalData={(data) => ({
                      color_mpr: data.color_mpr,
                    })}
                    renderExtra={(data) => (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: 1,
                          backgroundColor: data.color_mpr,
                          ml: 1,
                        }}
                      />
                    )}
                    shrink
                    error={Boolean(errors?.categorie_ressource_id)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <PlaylistAddIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Autres Informations
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12}>
                  <CheckBoxInput
                    label="Prêt à taux 0 au cours des 5 dernières années"
                    name="taux_zero"
                    value={this.state.taux_zero}
                    onChange={this.handleChangeByName}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CheckBoxInput
                    label="Primo accédant"
                    name="primo_accedant"
                    value={this.state.primo_accedant}
                    onChange={this.handleChangeByName}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CheckBoxInput
                    label="Accès au numérique"
                    name="acces_numerique"
                    value={this.state.acces_numerique}
                    onChange={this.handleChangeByName}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <LinkIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Liens actifs
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Logement : </strong>
                {Render.fullAddress(logementtravaux?.adresse)}
              </Typography>
              <br />
              {dt_actif && (
                <Typography>
                  <strong>Dossier Travaux : </strong>
                  DT{dt_actif.id} (
                  {DossierTravauxUtils.renderStatut(dt_actif.statut)})
                </Typography>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <AccountBox />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Compte Utilisateur
                </Typography>
              }
            />
            <CardContent>
              <GridItem xs={12}>
                <Typography>
                  <strong>
                    {this.state.user ? "Compte actif" : "Compte inactif"}
                  </strong>
                </Typography>
                {this.state.date_last_user_toggle && (
                  <>
                    <Typography>
                      <strong>
                        {this.state.user ? "Activé" : "Désactivé"} le :{" "}
                      </strong>
                      {Render.dateTime(this.state.date_last_user_toggle)}
                    </Typography>
                    {this.state.user &&
                      (this.state.user.last_connection_at ? (
                        <Typography>
                          <strong>Dernière connexion le : </strong>
                          {Render.dateTime(this.state.user.last_connection_at)}
                        </Typography>
                      ) : (
                        <Typography>
                          <strong>Jamais connecté</strong>
                        </Typography>
                      ))}
                  </>
                )}
              </GridItem>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logements
                </Typography>
              }
            />
            <CardContent>
              <br />
              <BeneficiaireSubTable
                withLinks={false}
                logementstravaux={logementstravaux}
              />
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

BeneficiairesForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  uiCustomization: PropTypes.object,
};

export default BeneficiairesForm;
