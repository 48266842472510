import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// @mui/icons-material
import Cancel from "@mui/icons-material/Cancel";
import CheckCircle from "@mui/icons-material/CheckCircle";

class CheckBoxShow extends Component {
  render() {
    const { label, data, checked } = this.props;
    return (
      <Grid container>
        <Grid item>
          <Typography>
            {label && <strong>{label} :&nbsp;</strong>}
            {(0 === data || (true !== data && data)) && <>{data}&nbsp;</>}
          </Typography>
        </Grid>
        {null !== checked && undefined !== checked && (
          <Grid item>
            {checked ? (
              <CheckCircle color="success" />
            ) : (
              <Cancel color="error" />
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

CheckBoxShow.propTypes = {
  checked: PropTypes.any,
  label: PropTypes.string,
  data: PropTypes.any,
};

export default CheckBoxShow;
