import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/system/Box";
import styled from "@mui/material/styles/styled";
import DisableLink from "components/DisableLink/DisableLink";

const StyledTimeline = styled(Timeline)({
  padding: "0",
  margin: "0",
  "& .MuiTimelineItem-root::before": {
    flex: 0,
    padding: 0,
  },
  "& .MuiTimelineDot-root": {
    alignSelf: "center",
  },
  "& .MuiTimelineContent-positionRight": {
    paddingRight: "4px",
  },
  "& .MuiTimelineContent-positionLeft": {
    paddingLeft: "4px",
  },
  "& .MuiPaper-root": {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "3px",
    paddingBottom: "3px",
    overflow: "hidden",
  },
  "& .MuiPaper-root:hover": {
    backgroundColor: "#e7e9eb",
  },
  "& svg": {
    fontSize: "1rem",
  },
});

class CustomTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  handleCollapse = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  buildItem = (item, key) => {
    const { renderDot, renderTo, renderBody } = this.props;

    return (
      <TimelineItem key={key}>
        <TimelineSeparator>
          <TimelineConnector />
          {renderDot(item)}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <DisableLink to={renderTo ? renderTo(item) : null}>
            <Paper elevation={3}>{renderBody(item)}</Paper>
          </DisableLink>
        </TimelineContent>
      </TimelineItem>
    );
  };

  defaultShouldCollapseFn = (item, key, visibleItems) => {
    return key >= visibleItems;
  };

  collapseItem = (item, key, renderedItem, context) => {
    const { itemShouldCollapse } = this.props;
    let shouldCollapseFn = itemShouldCollapse
      ? itemShouldCollapse
      : this.defaultShouldCollapseFn;

    if (shouldCollapseFn(item, key, context.visibleItems)) {
      context.nbCollapsed++;
      return <Collapse in={this.state.collapse}>{renderedItem}</Collapse>;
    }
    return renderedItem;
  };

  getVisibleItemsProp = () => {
    const { items, visibleItems } = this.props;
    return Math.min(Math.max(0, visibleItems), items.length);
  };

  render() {
    const { collapse } = this.state;
    const { items } = this.props;
    const visibleItems = this.getVisibleItemsProp();
    const context = {
      visibleItems: visibleItems,
      nbCollapsed: 0,
    };

    const renderedItems = items.map((item, key) =>
      this.collapseItem(item, key, this.buildItem(item, key), context),
    );

    const shouldCollapse = context.nbCollapsed > 0;

    return (
      <>
        <StyledTimeline>
          {renderedItems}
          {shouldCollapse && (
            <Box display="flex" justifyContent="center">
              <Button size="small" onClick={this.handleCollapse}>
                {collapse ? "Réduire" : "Voir tous"}
              </Button>
            </Box>
          )}
        </StyledTimeline>
      </>
    );
  }
}

CustomTimeline.propTypes = {
  items: PropTypes.array.isRequired,
  itemShouldCollapse: PropTypes.func,
  visibleItems: PropTypes.number,
  renderDot: PropTypes.func.isRequired,
  renderTo: PropTypes.func,
  renderBody: PropTypes.func.isRequired,
};

export default CustomTimeline;
