import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Settings from "@mui/icons-material/Settings";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import SettingsFormContainer from "components/SettingsFormContainer/SettingsFormContainer";

class ImmobilierConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // DATA FORM
      tel: "",
      email: "",
      // END DATA FORM
      errors: null,
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { errors } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <SettingsFormContainer
            icon={<Settings />}
            title="Modifier les Informations Immobilier"
            backendUrl="/settings/immobilier"
            formSetData={(data) => {
              this.setState({
                errors: null,
                tel: data.tel ?? "",
                email: data.email ?? "",
              });
            }}
            formGetData={() => ({
              tel: this.state.tel,
              email: this.state.email,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            formContent={
              <GridContainer>
                {errors && (
                  <GridItem xs={12}>
                    <Typography variant="h6" component="h4" color="error">
                      {Object.entries(errors).length === 0 ? (
                        <>Une erreur est survenue.</>
                      ) : (
                        <>
                          Merci de corriger les champs en rouge du formulaire.
                        </>
                      )}
                    </Typography>
                  </GridItem>
                )}
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Téléphone"
                    name="tel"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.tel}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.tel)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Email"
                    name="email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.email}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.email)}
                  />
                </GridItem>
              </GridContainer>
            }
          />
        </GridItem>
      </GridContainer>
    );
  }
}

ImmobilierConfig.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default ImmobilierConfig;
