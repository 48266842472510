import React from "react";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";

import Button from "components/Button/Button.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FileUtils from "Utils/FileUtils";
import CloudDownload from "@mui/icons-material/CloudDownload";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import CPUtils from "Utils/CPUtils";
import SelectInput from "components/CustomSelect/SelectInput.jsx";

class ExportAllAvisEcheances extends React.Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.YEARS = [
      d.getFullYear(),
      d.getFullYear() - 1,
      d.getFullYear() - 2,
      d.getFullYear() - 3,
    ];
    this.state = {
      annee: d.getFullYear(),
      mois: d.getMonth() + 1,
      type_paiement: CPUtils.TYPE_PAIEMENT[0],
      hasEmail: "",
      concat: false,
      type: [],
      loading: false,
      errorMessage: null,
      open: false,
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  export = () => {
    this.setState({
      loading: true,
      errorMessage: null,
    });
    const url =
      "/documents/avis-echeance/" +
      this.state.annee +
      "/" +
      this.state.mois +
      "/" +
      this.state.type_paiement;

    const hasEmail = this.state.hasEmail === "" ? null : this.state.hasEmail;

    axiosApiBackendNoJson
      .get(url, {
        responseType: "arraybuffer",
        params: {
          hasEmail: hasEmail,
          concat: this.state.concat ? 1 : 0,
        },
      })
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.toggle();
        FileUtils.downLoadFile(res.data, res.headers, "export-all-avis.zip");
      })
      .catch((error) => {
        let errorMessage = null;
        if (error.response && error.response.status === 400) {
          const res = FileUtils.getDataFromArrayBuffer(error.response.data);
          errorMessage = res && res.error && res.error.message;
        }
        this.setState({
          loading: false,
          errorMessage: errorMessage || "Erreur inconnue",
        });
      });
  };

  render() {
    return (
      <>
        <Button
          fullWidth
          color="primary"
          size="small"
          startIcon={<CloudDownload />}
          onClick={this.toggle}
        >
          Avis d&apos;échéances
        </Button>
        <Dialog open={this.state.open} maxWidth="sm" fullWidth={true}>
          <DialogTitle>
            Choisir une période pour télécharger tous les Avis d&apos;échéances
          </DialogTitle>
          <DialogContent>
            {this.state.errorMessage && (
              <Typography color="error">
                <p>{this.state.errorMessage}</p>
              </Typography>
            )}
            <GridContainer>
              <GridItem xs={12} lg={6}>
                <SelectInput
                  label="Mois"
                  name="mois"
                  onChange={this.handleChange}
                  value={this.state.mois}
                >
                  <MenuItem value={1}>Janvier</MenuItem>
                  <MenuItem value={2}>Février</MenuItem>
                  <MenuItem value={3}>Mars</MenuItem>
                  <MenuItem value={4}>Avril</MenuItem>
                  <MenuItem value={5}>Mai</MenuItem>
                  <MenuItem value={6}>Juin</MenuItem>
                  <MenuItem value={7}>Juillet</MenuItem>
                  <MenuItem value={8}>Août</MenuItem>
                  <MenuItem value={9}>Septembre</MenuItem>
                  <MenuItem value={10}>Octobre</MenuItem>
                  <MenuItem value={11}>Novembre</MenuItem>
                  <MenuItem value={12}>Décembre</MenuItem>
                </SelectInput>
              </GridItem>
              <GridItem xs={12} lg={6}>
                <SelectInput
                  label="Année"
                  name="annee"
                  labelId="annee-label"
                  value={this.state.annee}
                  onChange={this.handleChange}
                >
                  {this.YEARS.map((value, key) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </SelectInput>
              </GridItem>
              <GridItem xs={12} lg={6}>
                <SelectInput
                  label="Type de paiement"
                  name="type_paiement"
                  onChange={this.handleChange}
                  value={this.state.type_paiement}
                  shrink
                >
                  <MenuItem value="">
                    <em>Non renseigné</em>
                  </MenuItem>
                  {CPUtils.TYPE_PAIEMENT.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type}
                    </MenuItem>
                  ))}
                </SelectInput>
              </GridItem>
              <GridItem xs={12} lg={6}>
                <SelectInput
                  label="Filtrer par présence d'emails"
                  name="hasEmail"
                  value={this.state.hasEmail}
                  onChange={this.handleChange}
                  displayEmpty
                  shrink
                >
                  <MenuItem value="">Tous</MenuItem>
                  <MenuItem value={0}>Sans emails</MenuItem>
                  <MenuItem value={1}>Avec emails</MenuItem>
                </SelectInput>
              </GridItem>
              <GridItem xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.concat}
                      onChange={this.handleChangeCheckbox}
                      name="concat"
                    />
                  }
                  label="Agréger en un seul fichier"
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggle} color="reversed">
              Annuler
            </Button>
            <Button
              onClick={this.export}
              color="primary"
              loading={this.state.loading}
            >
              Télécharger
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default ExportAllAvisEcheances;
