import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components
import styled from "@mui/system/styled";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Settings from "@mui/icons-material/Settings";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardContent from "components/CardV2/CardContent.jsx";

const CardContentContainer = styled(CardContent)(() => ({
  height: "60vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

class HomeConfig extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="config">
                  <Settings />
                </CardAvatar>
              }
              title={<h3>Bienvenue sur le panneau de configuration</h3>}
            />
            <CardContentContainer>
              <Typography variant="h4">
                Vous pouvez configurer les différents libellés d&apos;HAPY dans
                cet espace
              </Typography>
            </CardContentContainer>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

HomeConfig.propTypes = {
  history: PropTypes.any,
};

export default HomeConfig;
