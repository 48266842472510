import React, { Component } from "react";
import PropTypes from "prop-types";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { axiosApiBackend } from "variables/axiosConfigs";
import { createRef } from "react";
import IsolatedTextField from "components/CustomInput/IsolatedTextField";

class EditableTree extends Component {
  constructor(props) {
    super(props);
    this.textRef = createRef();
    this.state = this.initialState();
    this.state.expandedNodes = props.expandedNodes?.map(String) ?? [];
  }

  initialState = () => {
    return {
      node: null,
      mode: "",
      errors: false,
      dialog: false,
      dialogTitle: "",
      dialogContentText: "",
      dialogConfirmColor: "",
      dialogConfirmLabel: "",
      dialogCancelLabel: "Annuler",
      dialogOnConfirm: null,
    };
  };

  canAddChild = (node) => {
    return (
      !this.props.maxDepth || !node.level || this.props.maxDepth > node.level
    );
  };

  renderTree = (node) => {
    if (node) {
      return (
        <TreeItem
          sx={{
            mt: 0.5,
            "& .MuiTreeItem-group": {
              borderLeft: "1px dashed black",
            },
          }}
          key={node.id}
          nodeId={node.id.toString()}
          label={
            <>
              {node.value}
              {this.canAddChild(node) && (
                <Button
                  sx={{ m: 0.5, px: 0.5, minWidth: 0 }}
                  size="small"
                  color="success"
                  onClick={(e) => this.addDialog(e, node)}
                >
                  <Add fontSize="small" />
                </Button>
              )}

              <Button
                sx={{ m: 0.5, px: 0.5, minWidth: 0 }}
                size="small"
                onClick={(e) => this.editDialog(e, node)}
              >
                <Edit fontSize="small" />
              </Button>

              <Button
                sx={{ m: 0.5, px: 0.5, minWidth: 0 }}
                size="small"
                color="error"
                onClick={(e) => this.deleteDialog(e, node)}
              >
                <Delete fontSize="small" />
              </Button>
            </>
          }
        >
          {Array.isArray(node.children)
            ? node.children.map((child) => this.renderTree(child))
            : null}
        </TreeItem>
      );
    }
  };

  addDialog = (e, node) => {
    e.stopPropagation();

    this.setState({
      node: node,
      mode: "add",
      dialog: true,
      dialogConfirmColor: "success",
      dialogConfirmLabel: "Ajouter",
      dialogOnConfirm: this.handleSave,
      dialogContentText: "",
      dialogTitle:
        node !== null ? (
          <>
            {"Ajouter un élément dans la section "} <b>{node.value}</b>
          </>
        ) : (
          "Ajouter un nouvel élément"
        ),
    });
  };

  handleSave = () => {
    const data = {
      parent_id: this.state.node?.id,
      value: this.textRef.current.getValue(),
    };

    axiosApiBackend
      .post(this.props.backendUrl, data)
      .then((res) => {
        if (this.props.refreshCallback) {
          this.props.refreshCallback();
        }
        this.closeDialog();
        this.setState({
          expandedNodes: [...this.state.expandedNodes, res.data.id.toString()],
        });
      })
      .catch((err) => {
        this.setState({
          errors: err,
        });
      });
  };

  editDialog = (e, node) => {
    e.stopPropagation();
    this.textRef.current.setValue(node?.value);

    this.setState({
      node: node,
      mode: "edit",
      dialog: true,
      dialogConfirmColor: "primary",
      dialogConfirmLabel: "Modifier",
      dialogOnConfirm: this.handleEdit,
      dialogContentText: "",
      dialogTitle: (
        <>
          {"Modifier l'élément "} <b>{node.value}</b>
        </>
      ),
    });
  };

  handleEdit = () => {
    const data = {
      parent_id: this.state.node.parent_id,
      value: this.textRef.current.getValue(),
    };

    axiosApiBackend
      .put(this.props.backendUrl + "/" + this.state.node.id, data)
      .then(() => {
        if (this.props.refreshCallback) {
          this.props.refreshCallback();
        }
        this.closeDialog();
      })
      .catch((err) => {
        this.setState({
          mode: "editError",
          errors: err,
        });
      });
  };

  deleteDialog = (e, node) => {
    e.stopPropagation();

    let warningMessage = "";
    if (node?.children_count > 0) {
      warningMessage = " contient " + node?.children_count + " élément(s).";
    } else if (node?.auditresultats_count > 0) {
      warningMessage =
        ": lié à " + node?.auditresultats_count + " audit(s) résultat(s)";
    }

    this.setState({
      node: node,
      mode: "delete",
      dialog: true,
      dialogConfirmColor: "error",
      dialogConfirmLabel: "Supprimer",
      dialogOnConfirm: this.handleDelete,
      dialogTitle: "Voulez-vous supprimer cet élément ?",
      dialogContentText: node.value + warningMessage,
    });
  };

  handleDelete = () => {
    axiosApiBackend
      .delete(this.props.backendUrl + "/" + this.state.node.id)
      .then(() => {
        if (this.props.refreshCallback) {
          this.props.refreshCallback();
        }
        this.closeDialog();
      })
      .catch((err) => {
        const errorData = err.response.data;
        const errorMessage = (
          <>
            {errorData?.children > 0 && (
              <li>
                {this.state.node.value +
                  " contient " +
                  errorData?.children +
                  " élément(s)."}
              </li>
            )}
            {errorData?.audit_resultats > 0 && (
              <li>
                {errorData.audit_resultats} audit(s) résultat(s) associé(s)
              </li>
            )}
          </>
        );

        this.setState({
          errors: err,
          dialogTitle: "Vous ne pouvez pas supprimer ce champ.",
          dialogContentText: errorMessage,
          dialogConfirmColor: "",
          dialogConfirmLabel: "",
          dialogCancelLabel: "Fermer",
          dialogOnConfirm: null,
        });
      });
  };

  closeDialog = () => {
    this.textRef?.current?.setValue("");
    this.setState(this.initialState());
  };

  handleToggleTree = (event, nodeIds) => {
    this.setState({ expandedNodes: nodeIds });
  };

  render() {
    const { tree, textFieldLabel } = this.props;
    const {
      mode,
      expandedNodes,
      dialog,
      dialogTitle,
      dialogContentText,
      dialogOnConfirm,
      dialogConfirmLabel,
      dialogConfirmColor,
      dialogCancelLabel,
      errors,
    } = this.state;

    return (
      <>
        <TreeView
          disableSelection
          aria-label="controlled"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expandedNodes}
          onNodeToggle={this.handleToggleTree}
        >
          <Button
            sx={{ m: 0.5, px: 0.5, minWidth: 0 }}
            size="small"
            color="success"
            onClick={(e) => this.addDialog(e, null)}
          >
            <Add fontSize="small" />
          </Button>
          {tree && tree.map((item) => this.renderTree(item))}
        </TreeView>

        <AlertDialog
          keepMounted
          open={dialog}
          title={dialogTitle}
          content={dialogContentText}
          loading={false}
          onConfirm={dialogOnConfirm}
          confirmLabel={dialogConfirmLabel}
          confirmColor={dialogConfirmColor}
          onCancel={this.closeDialog}
          cancelLabel={dialogCancelLabel}
          cancelColor="gray"
        >
          {mode !== "delete" && (
            <IsolatedTextField
              inputRef={(input) => input && input.focus()}
              onPressEnter={dialogOnConfirm}
              ref={this.textRef}
              fullWidth
              sx={{ mt: 1 }}
              label={textFieldLabel}
              error={!!errors}
            />
          )}
        </AlertDialog>
      </>
    );
  }
}

EditableTree.propTypes = {
  tree: PropTypes.array.isRequired,
  backendUrl: PropTypes.string.isRequired,
  refreshCallback: PropTypes.func,
  textFieldLabel: PropTypes.string,
  expandedNodes: PropTypes.array,
  maxDepth: PropTypes.number,
};

export default EditableTree;
