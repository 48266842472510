import React, { Component } from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import GetApp from "@mui/icons-material/GetApp";
import AddBox from "@mui/icons-material/AddBox";
import FamilyRestroom from "@mui/icons-material/FamilyRestroom";

// core components
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";
import SearchBarService from "services/SearchBarService";
import Render from "Utils/RenderUtils";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AgGridUtils from "Utils/AgGridUtils";
import FoyerUtils from "Utils/FoyerUtils";
import { withUserContext } from "context/UserContext";

class Foyer extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/foyers";
    this.FRONT_URL = "/tiers/foyers";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);

    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "N° CAF",
          field: "ncaf",
        },
        {
          headerName: "Titulaire contrat",
          field: "referents_name",
          valueGetter: (params) =>
            FoyerUtils.referentsFullName(params.data.referentsactifs),
        },
        {
          headerName: "Nombre de personnes",
          field: "personnes_actives_count",
          cellDataType: "number",
        },
        {
          headerName: "Référents sociaux",
          field: "referents_sociaux",
          valueGetter: (params) => {
            return params.data.referents_sociaux
              .map((rs) => {
                return rs.pivot.is_principal
                  ? Render.fullName(rs) + " (Principal)"
                  : Render.fullName(rs);
              })
              .join(", ");
          },
        },
        {
          headerName: "Situation familiale",
          field: "situation_familiale.value",
        },
        {
          headerName: "Montant total des revenus hors APL",
          field: "montant_total_revenus_decembre",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Dispositif d'accompagnement",
          field: "accompagnement.nom_accompagnement.value",
        },
        {
          headerName: "MSA",
          field: "msa",
        },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend.get(this.BACKEND_URL).then((result) => {
      this.setState({ data: result.data }, () => {
        this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
      });
    });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    return (
      <Grid container sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <FamilyRestroom />
                </CardAvatar>
              }
              title={<Typography variant="h5">Gestion des foyers</Typography>}
            />
            <CardContent>
              <Grid container alignItems="center" ref={this.filtersRef}>
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL + "/ajouter"}>
            <Button size="small" square round>
              <AddBox />
            </Button>
          </Link>
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Foyer"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

Foyer.propTypes = {
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(Foyer);
