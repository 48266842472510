import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// @mui/icons-material
import Savings from "@mui/icons-material/Savings";
import Info from "@mui/icons-material/Info";

// core components
import Render from "Utils/RenderUtils";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import SelectSearch from "components/CustomSelect/SelectSearch";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import SelectInput from "components/CustomSelect/SelectInput";
import Card from "components/CardV2/Card";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import LightTooltip from "components/Tooltip/LightTooltip";

class FinancementListForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/lignes-financements";
    this.STATUT = [
      "Non traité",
      "En attente de paiement",
      "Partiellement reçu",
      "Subvention reçue",
      "Subvention payée",
    ];

    this.state = {
      id: null,
      dossier_travaux_id: props.dossierTravauxId,
      plan_financement: props.plan_financement,
      financeur_id: "",
      montant_prev: "",
      montant_travaux_retenu: "",
      montant_notif: "",
      date_envoi: "",
      notification: "",
      amo_subvention_prevue: "",
      statut: 0,
      amo_subvention_payee: "",
      montant_recu: "",
      info_comptable: "",
      date_demande_paiement: "",
      errors: null,
      numero_dossier: "",
      date_limite_fin_travaux: "",
      date_limite_accord: "",
      date_facturation_amo_subvention: "",
      date_validation_depot_proprietaire: "",
      financeurs: [],
      selected_financeur: {},
      validation_manuelle: 0,
      pourcent_subvention_previsionnel: "",
      pourcent_subvention_notifie: "",
      montants_financement_inclut_amo_subvention: 0,
      actif: true,
      raison_inactif: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeValue = (name, value) => {
    this.setState({
      [name]: value,
      selected_financeur: this.state.financeurs.find(
        (financeur) => financeur.id === value,
      ),
    });
  };

  handleOnResponse = (data) => {
    this.setState({
      financeurs: data,
      selected_financeur: data.find(
        (financeur) => financeur.id === this.state.financeur_id,
      ),
    });
  };

  handleChangeCheckbox = (event) => {
    this.handleChangeByName(event.target.name, event.target.checked);
  };

  render() {
    const {
      cotiPrev,
      cotiPaye,
      amoSansSubPrev,
      amoSansSubPaye,
      statutDT,
      plan_financement,
      title,
      canEdit,
      onUpdate,
    } = this.props;
    const {
      id,
      dossier_travaux_id,
      financeur_id,
      montant_prev,
      montant_travaux_retenu,
      montant_notif,
      date_envoi,
      notification,
      amo_subvention_prevue,
      statut,
      amo_subvention_payee,
      montant_recu,
      info_comptable,
      date_demande_paiement,
      errors,
      numero_dossier,
      date_limite_fin_travaux,
      date_limite_accord,
      date_facturation_amo_subvention,
      date_validation_depot_proprietaire,
      selected_financeur,
      validation_manuelle,
      montants_financement_inclus_amo_subvention,
      information_complementaire,
      actif,
      raison_inactif,
    } = this.state;

    // Frais
    const coti_prev = cotiPrev !== "" ? cotiPrev : 0;
    const coti_paye = cotiPaye !== "" ? cotiPaye : 0;
    const amo_sans_sub_prev = amoSansSubPrev !== "" ? amoSansSubPrev : 0;
    const amo_sans_sub_paye = amoSansSubPaye !== "" ? amoSansSubPaye : 0;

    // Ajouter une logique de vérification du statut du DT pour l'affichage de la partie définitif du plan de financement
    const statutsApresFinTravaux = [
      "cloture",
      "paye",
      "facture",
      "fin_travaux",
      "visite_fin_travaux_programmee",
      "visite_fin_travaux_a_programmer",
    ];
    const showDefinitif = statutsApresFinTravaux.includes(statutDT);

    // Si negatif
    const styleRestAChargePrev =
      plan_financement?.montant_reste_charge_previsionnel < 0
        ? "error.main"
        : "";
    const styleRestAChargeNotif =
      plan_financement?.montant_reste_charge_notifie < 0 ? "error.main" : "";
    const styleRestAChargeDef =
      plan_financement?.montant_reste_charge_definitif < 0 ? "error.main" : "";

    return (
      <GridContainer>
        <GridItem xs={12}>
          <TablePopUpContainer
            icon={<Savings />}
            iconColor="primary"
            dialogProps={{
              fullWidth: true,
            }}
            title={title}
            backendUrl={this.BACKEND_URL}
            getParams={{ dt_id: dossier_travaux_id }}
            autoSize={false}
            hideAddButton={!canEdit}
            hideEditButton={!canEdit}
            hideDeleteButton={!canEdit}
            resizable
            rowClassRules={{
              pale_yellow_bg: (params) =>
                params.data.date_envoi !== null &&
                params.data.notification === null &&
                params.data.actif,
              pale_green_bg: (params) =>
                params.data.date_envoi !== null &&
                params.data.notification !== null &&
                params.data.actif,
              pale_grey_bg: (params) => !params.data.actif,
            }}
            hasGroupHeader
            colDef={[
              {
                headerName: "Financeur",
                children: [
                  {
                    headerName: "Nom",
                    field: "financeur_id",
                    valueGetter: (params) =>
                      params.data.financeur ? params.data.financeur.value : "",
                  },
                ],
              },
              {
                headerName: "Prévisionnel",
                children: [
                  {
                    headerName: "Date d'envoi",
                    field: "date_envoi",
                    valueFormatter: (params) => Render.date(params.value),
                  },
                  {
                    headerName: "Montant",
                    field: "montant_prev",
                    valueFormatter: (params) => Render.euro(params.value),
                  },
                  {
                    headerName: "(%)",
                    field: "pourcent_subvention_previsionnel",
                    valueFormatter: (params) => Render.percent(params.value),
                  },
                ],
              },
              {
                headerName: "Notifié",
                children: [
                  {
                    headerName: "Date notif",
                    field: "notification",
                    valueFormatter: (params) => Render.date(params.value),
                  },
                  {
                    headerName: "Montant",
                    field: "montant_notif",
                    valueFormatter: (params) => Render.euro(params.value),
                  },
                  {
                    headerName: "(%)",
                    field: "pourcent_subvention_notifie",
                    valueFormatter: (params) => Render.percent(params.value),
                  },
                ],
              },
              {
                headerName: "Paiement",
                children: [
                  {
                    headerName: "Date demande",
                    field: "date_demande_paiement",
                    valueFormatter: (params) => Render.date(params.value),
                  },
                  {
                    headerName: "Montant reçu",
                    field: "montant_recu",
                    valueFormatter: (params) => Render.euro(params.value),
                  },
                  {
                    headerName: "Statut",
                    field: "statut",
                    valueFormatter: (params) => this.STATUT[params.value] ?? "",
                  },
                ],
              },
              {
                headerName: "AMO",
                children: [
                  {
                    headerName: "AMO prévu",
                    field: "amo_subvention_prevue",
                    hide: !canEdit,
                    valueFormatter: (params) => Render.euro(params.value),
                  },
                  {
                    headerName: "AMO payé",
                    field: "amo_subvention_payee",
                    hide: !canEdit,
                    valueFormatter: (params) => Render.euro(params.value),
                  },
                  {
                    headerName: "Info comptable",
                    field: "info_comptable",
                    hide: !canEdit,
                  },
                ],
              },
            ]}
            forceRefreshCells={true}
            onChangeData={onUpdate}
            formInitData={() => {
              this.setState({
                id: null,
                financeur_id: "",
                montant_prev: "",
                montant_travaux_retenu: "",
                montant_notif: "",
                date_envoi: "",
                notification: "",
                amo_subvention_prevue: "",
                statut: 0,
                amo_subvention_payee: "",
                montant_recu: "",
                info_comptable: "",
                date_demande_paiement: "",
                numero_dossier: "",
                date_limite_fin_travaux: "",
                date_limite_accord: "",
                date_facturation_amo_subvention: "",
                date_validation_depot_proprietaire: "",
                validation_manuelle: 0,
                errors: null,
                montants_financement_inclus_amo_subvention: 0,
                actif: true,
                raison_inactif: "",
              });
            }}
            formSetData={(data) => {
              this.setState({
                id: data.id ?? null,
                financeur_id: data.financeur_id ?? "",
                montant_prev: data.montant_prev ?? "",
                montant_travaux_retenu: data.montant_travaux_retenu ?? "",
                montant_notif: data.montant_notif ?? "",
                date_envoi: data.date_envoi ?? "",
                notification: data.notification ?? "",
                amo_subvention_prevue: data.amo_subvention_prevue ?? "",
                statut: data.statut ?? 0,
                amo_subvention_payee: data.amo_subvention_payee ?? "",
                montant_recu: data.montant_recu ?? "",
                info_comptable: data.info_comptable ?? "",
                information_complementaire:
                  data.information_complementaire ?? "",
                date_demande_paiement: data.date_demande_paiement ?? "",
                numero_dossier: data.numero_dossier ?? "",
                date_limite_fin_travaux: data.date_limite_fin_travaux ?? "",
                date_limite_accord: data.date_limite_accord ?? "",
                date_facturation_amo_subvention:
                  data.date_facturation_amo_subvention ?? "",
                date_validation_depot_proprietaire:
                  data.date_validation_depot_proprietaire ?? "",
                validation_manuelle: data.validation_manuelle ?? 0,
                errors: null,
                montants_financement_inclus_amo_subvention:
                  data.montants_financement_inclus_amo_subvention ?? 0,
                actif: data.actif ?? true,
                raison_inactif: data.raison_inactif ?? "",
              });
            }}
            formGetData={() => ({
              id: id,
              dossier_travaux_id: dossier_travaux_id,
              financeur_id: financeur_id,
              montant_prev: montant_prev,
              montant_travaux_retenu: montant_travaux_retenu,
              montant_notif: montant_notif,
              date_envoi: date_envoi,
              notification: notification,
              amo_subvention_prevue: amo_subvention_prevue,
              statut: statut,
              amo_subvention_payee: amo_subvention_payee,
              montant_recu: montant_recu,
              info_comptable: info_comptable,
              information_complementaire: information_complementaire,
              date_demande_paiement: date_demande_paiement,
              numero_dossier: numero_dossier,
              date_limite_fin_travaux: date_limite_fin_travaux,
              date_limite_accord: date_limite_accord,
              date_facturation_amo_subvention: date_facturation_amo_subvention,
              date_validation_depot_proprietaire:
                date_validation_depot_proprietaire,
              validation_manuelle: validation_manuelle,
              montants_financement_inclus_amo_subvention:
                montants_financement_inclus_amo_subvention,
              actif: actif,
              raison_inactif: raison_inactif,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            getDeleteContent={(data) =>
              (data?.financeur?.value ? data?.financeur?.value + " : " : "") +
              Render.euro(data.montant_prev)
            }
            dialogDeleteTitle="Voulez vous supprimer cette ligne de financement ?"
            dialogDeleteTitleFail="Vous ne pouvez pas supprimer ce financement."
            formTitle={
              <GridContainer alignItems="center">
                <GridItem>
                  {id ? "Modifier le financement" : "Ajouter un financement"}
                </GridItem>
                {Boolean(id) && (
                  <GridItem>
                    <FormControlLabel
                      label="Actif"
                      control={
                        <Checkbox
                          name="actif"
                          checked={Boolean(this.state.actif)}
                          onChange={this.handleChangeCheckbox}
                        />
                      }
                      labelPlacement="start"
                    />
                  </GridItem>
                )}
              </GridContainer>
            }
            formConfirmLabel={id ? "Modifier" : "Ajouter"}
            formContent={
              <GridContainer>
                {!actif && (
                  <GridItem xs={12}>
                    <TextField
                      label="Raison d'inactivité du financement"
                      name="raison_inactif"
                      multiline
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={raison_inactif}
                      onChange={this.handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(errors?.raison_inactif)}
                    />
                  </GridItem>
                )}
                <GridItem xs={12}>
                  <SelectSearch
                    name="financeur_id"
                    label="Financeur"
                    margin="normal"
                    apiUrl="/config-financeurs"
                    onChange={this.handleChangeValue}
                    onResponse={this.handleOnResponse}
                    buildOptionLabel={(data) => data?.value ?? ""}
                    value={financeur_id}
                    shrink
                    error={Boolean(errors?.financeur_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    label="Numéro dossier"
                    name="numero_dossier"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={numero_dossier}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.numero_dossier)}
                  />
                </GridItem>
                {!selected_financeur?.gestion_interne && (
                  <GridItem xs={12}>
                    <TextField
                      type="date"
                      label="Date de validation de dépôt du propriétaire"
                      name="date_validation_depot_proprietaire"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={date_validation_depot_proprietaire}
                      onChange={this.handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(
                        errors?.date_validation_depot_proprietaire,
                      )}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} lg={6}>
                  <TextField
                    type="date"
                    label="Date limite fin des travaux"
                    name="date_limite_fin_travaux"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={date_limite_fin_travaux}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.date_limite_fin_travaux)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    type="date"
                    label="Date limite d'accord"
                    name="date_limite_accord"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={date_limite_accord}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.date_limite_accord)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Prévisionnel" />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    type="date"
                    label="Date d'envoi"
                    name="date_envoi"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={date_envoi}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.date_envoi)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant subvention"
                    name="montant_prev"
                    value={montant_prev}
                    onChange={this.handleChange}
                    error={Boolean(errors?.montant_prev)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <CurrencyInput
                    label={
                      <LightTooltip title="Montant retenu pour le calcul du financement, habituellement le montant total hors taxe (HT) ou toutes taxes comprises (TTC) des travaux approuvés (devis confirmés).">
                        <span>
                          Montant des travaux retenu <Info fontSize="small" />
                        </span>
                      </LightTooltip>
                    }
                    name="montant_travaux_retenu"
                    value={montant_travaux_retenu}
                    onChange={this.handleChange}
                    error={Boolean(errors?.montant_travaux_retenu)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Notifié" />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    type="date"
                    label="Date de notification"
                    name="notification"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={notification}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.notification)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant subvention"
                    name="montant_notif"
                    value={montant_notif}
                    onChange={this.handleChange}
                    error={Boolean(errors?.montant_notif)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Paiement" />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    type="date"
                    label="Date de demande"
                    name="date_demande_paiement"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={date_demande_paiement}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.date_demande_paiement)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant subvention reçu"
                    name="montant_recu"
                    value={montant_recu}
                    onChange={this.handleChange}
                    error={Boolean(errors?.montant_recu)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CheckBoxInput
                    label="Valider manuellement le paiement"
                    name="validation_manuelle"
                    value={validation_manuelle}
                    onChange={this.handleChangeByName}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectInput
                    label="Statut"
                    name="statut"
                    onChange={this.handleChange}
                    value={statut}
                    shrink
                    error={Boolean(errors?.statut)}
                  >
                    {this.STATUT.map((value, key) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="AMO" />
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    label="L'AMO est incluse dans les montants de subvention renseignées ?"
                    control={
                      <Checkbox
                        name="montants_financement_inclus_amo_subvention"
                        checked={Boolean(
                          this.state.montants_financement_inclus_amo_subvention,
                        )}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    labelPlacement="start"
                  />
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <TextField
                    type="date"
                    label="Date de facturation AMO"
                    name="date_facturation_amo_subvention"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={date_facturation_amo_subvention}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.date_facturation_amo_subvention)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <CurrencyInput
                    label="AMO prévu"
                    name="amo_subvention_prevue"
                    value={amo_subvention_prevue}
                    onChange={this.handleChange}
                    error={Boolean(errors?.amo_subvention_prevue)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <CurrencyInput
                    label="AMO payé"
                    name="amo_subvention_payee"
                    value={amo_subvention_payee}
                    onChange={this.handleChange}
                    error={Boolean(errors?.amo_subvention_payee)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    label="Informations comptables"
                    name="info_comptable"
                    multiline
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={info_comptable}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.info_comptable)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle label="Autres" />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    label="Informations complémentaires"
                    name="information_complementaire"
                    multiline
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={information_complementaire}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.information_complementaire)}
                  />
                </GridItem>
              </GridContainer>
            }
          />
          <Card sx={{ marginTop: "-20px" }}>
            <TableContainer component="div">
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell
                      align="center"
                      colSpan={2}
                      style={{ backgroundColor: "#ecf0f3" }}
                    >
                      PRÉVISIONNEL
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      style={{ backgroundColor: "#ecf0f3" }}
                    >
                      NOTIFIÉ
                    </TableCell>
                    {showDefinitif && (
                      <TableCell
                        align="center"
                        colSpan={2}
                        style={{ backgroundColor: "#ecf0f3" }}
                      >
                        DÉFINITIF
                      </TableCell>
                    )}
                    <TableCell style={{ backgroundColor: "#ecf0f3" }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="right">
                      Total TTC des travaux validés : <br /> Frais de cotisation
                      : <br /> Frais d&apos;AMO subventionnés : <br /> Frais
                      d&apos;AMO non subventionnés :
                    </TableCell>
                    <TableCell align="right">
                      {Render.euro(
                        plan_financement?.montant_total_travaux_ttc_devis,
                      )}
                      <br />
                      {Render.euro(coti_prev)}
                      <br />
                      {Render.euro(
                        plan_financement?.montant_amo_subventions_previsionnel,
                      )}
                      <br />
                      {Render.euro(amo_sans_sub_prev)}
                    </TableCell>
                    <TableCell>
                      {Render.percent(
                        plan_financement?.pourcent_total_travaux_ttc_previsionnel,
                        " (",
                        ")",
                      )}
                      <br />
                      {Render.percent(
                        plan_financement?.pourcent_cotisation_previsionnel,
                        " (",
                        ")",
                      )}
                      <br />
                      {Render.percent(
                        plan_financement?.pourcent_amo_subventions_previsionnel,
                        " (",
                        ")",
                      )}
                      <br />
                      {Render.percent(
                        plan_financement?.pourcent_amo_sans_subvention_previsionnel,
                        " (",
                        ")",
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {Render.euro(
                        plan_financement?.montant_total_travaux_ttc_devis,
                      )}
                      <br />
                      {Render.euro(coti_prev)}
                      <br />
                      {Render.euro(
                        plan_financement?.montant_amo_subventions_previsionnel,
                      )}
                      <br />
                      {Render.euro(amo_sans_sub_prev)}
                    </TableCell>
                    <TableCell>
                      {Render.percent(
                        plan_financement?.pourcent_total_travaux_ttc_notifie,
                        " (",
                        ")",
                      )}
                      <br />
                      {Render.percent(
                        plan_financement?.pourcent_cotisation_notifie,
                        " (",
                        ")",
                      )}
                      <br />
                      {Render.percent(
                        plan_financement?.pourcent_amo_subventions_notifie,
                        " (",
                        ")",
                      )}
                      <br />
                      {Render.percent(
                        plan_financement?.pourcent_amo_sans_subvention_notifie,
                        " (",
                        ")",
                      )}
                    </TableCell>
                    {showDefinitif && (
                      <TableCell align="right">
                        {Render.euro(
                          plan_financement?.montant_total_travaux_ttc_facture,
                        )}
                        <br />
                        {Render.euro(coti_paye)}
                        <br />
                        {Render.euro(
                          plan_financement?.montant_amo_subventions_definitif,
                        )}{" "}
                        <br />
                        {Render.euro(amo_sans_sub_paye)}
                      </TableCell>
                    )}
                    {showDefinitif && (
                      <TableCell>
                        {Render.percent(
                          plan_financement?.pourcent_total_travaux_ttc_definitif,
                          " (",
                          ")",
                        )}
                        <br />
                        {Render.percent(
                          plan_financement?.pourcent_cotisation_definitif,
                          " (",
                          ")",
                        )}
                        <br />
                        {Render.percent(
                          plan_financement?.pourcent_amo_subventions_definitif,
                          " (",
                          ")",
                        )}
                        <br />
                        {Render.percent(
                          plan_financement?.pourcent_amo_sans_subvention_definitif,
                          " (",
                          ")",
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow sx={{ borderBottom: "double" }}>
                    <TableCell align="right">Total des coûts :</TableCell>
                    <TableCell align="right">
                      {Render.euro(
                        plan_financement?.montant_total_cout_previsionnel,
                      )}
                    </TableCell>
                    <TableCell>
                      {plan_financement?.montant_total_cout_previsionnel
                        ? "(100.00 %)"
                        : ""}
                    </TableCell>
                    <TableCell align="right">
                      {Render.euro(
                        plan_financement?.montant_total_cout_notifie,
                      )}
                    </TableCell>
                    <TableCell>
                      {plan_financement?.montant_total_cout_notifie
                        ? "(100.00 %)"
                        : ""}
                    </TableCell>
                    {showDefinitif && (
                      <>
                        <TableCell align="right">
                          {Render.euro(
                            plan_financement?.montant_total_cout_definitif,
                          )}
                        </TableCell>
                        <TableCell>
                          {plan_financement?.montant_total_cout_definitif
                            ? "(100.00 %)"
                            : ""}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      Montant subvention : <br />
                      Montant AMO subventionné :
                    </TableCell>
                    <TableCell align="right">
                      {Render.euro(
                        plan_financement?.montant_subventions_previsionnel,
                      )}
                      <br />
                      {Render.euro(
                        plan_financement?.montant_amo_subventions_previsionnel,
                      )}
                    </TableCell>
                    <TableCell>
                      {Render.percent(
                        plan_financement?.pourcent_subventions_previsionnel,
                        " (",
                        ")",
                      )}
                      <br />
                      {Render.percent(
                        plan_financement?.pourcent_amo_subventions_previsionnel,
                        " (",
                        ")",
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {Render.euro(
                        plan_financement?.montant_subventions_notifie,
                      )}
                      <br />
                      {Render.euro(
                        plan_financement?.montant_amo_subventions_previsionnel,
                      )}
                    </TableCell>
                    <TableCell>
                      {Render.percent(
                        plan_financement?.pourcent_subventions_notifie,
                        " (",
                        ")",
                      )}
                      {!showDefinitif && (
                        <>
                          {" - Avance versée : "}
                          {Render.euro(
                            plan_financement?.montant_subventions_definitif,
                          )}
                        </>
                      )}
                      <br />
                      {Render.percent(
                        plan_financement?.pourcent_amo_subventions_notifie,
                        " (",
                        ")",
                      )}
                    </TableCell>
                    {showDefinitif && (
                      <>
                        <TableCell align="right">
                          {Render.euro(
                            plan_financement?.montant_subventions_definitif,
                          )}{" "}
                          <br />
                          {Render.euro(
                            plan_financement?.montant_amo_subventions_definitif,
                          )}
                        </TableCell>
                        <TableCell>
                          {Render.percent(
                            plan_financement?.pourcent_subventions_definitif,
                            " (",
                            ")",
                          )}
                          <br />
                          {Render.percent(
                            plan_financement?.pourcent_amo_subventions_definitif,
                            " (",
                            ")",
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow sx={{ borderBottom: "double" }}>
                    <TableCell align="right">Total des aides :</TableCell>
                    <TableCell align="right">
                      {" "}
                      {Render.euro(
                        plan_financement?.montant_total_aides_previsionnel,
                      )}
                    </TableCell>
                    <TableCell>
                      {Render.percent(
                        plan_financement?.pourcent_total_aides_previsionnel,
                        " (",
                        ")",
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {Render.euro(
                        plan_financement?.montant_total_aides_notifie,
                      )}
                    </TableCell>
                    <TableCell>
                      {Render.percent(
                        plan_financement?.pourcent_total_aides_notifie,
                        " (",
                        ")",
                      )}
                    </TableCell>
                    {showDefinitif && (
                      <>
                        <TableCell align="right">
                          {" "}
                          {Render.euro(
                            plan_financement?.montant_total_aides_definitif,
                          )}
                        </TableCell>
                        <TableCell>
                          {Render.percent(
                            plan_financement?.pourcent_total_aides_definitif,
                            " (",
                            ")",
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Reste à charge :
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ color: styleRestAChargePrev, fontWeight: "bold" }}
                    >
                      {Render.euro(
                        plan_financement?.montant_reste_charge_previsionnel,
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: styleRestAChargePrev,
                        fontWeight: "bold",
                      }}
                    >
                      {Render.percent(
                        plan_financement?.pourcent_reste_charge_previsionnel,
                        " (",
                        ")",
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ color: styleRestAChargeNotif, fontWeight: "bold" }}
                    >
                      {Render.euro(
                        plan_financement?.montant_reste_charge_notifie,
                      )}
                    </TableCell>
                    <TableCell
                      sx={{ color: styleRestAChargeNotif, fontWeight: "bold" }}
                    >
                      {Render.percent(
                        plan_financement?.pourcent_reste_charge_notifie,
                        " (",
                        ")",
                      )}
                    </TableCell>
                    {showDefinitif && (
                      <>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleRestAChargeDef,
                            fontWeight: "bold",
                          }}
                        >
                          {Render.euro(
                            plan_financement?.montant_reste_charge_definitif,
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: styleRestAChargeDef,
                            fontWeight: "bold",
                          }}
                        >
                          {Render.percent(
                            plan_financement?.pourcent_reste_charge_definitif,
                            " (",
                            ")",
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
FinancementListForm.propTypes = {
  dossierTravauxId: PropTypes.any.isRequired,
  plan_financement: PropTypes.any,
  cotiPrev: PropTypes.any,
  cotiPaye: PropTypes.any,
  amoSansSubPrev: PropTypes.any,
  amoSansSubPaye: PropTypes.any,
  statutDT: PropTypes.any,
  onUpdate: PropTypes.func.isRequired,
  canEdit: PropTypes.any,
  title: PropTypes.string.isRequired,
};

export default FinancementListForm;
