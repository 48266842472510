import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs";

// @mui/material components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

// @mui/icons-material
import Check from "@mui/icons-material/Check";
import Done from "@mui/icons-material/Done";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CardAvatar from "components/CardV2/CardAvatar";
import CardHeader from "components/CardV2/CardHeader";
import Card from "components/CardV2/Card";
import CardContent from "components/CardV2/CardContent";
import AlertDialog from "components/AlertDialog/AlertDialog";

class SettingsFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      alert: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    this.setState({
      loading: true,
    });
    this.props.formSetErrors(null);

    axiosApiBackend.get(this.props.backendUrl).then((result) => {
      this.props.formSetData(result.data);
      this.setState({
        loading: false,
      });
    });
  }

  handleSubmit = () => {
    const dataIn = this.props.formGetData();
    this.setState({
      loading: true,
    });
    this.props.formSetErrors(null);

    axiosApiBackend
      .put(this.props.backendUrl, dataIn)
      .then(() => {
        this.setState({
          loading: false,
        });
        this.displaySuccessAlert();
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  handleError = (error) => {
    this.setState({
      loading: false,
    });
    this.props.formSetErrors(error.response?.data?.errors ?? {});
  };

  displaySuccessAlert = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Enregistré !"
          onConfirm={this.clearAlert}
          confirmLabel="OK"
          confirmColor="success"
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ minWidth: "300px" }}
          >
            <Grid item>
              <Done fontSize="large" style={{ color: "green" }} />
            </Grid>
          </Grid>
        </AlertDialog>
      ),
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const { icon, title } = this.props;
    const { loading } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader
              avatar={<CardAvatar color="config">{icon}</CardAvatar>}
              title={
                <Typography variant="h6" component="h5">
                  {title}
                </Typography>
              }
            />
            <CardContent>
              {this.props.formContent}
              <Grid container>
                <Grid item xs={12}>
                  <LoadingButton
                    size="small"
                    loading={loading}
                    color="success"
                    onClick={this.handleSubmit}
                  >
                    <Check /> Enregistrer
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </GridItem>
        {this.state.alert}
      </GridContainer>
    );
  }
}

SettingsFormContainer.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  backendUrl: PropTypes.string,
  formSetData: PropTypes.func,
  formGetData: PropTypes.func,
  formContent: PropTypes.any,
  formSetErrors: PropTypes.func,
};

export default SettingsFormContainer;
