import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import List from "@mui/icons-material/List";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Person from "@mui/icons-material/Person";
import Description from "@mui/icons-material/Description";

// Services
import SearchBarService from "services/SearchBarService";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import Render from "Utils/RenderUtils";
import LinkCard from "components/LinkCard/LinkCard";
import AlertDialog from "components/AlertDialog/AlertDialog";
import MenuBottom from "components/MenuBottom/MenuBottom";
import { withUserContext } from "context/UserContext";

class PersonnesView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.personne");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/personnes";
    this.FRONT_URL = "/tiers/locataires";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      foyersactifs: [],
      civilite: null,
      // DATA FORM
      nom: "",
      prenom: "",
      date_naissance: "",
      civilite_id: "",
      telephone: "",
      email: "",
      nir: "",
      ville_naissance: "",
      pays_naissance: "",
      nationalite: "",
      type_papier: "",
      // END DATA FORM
      mostRecentCL: null,
      alert: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  buildFoyerLinks = () => {
    const { foyersactifs } = this.state;

    const foyer = foyersactifs.length > 0 ? foyersactifs[0] : null;

    let links = [];
    if (foyer && foyer.id) {
      links.push({
        header: "Foyer : ",
        to: "/tiers/foyers/detail/" + foyer.id,
        text: "FO" + foyer.id,
      });
    } else {
      links.push({
        button: true,
        header: "Foyer : ",
        to: "/tiers/foyers/ajouter?personne_id=" + this.state.id,
        text: "Créer un Foyer",
      });
    }

    return links;
  };

  // Remove Alert

  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };
  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer la personne ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };
  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer cette personne : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.foyer > 0 && (
              <li>{nbrEntity.foyer} foyer(s) associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const {
      id,
      civilite,
      nom,
      prenom,
      date_naissance,
      ville_naissance,
      pays_naissance,
      nationalite,
      type_papier,
      telephone,
      email,
      nir,
    } = this.state;
    const links = this.buildFoyerLinks();

    return (
      <GridContainer>
        <GridItem xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Locataire
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Civilité : </strong>
                {civilite?.value}
              </Typography>
              <Typography>
                <strong>Prénom : </strong>
                {prenom}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {nom}
              </Typography>
              <Typography>
                <strong>Date de naissance : </strong>
                {Render.date(date_naissance)}{" "}
                {Render.age(date_naissance, "(", " ans)")}
              </Typography>
              <Typography>
                <strong>Ville de naissance : </strong>
                {ville_naissance}
              </Typography>
              <Typography>
                <strong>Pays de naissance : </strong>
                {pays_naissance}
              </Typography>
              <Typography>
                <strong>Nationalité : </strong>
                {nationalite}
              </Typography>
              <Typography>
                <strong>Type de papier : </strong>
                {type_papier}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(telephone)}
              </Typography>
              <Typography>
                <strong>Adresse email : </strong>
                {Render.email(email)}
              </Typography>
              <Typography>
                <strong>NIR : </strong>
                {Render.nir(nir)}
              </Typography>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={6}>
          <LinkCard links={links} />
        </GridItem>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Personne&id=" + id}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </GridContainer>
    );
  }
}

PersonnesView.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(PersonnesView);
