import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  axiosApiBackend,
  axiosApiBackendNoJson,
} from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import GetApp from "@mui/icons-material/GetApp";
import Euro from "@mui/icons-material/Euro";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import FileUtils from "Utils/FileUtils";
import Render from "Utils/RenderUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import MenuBottom from "components/MenuBottom/MenuBottom";
import MenuItem from "@mui/material/MenuItem";
import AgGridUtils from "Utils/AgGridUtils";
import debounce from "@mui/material/utils/debounce";
import FoyerUtils from "Utils/FoyerUtils";
import SelectInput from "components/CustomSelect/SelectInput";
import SearchBar from "components/SearchBar/SearchBar";

class OrdresPaiementsCL extends Component {
  constructor(props) {
    super(props);

    this.BACKEND_URL = "/contrats-locataires";
    this.FRONT_URL = "/banque/ordres/cl";
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.NO_YES = ["Non", "Oui"];
    this.NOWDAY = new Date().getDate();

    this.gridApi = null;
    this.gridColumnApi = null;

    this.state = {
      bank_label: null,
      bank2_label: null,
      bankNbr: "",
      total: 0,
      nbTransaction: 0,
      loading: null,
      alert: null,
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: "Locataire",
          field: "referents_name",
          valueGetter: (params) =>
            FoyerUtils.referentsFullName(
              params.data.foyer && params.data.foyer.referentsactifs,
            ),
        },
        {
          headerName: "SOLDE À DÉBITER",
          field: "solde_a_debiter",
          valueFormatter: (params) => -params.value,
        },
        {
          headerName: "JOUR DE PAIEMENT",
          field: "jour_paiement",
          sort: "asc",
        },
        {
          headerName: "DATE D'ENTRÉE",
          field: "date_entree",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "DATE DE SORTIE",
          field: "date_sortie",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "TYPE",
          field: "logement.contratproprietaire.type",
        },
        {
          headerName: "ADRESSE",
          field: "adresse",
          valueGetter: (params) =>
            Render.address(params.data.logement?.adresse),
        },
        {
          headerName: "VILLE",
          field: "logement.adresse.ville.ville",
        },
        {
          headerName: "Type paiement",
          field: "logement.contratproprietaire.type_paiement",
        },
        {
          headerName: "LOYER ORIGINE",
          field: "loyer_origine",
        },
        {
          headerName: "INDICE ORIGINE",
          field: "logement.contratproprietaire.indice_origine",
        },
        {
          headerName: "DEPOT DE GARANTIE",
          field: "depot_de_garantie",
        },
        {
          headerName: "DG CONSERVÉ",
          field: "logement.contratproprietaire.dg_conserve",
          valueFormatter: (params) => this.NO_YES[params.value],
        },
        {
          headerName: "MENSUALITE LOYER",
          field: "mensualite_loyer",
        },
        {
          headerName: "MENSUALITE CHARGES",
          field: "mensualite_charges",
        },
        {
          headerName: "MENSUALITE GARAGE",
          field: "mensualite_garage",
        },
        {
          headerName: "MENSUALITE CHARGES GARAGE",
          field: "mensualite_charges_garage",
        },
        {
          headerName: "MENSUALITE ORDURES",
          field: "mensualite_ordures",
        },
        {
          headerName: "MENSUALITE GESTION",
          field: "logement.contratproprietaire.mensualite_gestion",
        },
        {
          headerName: "LOGEMENT ID",
          field: "logement_id",
        },
      ]),
      defaultColDef: {
        resizable: true,
        sortable: true,
      },
      data: null,
      getRowStyle: (params) => {
        if (params.data.jour_paiement <= this.NOWDAY) {
          return { color: "red" };
        }
      },
      quickFilterText: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
    this.getConfigData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL, {
        params: {
          filters: ["ordrespaiements"],
        },
      })
      .then((result) => {
        this.setState({ data: result.data }, () => {
          setTimeout(() => {
            this.checkPastPaymentDate();
          }, 1);
        });
      });
  }

  getConfigData = () => {
    axiosApiBackend.get("/settings/general").then((result) => {
      this.setState({
        bank_label: result.data?.bank_label ?? null,
        bank2_label: result.data?.bank2_label ?? null,
        bankNbr: 1,
      });
    });
  };

  checkPastPaymentDate = () => {
    if (this.gridApi == null) {
      return;
    }

    const today = new Date().getTime();
    this.gridApi.forEachNode((node) => {
      const { jour_paiement, date_entree, date_sortie } = node.data;

      if (date_sortie && Date.parse(date_sortie) <= today) {
        return;
      }
      const isSelected =
        jour_paiement &&
        date_entree &&
        jour_paiement <= this.NOWDAY &&
        Date.parse(date_entree) <= today;

      node.setSelected(Boolean(isSelected));
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  resetSearchValue = () => {
    this.setState({ quickFilterText: null });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };
  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  onSelectionChanged = debounce(() => {
    let selectedRows = this.gridApi.getSelectedRows();
    let usefullRows = selectedRows.filter(
      (row) => Math.abs(row.solde_a_debiter) > 0,
    );
    let total = usefullRows.reduce(
      (acc, row) => (acc += Math.abs(row.solde_a_debiter)),
      0,
    );

    this.setState({
      total: total,
      nbTransaction: usefullRows.length,
    });
  }, 100);

  generateFile = () => {
    this.setState({ loading: true });
    let selectedRows = this.gridApi.getSelectedRows();
    let clIds = selectedRows.map((row) => row.id);
    let { bankNbr } = this.state;
    axiosApiBackendNoJson
      .post("/banque/ordres-prelevements/cl", {
        clIds: clIds,
        bankNbr: bankNbr,
      })
      .then((res) => {
        const file = res.data.file ?? {};
        FileUtils.downLoadFile(file.content, file.headers, "file.xml");
        this.successAlert();
      })
      .catch((err) => {
        const error =
          err.response.status === 422
            ? { message: "Veuillez selectionner des Contrats." }
            : err.response?.data?.error;
        this.errorAlert(error);
      });
  };

  successAlert = () => {
    this.setState({
      loading: false,
      alert: (
        <AlertDialog
          title="Le fichier a été généré avec succès"
          onCancel={this.clearAlert}
          cancelLabel="Fermer"
          cancelColor="success"
        />
      ),
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  errorAlert = (err) => {
    const message = err?.message ?? "";
    const clIds = err?.cls ?? [];
    const code = err?.code ?? 0;
    let redirectUrl = "/contrats-locataires/modifier/";

    if (code === 4) {
      redirectUrl = "/contrats-locataires/detail/";
    }

    this.setState({
      loading: false,
      alert: (
        <AlertDialog
          title={"Erreur : " + message}
          onCancel={this.clearAlert}
          cancelLabel="OK"
          cancelColor="error"
        >
          {clIds.length > 0 && (
            <ul>
              {clIds.map((id, key) => (
                <li key={key}>
                  <Link
                    to={redirectUrl + id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CL{id}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </AlertDialog>
      ),
    });
  };

  render() {
    const { loading } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Prélèvements Locataires
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.handleChange}
                    resetSearchValue={this.resetSearchValue}
                  />
                </GridItem>
                <GridItem>{Render.euro(this.state.total, "TOTAL : ")}</GridItem>
                <GridItem>
                  Nombre de transations : {this.state.nbTransaction}
                </GridItem>
                <GridItem>
                  <SelectInput
                    label="Compte bancaire"
                    name="bankNbr"
                    value={this.state.bankNbr}
                    onChange={this.handleChange}
                    margin="dense"
                    size="small"
                  >
                    {this.state.bank_label && (
                      <MenuItem value={1}>{this.state.bank_label}</MenuItem>
                    )}
                    {this.state.bank2_label && (
                      <MenuItem value={2}>{this.state.bank2_label}</MenuItem>
                    )}
                  </SelectInput>
                </GridItem>
                <GridItem>
                  <Button
                    color="success"
                    onClick={this.generateFile}
                    loading={loading}
                  >
                    Générer Fichier Ordres de Prélèvements
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer
                style={{
                  minHeight: "300px",
                }}
              >
                <GridItem xs={12} className="ag-theme-material">
                  <AgGridReact
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    rowSelection="multiple"
                    rowMultiSelectWithClick={true}
                    domLayout="autoHeight"
                    paginationPageSize="10"
                    onPaginationChanged={this.autoSizeAll}
                    onSelectionChanged={this.onSelectionChanged}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <MenuBottom>
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
        </MenuBottom>
        {this.state.alert}
      </GridContainer>
    );
  }
}

OrdresPaiementsCL.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default OrdresPaiementsCL;
