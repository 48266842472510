import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import TextField from "@mui/material/TextField";

// @mui/icons-material

// core components
import GridItem from "components/Grid/GridItem.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch";
import FormCollection from "components/Form/FormCollection";

class FinancementLogementForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  event = (handleChangeFn) => {
    return (event) => handleChangeFn(event.target.name, event.target.value);
  };

  render() {
    const { financements, addRow, updateRow, removeRow, errors } = this.props;

    return (
      <FormCollection
        items={financements}
        rowTitle="Financement"
        addRow={addRow}
        removeRow={removeRow}
        tooltipAdd="Ajouter un financement"
        tooltipDelete="Supprimer le financement"
        dialogDeleteTitle={(rowNumber) =>
          "Voulez-vous supprimer le financement n° " + rowNumber + " ?"
        }
        formInputs={(financement, key) => (
          <>
            <GridItem xs={12} lg={6}>
              <SelectSearch
                label="Financeur"
                name="financeur_id"
                apiUrl="/config-logement-financeurs"
                onChange={(name, value) => {
                  updateRow(key, name, value);
                  updateRow(key, "convention_id", "");
                }}
                value={financement.financeur_id ?? ""}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(errors?.[key + ".financeur_id"])}
              />
            </GridItem>
            <GridItem xs={12} lg={6}>
              <SelectSearch
                label="Convention"
                name="convention_id"
                loadOnMount={Boolean(financement.financeur_id)}
                apiUrl="/config-logement-conventions"
                filterKey="financeur"
                filter={financement.financeur_id}
                onChange={(name, value) => updateRow(key, name, value)}
                value={financement.convention_id ?? ""}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(errors?.[key + ".convention_id"])}
                disabled={!financement.financeur_id}
              />
            </GridItem>
            <GridItem xs={12} lg={6}>
              <TextField
                label="Date de début"
                name="date_debut"
                type="date"
                fullWidth
                margin="normal"
                variant="outlined"
                value={financement.date_debut ?? ""}
                onChange={this.event((name, value) =>
                  updateRow(key, name, value),
                )}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.[key + ".date_debut"])}
              />
            </GridItem>
            <GridItem xs={12} lg={6}>
              <TextField
                label="Date de fin"
                name="date_fin"
                type="date"
                fullWidth
                margin="normal"
                variant="outlined"
                value={financement.date_fin ?? ""}
                onChange={this.event((name, value) =>
                  updateRow(key, name, value),
                )}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.[key + ".date_fin"])}
              />
            </GridItem>
          </>
        )}
      />
    );
  }
}

FinancementLogementForm.propTypes = {
  financements: PropTypes.array.isRequired,
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default FinancementLogementForm;
