import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import WorkIcon from "@mui/icons-material/Work";
import InfoIcon from "@mui/icons-material/Info";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import PhoneInput from "components/PhoneInput/PhoneInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import { withUiCustomizationContext } from "context/UiCustomizationContext";
import TextInput from "components/CustomInput/TextInput";

class EntreprisesForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/entreprises";
    this.FRONT_URL = (props.urlPrefix ?? "") + "/entreprises/" + props.section;
    const uiCustomization = props.uiCustomization;

    this.state = {
      // DATA FORM
      nom: "",
      siret: "",
      rc_numero: "",
      gerant: "",
      tel: "",
      email: "",
      zone_activite: "",
      infos: "",
      secteur_activite: "",
      chiffre_affaire: "",
      commune_siege_id: "",
      // END DATA FORM
      loading: false,
      dep_code: uiCustomization?.dep_code ?? "",
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id, {
        params: { section: this.props.section },
      })
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        const cp = result.data.commune_siege?.cp;
        if (cp) {
          state.dep_code = cp.substring(0, 2);
        }
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeDepartement = (name, value) => {
    this.setState({ [name]: value, commune_siege_id: "" });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      nom: this.state.nom,
      siret: this.state.siret,
      rc_numero: this.state.rc_numero,
      gerant: this.state.gerant,
      tel: this.state.tel,
      email: this.state.email,
      zone_activite: this.state.zone_activite,
      infos: this.state.infos,
      secteur_activite: this.state.secteur_activite,
      chiffre_affaire: this.state.chiffre_affaire,
      commune_siege_id: this.state.commune_siege_id,
      section: this.props.section,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { errors } = this.state;

    return (
      <GridContainer>
        {errors && (
          <GridItem xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </GridItem>
        )}
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <BusinessIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Informations Générales
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    value={this.state.nom}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nom)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Siret"
                    name="siret"
                    value={this.state.siret}
                    onChange={this.handleChange}
                    error={Boolean(errors?.siret)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="RC numéro"
                    name="rc_numero"
                    value={this.state.rc_numero}
                    onChange={this.handleChange}
                    error={Boolean(errors?.rc_numero)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <ContactPhoneIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Contact Principal
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Nom du gérant"
                    name="gerant"
                    value={this.state.gerant}
                    onChange={this.handleChange}
                    error={Boolean(errors?.gerant)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <PhoneInput
                    label="Téléphone"
                    name="tel"
                    value={this.state.tel}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.tel)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    error={Boolean(errors?.email)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <LocationOnIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Localisation
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    name="dep_code"
                    label="Département du siège social"
                    apiUrl="/departements"
                    onChange={this.handleChangeDepartement}
                    value={this.state.dep_code}
                    buildOptionValue={(data) => data.code}
                    buildOptionLabel={(data) => data.code + " " + data.nom}
                    shrink
                    error={Boolean(errors?.commune_siege_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    name="commune_siege_id"
                    label={
                      "Commune du siège social" +
                      (!this.state.dep_code
                        ? " - Choisissez un département..."
                        : "")
                    }
                    loadOnMount={Boolean(this.state.dep_code)}
                    apiUrl="/villes"
                    filterKey="departement"
                    filter={this.state.dep_code}
                    onChange={this.handleChangeByName}
                    value={this.state.commune_siege_id}
                    buildOptionLabel={(data) => data.cp + " " + data.ville}
                    shrink
                    error={Boolean(errors?.commune_siege_id)}
                    disabled={!this.state.dep_code}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <InfoIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Informations Supplémentaires
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <TextInput
                    label="Informations complémentaires"
                    name="infos"
                    multiline
                    maxRows={8}
                    onChange={this.handleChange}
                    value={this.state.infos}
                    error={Boolean(errors?.infos)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <WorkIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Activité
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Zone d'activité"
                    name="zone_activite"
                    value={this.state.zone_activite}
                    onChange={this.handleChange}
                    error={Boolean(errors?.zone_activite)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    label="Secteur d'activité"
                    name="secteur_activite"
                    value={this.state.secteur_activite}
                    onChange={this.handleChange}
                    error={Boolean(errors?.secteur_activite)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <CurrencyInput
                    label="Chiffre d'affaire"
                    name="chiffre_affaire"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.chiffre_affaire}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.chiffre_affaire)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

EntreprisesForm.propTypes = {
  section: PropTypes.string,
  urlPrefix: PropTypes.string,
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  uiCustomization: PropTypes.object,
};

export default withUiCustomizationContext(EntreprisesForm);
