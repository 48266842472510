import React from "react";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";

// @mui/material components
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

// @mui/icons-material
import Feed from "@mui/icons-material/Feed";

// core components
import Button from "components/Button/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FileUtils from "Utils/FileUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import SelectInput from "components/CustomSelect/SelectInput";

class ExportGLI extends React.Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.YEARS = [
      d.getFullYear(),
      d.getFullYear() - 1,
      d.getFullYear() - 2,
      d.getFullYear() - 3,
    ];
    this.TRIMESTRES = ["T1", "T2", "T3", "T4"];

    this.state = {
      // FORM
      year: this.YEARS[0],
      trimestre: this.TRIMESTRES[0],
      // END FORM
      loading: false,
      errorMessage: null,
      open: false,
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
      errorMessage: null,
    });
    const { year, trimestre } = this.state;

    axiosApiBackendNoJson
      .get(`/immobilier/documents/extract-gli/${year}/${trimestre}`)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.toggle();
        FileUtils.downLoadFile(res.data, res.headers, "export-gli.csv");
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorMessage:
            error?.response?.data?.error?.message ?? "Une erreur est survenue",
        });
      });
  };

  render() {
    const { errorMessage, loading, open, year, trimestre } = this.state;

    return (
      <>
        <Button
          fullWidth
          color="primary"
          size="small"
          startIcon={<Feed />}
          onClick={this.toggle}
        >
          Export GLI trimestrielle
        </Button>
        <AlertDialog
          open={open}
          fullWidth
          outsideClickCancel
          title="Export GLI trimestrielle - Choisir une période"
          onConfirm={this.handleSubmit}
          confirmLabel="Exporter"
          confirmColor="primary"
          onCancel={this.toggle}
          cancelLabel="Annuler"
          cancelColor="reversed"
          loading={loading}
        >
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          <GridContainer>
            <GridItem xs={12} lg={6}>
              <SelectInput
                label="Année"
                name="year"
                value={year}
                onChange={this.handleChange}
              >
                {this.YEARS.map((value, key) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectInput>
            </GridItem>
            <GridItem xs={12} lg={6}>
              <SelectInput
                label="Trimestre"
                name="trimestre"
                value={trimestre}
                onChange={this.handleChange}
              >
                {this.TRIMESTRES.map((value, key) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectInput>
            </GridItem>
          </GridContainer>
        </AlertDialog>
      </>
    );
  }
}

export default ExportGLI;
