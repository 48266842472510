import React, { Component } from "react";

// @mui/material components
import Grid from "@mui/material/Grid";
import MuiButton from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

// @mui/icons-material
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Assignment from "@mui/icons-material/Assignment";

// core components
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import AlertDialog from "components/AlertDialog/AlertDialog";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import TextInput from "components/CustomInput/TextInput";

class ConfigLogementFinanceur extends Component {
  constructor(props) {
    super(props);
    this.LABEL = "logement(s)";

    this.state = {
      // NESTED DATA
      conventions: this.getConventionDefault(),
      // DATA FORM
      id: "",
      value: "",
      // END DATA FORM
      errors: null,
      alert: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getConventionDefault = () => {
    return [{ value: "", financements_count: 0 }];
  };

  handleAddConvention = () => {
    let newData = [...this.state.conventions, ...this.getConventionDefault()];

    this.setState({
      conventions: newData,
    });
  };

  handleChangeConvention = (key, event) => {
    let newData = this.state.conventions;

    newData[key][event.target.name] = event.target.value;
    this.setState({ conventions: newData });
  };

  handleDeleteConvention = (key) => {
    this.clearAlert();
    let newData = this.state.conventions;

    newData.splice(key, 1);
    if (newData.length === 0) {
      newData = this.getConventionDefault();
    }
    this.setState({
      conventions: [...newData],
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  onClickDeleteButton = (event, key, convention) => {
    this.setState({
      alert: (
        <AlertDialog
          title={
            "Voulez-vous supprimer la convention " + convention.value + " ?"
          }
          onConfirm={() => this.handleDeleteConvention(key)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="primary"
        />
      ),
    });
  };

  render() {
    const { errors, conventions } = this.state;

    return (
      <>
        <TablePopUpContainer
          forceLoadAsyncRender
          icon={<Assignment />}
          title="Financeur"
          backendUrl="/config-logement-financeurs"
          autoSize={false}
          colDef={[
            { field: "id", editable: false, hide: true },
            {
              headerName: "Libellé",
              field: "value",
            },
            {
              headerName: "Conventions",
              field: "conventions",
              valueGetter: (params) => {
                return params.data.conventions
                  .map((conv) => conv.value)
                  .join(", ");
              },
            },
          ]}
          formInitData={() => {
            this.setState({
              errors: null,
              id: "",
              value: "",
              conventions: this.getConventionDefault(),
            });
          }}
          formSetData={(data) => {
            this.setState({
              errors: null,
              id: data.id ?? "",
              value: data.value ?? "",
              conventions: [...data.conventions] || this.getConventionDefault(),
            });
          }}
          formGetData={() => ({
            id: this.state.id,
            value: this.state.value,
            conventions: conventions,
          })}
          formSetErrors={(errors, status) => {
            if (status == 428) {
              let convs = errors || [];
              let conventions = Object.values(convs).map(
                (conv) =>
                  `${conv.value} est associé à ${conv.financements_count} ${this.LABEL}`,
              );
              this.setState({
                alert: (
                  <AlertDialog
                    title="Vous ne pouvez pas supprimer ces conventions :"
                    content={
                      <ul>
                        {conventions.map((conv, key) => (
                          <li key={key}>{conv}</li>
                        ))}
                      </ul>
                    }
                    onCancel={this.clearAlert}
                    cancelLabel="Ok"
                    cancelColor="info"
                  />
                ),
              });
            }

            this.setState({
              errors: errors,
            });
          }}
          getDeleteContent={(data) =>
            data.value +
            (data.financements_count > 0
              ? ` : Lié à ${data.financements_count} ${this.LABEL}`
              : "")
          }
          getDeleteError={(data, error) => {
            if (error.errors) {
              return (
                (data.value ?? "") +
                " est associé à " +
                (error.errors.financements || error.errors.conventions || "") +
                ` ${this.LABEL}.`
              );
            } else {
              return "Impossible de supprimer " + (data.value ?? "");
            }
          }}
          formTitle={
            this.state.id ? "Modifier le financeur" : "Ajouter un financeur"
          }
          formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
          formContent={
            <Grid container>
              <Grid item xs={12}>
                <TextInput
                  label="Libellé"
                  name="value"
                  value={this.state.value}
                  onChange={this.handleChange}
                  error={Boolean(errors?.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <HeaderTitle label="Conventions" />
              </Grid>
              {conventions.map((convention, key) => (
                <Grid item xs={12} key={key}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs>
                      <TextInput
                        label="Convention"
                        name="value"
                        multiline
                        value={convention.value}
                        onChange={(event) =>
                          this.handleChangeConvention(key, event)
                        }
                        error={
                          errors &&
                          Boolean(errors["conventions." + key + ".value"])
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {convention.financements_count > 0 ? (
                        <>
                          Lié à {convention.financements_count} Contrats
                          Locataires
                        </>
                      ) : (
                        <Tooltip
                          title="Supprimer cette convention"
                          placement="left"
                        >
                          <MuiButton
                            color="error"
                            onClick={(event) =>
                              this.onClickDeleteButton(event, key, convention)
                            }
                          >
                            <Delete />
                          </MuiButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Tooltip title="Ajouter une convention" placement="left">
                  <MuiButton color="primary" onClick={this.handleAddConvention}>
                    <Add />
                  </MuiButton>
                </Tooltip>
              </Grid>
            </Grid>
          }
        />
        {this.state.alert}
      </>
    );
  }
}

export default ConfigLogementFinanceur;
