import React from "react";
import PropTypes from "prop-types";

// @mui/icons-material
import ChatIcon from "@mui/icons-material/Chat";
import ListIcon from "@mui/icons-material/List";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import EvenementForm from "./EvenementForm";
import MenuBottom from "components/MenuBottom/MenuBottom";
import Typography from "@mui/material/Typography";

class EvenementUpdatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evenementId: props.match.params.evenementId,
      modelType: props.match.params.modelType,
      data: [],
      errors: null,
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  reloadEvenementList = () => {
    this.props.history.push(
      "/evenements/" +
        this.state.modelType +
        "/detail/" +
        this.state.evenementId,
    );
  };

  render() {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <ChatIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h5" component="h4">
                  Modifier l&apos;évènement
                </Typography>
              }
            />
            <CardContent>
              <GridContainer alignItems="center">
                <GridItem xs={12} style={{ marginBottom: "50px" }}>
                  <EvenementForm
                    id={this.state.evenementId}
                    modelType={this.state.modelType}
                    onSuccess={this.reloadEvenementList}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>

        <MenuBottom>
          <Button size="small" square round onClick={this.goBack}>
            <ListIcon />
          </Button>
        </MenuBottom>
      </GridContainer>
    );
  }
}

EvenementUpdatePage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
};

export default EvenementUpdatePage;
