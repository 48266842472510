import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import TrendingUp from "@mui/icons-material/TrendingUp";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import SelectInput from "components/CustomSelect/SelectInput";

class IRLsForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/irls";
    this.FRONT_URL = "/tiers/irls";

    this.state = {
      // DATA FORM
      annee: "",
      trimestre: "",
      valeur: "",
      publication: "",
      // END DATA FORM
      loading: false,
      id: props.match.params.id,
      errors: null,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeNumber = (event) => {
    let val = event.target.value;
    val = val.replace(/,/g, ".");
    this.setState({ [event.target.name]: val });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      annee: this.state.annee,
      trimestre: this.state.trimestre,
      valeur: this.state.valeur,
      publication: this.state.publication,
    };
    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { errors } = this.state;

    return (
      <GridContainer>
        {errors && (
          <GridItem xs={12}>
            <Typography color="error">
              <div>
                {Object.entries(errors).length === 0 ? (
                  <h4>Une erreur est survenue.</h4>
                ) : (
                  <h4>Merci de corriger les champs en rouge du formulaire.</h4>
                )}
              </div>
            </Typography>
          </GridItem>
        )}
        <GridItem xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <TrendingUp />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier l&apos;IRL ( {this.state.id} )</>
                  ) : (
                    <>Ajouter un IRL</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Année"
                    name="annee"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    type="text"
                    inputProps={{
                      maxLength: 4,
                      minLength: 4,
                    }}
                    onChange={this.handleChange}
                    value={this.state.annee}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.annee)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <SelectInput
                    label="Trimestre"
                    name="trimestre"
                    value={this.state.trimestre}
                    onChange={this.handleChange}
                    error={Boolean(errors?.trimestre)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={1}>T1</MenuItem>
                    <MenuItem value={2}>T2</MenuItem>
                    <MenuItem value={3}>T3</MenuItem>
                    <MenuItem value={4}>T4</MenuItem>
                  </SelectInput>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <TextField
                    label="Valeur"
                    name="valeur"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChangeNumber}
                    value={this.state.valeur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.valeur)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <TextField
                    label="Date de publication"
                    name="publication"
                    type="date"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.publication}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.publication)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

IRLsForm.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
};

export default IRLsForm;
