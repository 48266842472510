import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Person from "@mui/icons-material/Person";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import PhoneInput from "components/PhoneInput/PhoneInput";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import SelectSearch from "components/CustomSelect/SelectSearch";

class PersonnesForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/personnes";
    this.FRONT_URL = "/tiers/locataires";

    this.state = {
      // DATA FORM
      nom: "",
      prenom: "",
      date_naissance: "",
      civilite_id: "",
      telephone: "",
      email: "",
      nir: "",
      ville_naissance: "",
      pays_naissance: "",
      nationalite: "",
      type_papier: "",
      // END DATA FORM
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      nom: this.state.nom,
      prenom: this.state.prenom,
      date_naissance: this.state.date_naissance,
      civilite_id: this.state.civilite_id,
      telephone: this.state.telephone,
      email: this.state.email,
      nir: this.state.nir,
      ville_naissance: this.state.ville_naissance,
      pays_naissance: this.state.pays_naissance,
      nationalite: this.state.nationalite,
      type_papier: this.state.type_papier,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { errors } = this.state;

    return (
      <GridContainer>
        {errors && (
          <GridItem xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </GridItem>
        )}
        <GridItem xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier le locataire ( {this.state.id} )</>
                  ) : (
                    <>Ajouter un locataire</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <GridContainer>
                <GridItem xs={12} lg={6}>
                  <SelectSearch
                    label="Civité"
                    name="civilite_id"
                    apiUrl="/config-user-civilite"
                    onChange={this.handleChangeByName}
                    value={this.state.civilite_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.civilite_id)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Prénom"
                    name="prenom"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.prenom}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.prenom)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Nom"
                    name="nom"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.nom}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.nom)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Date de naissance"
                    name="date_naissance"
                    type="date"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.date_naissance}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.date_naissance)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Ville de naissance"
                    name="ville_naissance"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.ville_naissance}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.ville_naissance)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Pays de naissance"
                    name="pays_naissance"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.pays_naissance}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.pays_naissance)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Nationalité"
                    name="nationalite"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.nationalite}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.nationalite)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Type de papier"
                    name="type_papier"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.type_papier}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.type_papier)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <PhoneInput
                    label="Téléphone"
                    name="telephone"
                    value={this.state.telephone}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.telephone)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="Adresse email"
                    name="email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.email}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.email)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextField
                    label="NIR"
                    name="nir"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.nir}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.nir)}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

PersonnesForm.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default PersonnesForm;
