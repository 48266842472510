import React from "react";
import PropTypes from "prop-types";

// @mui/material components;
import Typography from "@mui/material/Typography";
import CheckBoxShow from "components/CheckBoxShow/CheckBoxShow";
import SimpleTreeView from "components/Tree/SimpleTreeView";
import ListTitle from "components/ListTitle/ListTitle";
import Grid from "@mui/material/Grid";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import Render from "Utils/RenderUtils";

class AuditResultatView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      data,
      estimation,
      averageConso,
      averageCo2,
      isConsommationEnergetique,
    } = this.props;

    return (
      <Grid container sx={{ mb: 2 }}>
        <ListTitle>Estimation {estimation + 1}</ListTitle>
        <Grid item xs={12}>
          <Typography>
            <strong>Résultat : </strong>
            {data.resultat}
          </Typography>
          {isConsommationEnergetique && (
            <>
              <Typography>
                <strong>Consommation énergétique : </strong>
                {Render.ifNotEmpty(
                  data.consommation_energetique,
                  "",
                  " kWh/m²/an",
                )}
              </Typography>
              {averageConso && (
                <Typography>
                  <strong>Taux d&apos;amélioration : </strong>
                  {averageConso}
                </Typography>
              )}
            </>
          )}
          {isConsommationEnergetique && (
            <>
              <Typography>
                <strong>Étiquette GES : </strong>
                {data.etiquette_ges}
              </Typography>
              <Typography>
                <strong>CO2 : </strong>
                {Render.ifNotEmpty(data.co2, "", " CO2/m²/an")}
              </Typography>
              {averageCo2 && (
                <Typography>
                  <strong>Taux d&apos;amélioration CO2 : </strong>
                  {averageCo2}
                </Typography>
              )}
            </>
          )}
          {Boolean(data?.validated) && (
            <CheckBoxShow checked={data.validated} label="Validé" />
          )}
          <Typography>
            <strong>Description : </strong>
          </Typography>
          <MultilineTypography>{data.description}</MultilineTypography>
          {data.typetravaux?.tree?.length > 0 && (
            <SimpleTreeView
              tree={data.typetravaux.tree}
              defaultExpanded={data.typetravaux?.parent_ids ?? []}
              collapseAll
              label="Travaux"
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

AuditResultatView.propTypes = {
  estimation: PropTypes.any,
  data: PropTypes.any,
  averageConso: PropTypes.any,
  averageCo2: PropTypes.any,
  isConsommationEnergetique: PropTypes.bool,
};

export default AuditResultatView;
