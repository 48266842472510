import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import Description from "@mui/icons-material/Description";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import SettingsFormContainer from "components/SettingsFormContainer/SettingsFormContainer";
import PercentInput from "components/CustomInput/PercentInput";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import TextInput from "components/CustomInput/TextInput";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import SelectInput from "components/CustomSelect/SelectInput";
import { withUserContext } from "context/UserContext";

class ComptabiliteConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.CAN_CONFIG_COMPTA = this.USER.can("edit.configuration.comptabilite");
    this.CAN_CONFIG_OPERATIONS = this.USER.canOne([
      "config.operationlocataire",
      "config.operationproprietaire",
    ]);
    this.FRONT_URL = "/configuration/comptabilite";
    this.NO_YES = ["Non", "Oui"];

    this.state = {
      // SETTINGS FORM
      export_format: "",
      regul_journal: "",
      regul_id: "",
      regul_label: "",
      caf_id: "",
      caf_journal: "",
      quittancement: true,
      terme_echu_switch_day: "",
      gli_pourcent: "",
      gli_annuelle: "",
      adhesion_annuelle: "",
      adhesion_mensualite_max: "",
      // DATA FORM
      id: "",
      label: "",
      description: "",
      locataire_credit: false,
      locataire_debit: false,
      proprietaire_credit: false,
      proprietaire_debit: false,
      imputation_transitive: false,
      compta_mg_id: "",
      compta_lsl_id: "",
      paiement: false,
      export_comptable: false,
      compta_journal: "",
      editable: false,
      force_indicatif: false,
      // END DATA FORM
      setErrs: null,
      opErrs: null,
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeEvent = (event) => {
    this.handleChange(event.target.name, event.target.value);
  };

  handleChangeCheckbox = (event) => {
    this.handleChange(event.target.name, event.target.checked);
  };

  render() {
    const { opErrs, setErrs } = this.state;

    return (
      <GridContainer>
        {this.CAN_CONFIG_COMPTA && (
          <GridItem xs={12}>
            <SettingsFormContainer
              icon={<Assignment />}
              title="Configuration export comptable"
              backendUrl="/settings/comptabilite"
              formSetData={(data) => {
                this.setState({
                  setErrs: null,
                  export_format: data.export_format,
                  regul_journal: data.regul_journal,
                  regul_id: data.regul_id,
                  regul_label: data.regul_label,
                  caf_id: data.caf_id,
                  caf_journal: data.caf_journal,
                  quittancement: data.quittancement,
                  terme_echu_switch_day: data.terme_echu_switch_day,
                  gli_pourcent: data.gli_pourcent,
                  gli_annuelle: data.gli_annuelle,
                  adhesion_annuelle: data.adhesion_annuelle,
                  adhesion_mensualite_max: data.adhesion_mensualite_max,
                });
              }}
              formGetData={() => ({
                export_format: this.state.export_format,
                regul_journal: this.state.regul_journal,
                regul_id: this.state.regul_id,
                regul_label: this.state.regul_label,
                caf_id: this.state.caf_id,
                caf_journal: this.state.caf_journal,
                quittancement: this.state.quittancement,
                terme_echu_switch_day: this.state.terme_echu_switch_day,
                gli_pourcent: this.state.gli_pourcent,
                gli_annuelle: this.state.gli_annuelle,
                adhesion_annuelle: this.state.adhesion_annuelle,
                adhesion_mensualite_max: this.state.adhesion_mensualite_max,
              })}
              formSetErrors={(err) => {
                this.setState({
                  setErrs: err,
                });
              }}
              formContent={
                <GridContainer alignItems="center">
                  {setErrs && (
                    <GridItem xs={12}>
                      <Typography variant="h6" component="h4" color="error">
                        {Object.entries(setErrs).length === 0 ? (
                          <>Une erreur est survenue.</>
                        ) : (
                          <>
                            Merci de corriger les champs en rouge du formulaire.
                          </>
                        )}
                      </Typography>
                    </GridItem>
                  )}
                  <GridItem xs={12}>
                    <HeaderTitle label="Général" />
                  </GridItem>
                  <GridItem xs={12}>
                    <SelectInput
                      label="Format des exports comptables"
                      name="export_format"
                      onChange={this.handleChangeEvent}
                      value={this.state.export_format}
                      error={Boolean(setErrs?.export_format)}
                    >
                      <MenuItem value="">
                        <em>Non renseigné</em>
                      </MenuItem>
                      <MenuItem value="csv_ciel">Csv Ciel</MenuItem>
                      <MenuItem value="quadra_ascii">Quadra ASCII</MenuItem>
                    </SelectInput>
                  </GridItem>
                  <GridItem xs={12}>
                    <HeaderTitle label="Différence de loyers dans l'export" />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <TextInput
                      label="Journal"
                      name="regul_journal"
                      onChange={this.handleChangeEvent}
                      value={this.state.regul_journal}
                      error={Boolean(setErrs?.regul_journal)}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <TextInput
                      label="Identifiant comptable"
                      name="regul_id"
                      onChange={this.handleChangeEvent}
                      value={this.state.regul_id}
                      error={Boolean(setErrs?.regul_id)}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextInput
                      label="Label"
                      name="regul_label"
                      onChange={this.handleChangeEvent}
                      value={this.state.regul_label}
                      error={Boolean(setErrs?.regul_label)}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <HeaderTitle label="Export CAF" />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <TextInput
                      label="Identifiant comptable"
                      name="caf_id"
                      onChange={this.handleChangeEvent}
                      value={this.state.caf_id}
                      error={Boolean(setErrs?.caf_id)}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <TextInput
                      label="Journal"
                      name="caf_journal"
                      onChange={this.handleChangeEvent}
                      value={this.state.caf_journal}
                      error={Boolean(setErrs?.caf_journal)}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <HeaderTitle label="Échéanciers" />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CheckBoxInput
                      label="Activer le quittancement"
                      name="quittancement"
                      onChange={this.handleChange}
                      value={this.state.quittancement}
                      error={Boolean(setErrs?.quittancement)}
                    />
                  </GridItem>
                  {this.state.quittancement ? (
                    <>
                      <GridItem xs={12} md={6}>
                        <TextInput
                          label="Jour du changement des contrats à termes échus"
                          name="terme_echu_switch_day"
                          onChange={this.handleChangeEvent}
                          value={this.state.terme_echu_switch_day}
                          error={Boolean(setErrs?.terme_echu_switch_day)}
                        />
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <PercentInput
                          label="Pourcentage de la GLI mensuelle"
                          name="gli_pourcent"
                          onChange={this.handleChangeEvent}
                          value={this.state.gli_pourcent}
                          error={Boolean(setErrs?.gli_pourcent)}
                        />
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <CurrencyInput
                          label="Montant GLI annuelle"
                          name="gli_annuelle"
                          onChange={this.handleChangeEvent}
                          value={this.state.gli_annuelle}
                          error={Boolean(setErrs?.gli_annuelle)}
                        />
                      </GridItem>
                    </>
                  ) : (
                    <GridItem xs={12} md={6} />
                  )}
                  <GridItem xs={12}>
                    <HeaderTitle label="Valeurs par défaut de l'adhésion" />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <TextInput
                      label="Montant adhésion annuelle"
                      name="adhesion_annuelle"
                      onChange={this.handleChangeEvent}
                      value={this.state.adhesion_annuelle}
                      error={Boolean(setErrs?.adhesion_annuelle)}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <TextInput
                      label="Adhésion mensualités maximum"
                      name="adhesion_mensualite_max"
                      onChange={this.handleChangeEvent}
                      value={this.state.adhesion_mensualite_max}
                      error={Boolean(setErrs?.adhesion_mensualite_max)}
                    />
                  </GridItem>
                </GridContainer>
              }
            />
          </GridItem>
        )}
        {this.CAN_CONFIG_OPERATIONS && (
          <GridItem xs={12}>
            <TablePopUpContainer
              icon={<Assignment />}
              title="Configuration des échéanciers"
              backendUrl="/config-operations"
              colDef={[
                { field: "id", editable: false, hide: true },
                {
                  headerName: "Libellé",
                  field: "label",
                },
                {
                  headerName: "Description",
                  field: "description",
                },
                {
                  headerName: "Locataire",
                  field: "locataire",
                  valueGetter: (params) => {
                    let credit = params.data.locataire_credit ? "Crédit" : "";
                    let debit = params.data.locataire_debit ? "Débit" : "";
                    return [credit, debit].filter((a) => a).join(", ");
                  },
                },
                {
                  headerName: "Propriétaire",
                  field: "proprietaire",
                  valueGetter: (params) => {
                    let credit = params.data.proprietaire_credit
                      ? "Crédit"
                      : "";
                    let debit = params.data.proprietaire_debit ? "Débit" : "";
                    return [credit, debit].filter((a) => a).join(", ");
                  },
                },
                {
                  headerName: "Identifiant Comptable LSL",
                  field: "compta_lsl_id",
                  valueGetter: (params) =>
                    params.data.imputation_transitive
                      ? "Transitive"
                      : params.data.compta_lsl_id,
                },
                {
                  headerName: "Identifiant Comptable MG",
                  field: "compta_mg_id",
                  valueGetter: (params) =>
                    params.data.imputation_transitive
                      ? "Transitive"
                      : params.data.compta_mg_id,
                },
                {
                  headerName: "Paiement",
                  field: "paiement",
                  valueFormatter: (params) => this.NO_YES[params.value] ?? "",
                },
                {
                  headerName: "Export Comptable",
                  field: "export_comptable",
                  valueFormatter: (params) => this.NO_YES[params.value] ?? "",
                },
                {
                  headerName: "Journal Comptable",
                  field: "compta_journal",
                },
                {
                  headerName: "Éditable",
                  field: "editable",
                  valueFormatter: (params) => this.NO_YES[params.value] ?? "",
                },
                {
                  headerName: "Force Indicatif",
                  field: "force_indicatif",
                  valueFormatter: (params) => this.NO_YES[params.value] ?? "",
                },
              ]}
              formInitData={() => {
                this.setState({
                  opErrs: null,
                  id: "",
                  label: "",
                  description: "",
                  locataire_credit: false,
                  locataire_debit: false,
                  proprietaire_credit: false,
                  proprietaire_debit: false,
                  imputation_transitive: false,
                  compta_mg_id: "",
                  compta_lsl_id: "",
                  paiement: false,
                  export_comptable: false,
                  compta_journal: "",
                  editable: true,
                  force_indicatif: false,
                });
              }}
              formSetData={(data) => {
                this.setState({
                  opErrs: null,
                  id: data.id ?? "",
                  label: data.label ?? "",
                  description: data.description ?? "",
                  locataire_credit: data.locataire_credit ?? false,
                  locataire_debit: data.locataire_debit ?? false,
                  proprietaire_credit: data.proprietaire_credit ?? false,
                  proprietaire_debit: data.proprietaire_debit ?? false,
                  imputation_transitive: data.imputation_transitive ?? false,
                  compta_mg_id: data.compta_mg_id ?? "",
                  compta_lsl_id: data.compta_lsl_id ?? "",
                  paiement: data.paiement ?? false,
                  export_comptable: data.export_comptable ?? false,
                  compta_journal: data.compta_journal ?? "",
                  editable: data.editable ?? false,
                  force_indicatif: data.force_indicatif ?? false,
                });
              }}
              formGetData={() => ({
                id: this.state.id,
                label: this.state.label,
                description: this.state.description,
                locataire_credit: this.state.locataire_credit,
                locataire_debit: this.state.locataire_debit,
                proprietaire_credit: this.state.proprietaire_credit,
                proprietaire_debit: this.state.proprietaire_debit,
                imputation_transitive: this.state.imputation_transitive,
                compta_mg_id: this.state.imputation_transitive
                  ? ""
                  : this.state.compta_mg_id,
                compta_lsl_id: this.state.imputation_transitive
                  ? ""
                  : this.state.compta_lsl_id,
                paiement: this.state.paiement,
                export_comptable: this.state.export_comptable,
                compta_journal: this.state.compta_journal,
                force_indicatif: this.state.force_indicatif,
              })}
              formSetErrors={(err) => {
                this.setState({
                  opErrs: err,
                });
              }}
              getDeleteContent={(data) => data.label}
              getDeleteError={(data, error) =>
                (data.label ?? "") +
                " est associé à " +
                (error ?? "") +
                " operations."
              }
              formTitle={
                this.state.id ? "Modifier l'opération" : "Ajouter une opération"
              }
              formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
              formContent={
                <GridContainer alignItems="center">
                  <GridItem xs={12} lg={6}>
                    <TextInput
                      label="Libellé"
                      name="label"
                      value={this.state.label}
                      onChange={this.handleChangeEvent}
                      error={Boolean(opErrs?.label)}
                      disabled={!this.state.editable}
                    />
                  </GridItem>
                  <GridItem xs={12} lg={6}>
                    <TextInput
                      label="Description"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleChangeEvent}
                      error={Boolean(opErrs?.description)}
                    />
                  </GridItem>
                  <GridItem container alignItems="center" xs={12}>
                    <Typography sx={{ mr: 2 }}>Locataire :</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(this.state.locataire_credit)}
                          onChange={this.handleChangeCheckbox}
                          name="locataire_credit"
                        />
                      }
                      label="Crédit"
                      disabled={!this.state.editable}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(this.state.locataire_debit)}
                          onChange={this.handleChangeCheckbox}
                          name="locataire_debit"
                        />
                      }
                      label="Débit"
                      disabled={!this.state.editable}
                    />
                  </GridItem>
                  <GridItem container alignItems="center" xs={12}>
                    <Typography sx={{ mr: 2 }}>Propriétaire :</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(this.state.proprietaire_credit)}
                          onChange={this.handleChangeCheckbox}
                          name="proprietaire_credit"
                        />
                      }
                      label="Crédit"
                      disabled={!this.state.editable}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(this.state.proprietaire_debit)}
                          onChange={this.handleChangeCheckbox}
                          name="proprietaire_debit"
                        />
                      }
                      label="Débit"
                      disabled={!this.state.editable}
                    />
                  </GridItem>
                  <GridItem xs={12} lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(this.state.imputation_transitive)}
                          onChange={this.handleChangeCheckbox}
                          name="imputation_transitive"
                        />
                      }
                      label="Imputation Transitive"
                      disabled={!this.state.editable}
                    />
                  </GridItem>
                  <GridItem xs={12} lg={6}>
                    {!this.state.imputation_transitive ? (
                      <TextInput
                        label="Identifiant Comptable LSL"
                        name="compta_lsl_id"
                        value={this.state.compta_lsl_id}
                        onChange={this.handleChangeEvent}
                        error={Boolean(opErrs?.compta_lsl_id)}
                      />
                    ) : null}
                  </GridItem>
                  <GridItem xs={12} lg={6}>
                    {!this.state.imputation_transitive ? (
                      <TextInput
                        label="Identifiant Comptable MG"
                        name="compta_mg_id"
                        value={this.state.compta_mg_id}
                        onChange={this.handleChangeEvent}
                        error={Boolean(opErrs?.compta_mg_id)}
                      />
                    ) : null}
                  </GridItem>
                  <GridItem xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(this.state.paiement)}
                          onChange={this.handleChangeCheckbox}
                          name="paiement"
                        />
                      }
                      label="Paiement"
                      disabled={!this.state.editable}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(this.state.export_comptable)}
                          onChange={this.handleChangeCheckbox}
                          name="export_comptable"
                        />
                      }
                      label="Export Comptable"
                      disabled={!this.state.editable}
                    />
                    <TextInput
                      label="Journal Comptable"
                      name="compta_journal"
                      value={this.state.compta_journal}
                      onChange={this.handleChangeEvent}
                      error={Boolean(opErrs?.compta_journal)}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(this.state.force_indicatif)}
                          onChange={this.handleChangeCheckbox}
                          name="force_indicatif"
                        />
                      }
                      label="Force Indicatif"
                      disabled={!this.state.editable}
                    />
                  </GridItem>
                </GridContainer>
              }
            />
          </GridItem>
        )}
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigOperation"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </GridContainer>
    );
  }
}

ComptabiliteConfig.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(ComptabiliteConfig);
