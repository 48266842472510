import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import MuiButton from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

// @mui/icons-material
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import AlertDialog from "components/AlertDialog/AlertDialog";
import ListTitle from "components/ListTitle/ListTitle";
import GridContainer from "components/Grid/GridContainer";

class FormCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
    };
  }

  componentDidMount() {}

  addRow = () => {
    this.props.addRow();
  };

  removeRow = (key) => {
    this.props.removeRow(key);
    this.clearAlert();
  };

  onClickDelete = (key) => {
    this.setState({
      alert: (
        <AlertDialog
          title={this.props.dialogDeleteTitle(key + 1)}
          onConfirm={() => this.removeRow(key)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="primary"
        />
      ),
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const {
      items,
      formInputs,
      rowTitle,
      tooltipAdd,
      tooltipDelete,
      linkedRelationsCountKey,
      linkedRelationName,
      hideButtons,
    } = this.props;

    return (
      <GridContainer alignItems="center">
        {items.map((item, key) => (
          <React.Fragment key={key}>
            <GridItem
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              key={key}
            >
              <GridItem>
                <ListTitle>
                  {rowTitle} n°{key + 1} :
                </ListTitle>
              </GridItem>
              {!item.read_only &&
                (item[linkedRelationsCountKey] > 0 ? (
                  <>
                    Lié à {item[linkedRelationsCountKey]} {linkedRelationName}
                  </>
                ) : (
                  !hideButtons && (
                    <GridItem>
                      <Tooltip title={tooltipDelete} placement="left">
                        <MuiButton
                          color="error"
                          onClick={() => this.onClickDelete(key)}
                        >
                          <Delete />
                        </MuiButton>
                      </Tooltip>
                    </GridItem>
                  )
                ))}
            </GridItem>
            {formInputs(item, key)}
          </React.Fragment>
        ))}
        {!hideButtons && (
          <GridItem xs={12} sx={{ mb: 1 }} container justifyContent="right">
            <GridItem>
              <Tooltip title={tooltipAdd} placement="left">
                <MuiButton color="primary" onClick={this.addRow}>
                  <Add />
                  {items.length === 0 && <>&nbsp;{tooltipAdd}</>}
                </MuiButton>
              </Tooltip>
            </GridItem>
          </GridItem>
        )}
        {this.state.alert}
      </GridContainer>
    );
  }
}

FormCollection.defaultProps = {
  tooltipAdd: "Ajouter une ligne",
  tooltipDelete: "Supprimer la ligne",
  hideButtons: false,
};

FormCollection.propTypes = {
  items: PropTypes.array.isRequired,
  formInputs: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  rowTitle: PropTypes.string.isRequired,
  dialogDeleteTitle: PropTypes.func.isRequired,
  tooltipAdd: PropTypes.string,
  tooltipDelete: PropTypes.string,
  linkedRelationsCountKey: PropTypes.string,
  linkedRelationName: PropTypes.string,
  hideButtons: PropTypes.bool,
};

export default FormCollection;
