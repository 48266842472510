import React from "react";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { VariableSizeList } from "react-window";

// @mui/lab components
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

// @mui/material components
import styled from "@mui/styles/styled";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";

// @mui/icons-material
import ChatIcon from "@mui/icons-material/Chat";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import Visibility from "@mui/icons-material/Visibility";
import Icon from "@mui/material/Icon";

// core components
import CircularLoading from "components/Loading/CircularLoading";
import ToggleChip from "components/CustomButtons/ToggleChip";
import Render from "Utils/RenderUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import SearchBar from "components/SearchBar/SearchBar";
import EvenementUtils from "Utils/EvenementUtils";
import EvenementReplies from "./EvenementReplies";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import StyledTimeline from "components/Evenements/StyledTimeline";
import { withUserContext } from "context/UserContext";
import Button from "components/Button/Button";

const StyledPaper = styled(Paper)(() => ({
  padding: "6px 16px",
}));

const StyledTimelineOppositeContent = styled(TimelineOppositeContent)(() => ({
  flex: "0",
  padding: "0",
}));

const SubjectTypography = styled(MultilineTypography)(() => ({
  whiteSpace: "inherit",
}));

class Evenements extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/evenements";
    this.FRONT_URL = "/evenements";
    this.CAN_EDIT_EVENTS = props.user.can("edit.evenement");
    this.CAN_DELETE = props.user.can("delete.evenement");

    this.listRef = React.createRef();
    this.evenementRepliesRef = React.createRef();

    this.state = {
      replies: [],
      types: [],
      loading: false,
      error: false,
      alert: null,
      quickFilterText: "",
      typeFilter: [],
      data: [],
      openReplyModal: false,
      sortByUpdatedDate:
        localStorage.getItem("events_sortByUpdatedDate") === "false"
          ? false
          : true,
    };
  }

  componentDidMount() {
    if (this.props.loadOnMount) {
      this.loadAsyncData();
    }
    this.displayableTypes();
  }

  loadAsyncData = () => {
    const { evenementId, getQueryFilters } = this.props;
    let queryFilters = getQueryFilters ? getQueryFilters() : {};
    this.setState({
      error: false,
      loading: true,
    });
    const url = evenementId ? "/" + evenementId : "";
    axiosApiBackend
      .get(this.BACKEND_URL + url, {
        params: {
          eventable_id: this.props.modelId ?? null,
          eventable_type_readable: this.props.modelType ?? null,
          section: this.props.section ?? null,
          limit: this.props.limit,
          with_count_replies: this.props.withCountReplies ?? null,
          ...queryFilters,
        },
      })
      .then((result) => {
        let resultData = Array.isArray(result.data)
          ? result.data
          : [result.data];

        resultData = resultData.map((item) => ({
          ...item,
          date_time_update:
            item.updated_at != item.created_at
              ? item.updated_at
              : item.date_time,
        }));

        this.resetItemSizeCache();
        this.setState({
          data: resultData,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          error: true,
          loading: false,
        });
      });
  };

  displayableTypes = () => {
    axiosApiBackend
      .get("/config-type-evenement", {
        params: {
          display_on: this.props.modelType ?? null,
          section: this.props.section ?? null,
        },
      })
      .then((result) => {
        this.setState({
          types: result.data,
        });
      });
  };

  handleSolve = (evenement) => {
    const dataIn = {
      eventable_id: evenement.eventable_id,
      eventable_type_readable: evenement.eventable_type_readable,
      type_evenement_id: evenement.type.id,
      origine_id: evenement.origine_id,
      subject: evenement.subject,
      content: evenement.content,
      resolu: evenement.resolu === 1 ? 0 : 1,
      date_time: evenement.date_time,
    };

    axiosApiBackend
      .put(this.BACKEND_URL + "/" + evenement.id, dataIn)
      .then(() => {
        this.loadAsyncData();
      })
      .catch(() => {
        this.setState({
          error: true,
        });
      });
  };

  handleDelete = (evenement) => {
    axiosApiBackend.delete(this.BACKEND_URL + "/" + evenement.id).then(() => {
      this.hideAlert();
      if (this.props.evenementId) {
        this.props.onDelete(evenement);
      } else {
        this.loadAsyncData();
      }
    });
  };

  deleteAlert = (evenement) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Êtes vous sûr de vouloir supprimer cet évènement ?"
          onConfirm={() => this.handleDelete(evenement)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={() => this.hideAlert()}
          cancelLabel="Annuler"
          cancelColor="primary"
        />
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  evenementDot = (typeName) => {
    let type = this.state.types.find((t) => t.value === typeName);
    return (
      <TimelineDot sx={{ bgcolor: type.color }}>
        <Icon>{type.icon}</Icon>
      </TimelineDot>
    );
  };

  timelineDenseContent = (evenement) => {
    const userName = Render.shortName(evenement.user);

    return (
      <>
        {this.CAN_EDIT_EVENTS && (
          <Typography variant="body2" component="span" color="textSecondary">
            {Render.date(evenement.date_time) + " - " + userName + " "}
          </Typography>
        )}
        <SubjectTypography variant="body1" component="span">
          {evenement.subject}
        </SubjectTypography>
      </>
    );
  };

  timelineContent = (evenement) => {
    const { displayEntityBtn, lineClamp } = this.props;
    const userContext = this.props.user;
    const user = evenement.user;
    const userName = Render.fullName(user);
    const createdDate = evenement.created_at;
    const updatedDate = evenement.updated_at;
    const updatedDateText =
      updatedDate != createdDate
        ? " - Modifié le " + Render.dateTime(updatedDate)
        : "";

    const displayEditBtn =
      userContext.isChef() ||
      (user && userContext.id && user.id == userContext.id); // we want type conversion

    return (
      <>
        <Box style={{ float: "right" }}>
          {this.CAN_DELETE && (
            <Button
              sx={{ mx: "1px" }}
              size="small"
              square
              round
              color="error"
              onClick={() => this.deleteAlert(evenement)}
            >
              <DeleteForeverIcon />
            </Button>
          )}
          {displayEditBtn && (
            <Link
              to={`${this.FRONT_URL}/${evenement.eventable_type_readable}/modifier/${evenement.id}`}
            >
              <Button sx={{ mx: "1px" }} size="small" square round>
                <EditIcon />
              </Button>
            </Link>
          )}
          {displayEntityBtn && (
            <Link to={EvenementUtils.evenementEntityPath(evenement)}>
              <Button sx={{ mx: "1px" }} size="small" round>
                <Visibility /> {EvenementUtils.evenementEntityName(evenement)}
              </Button>
            </Link>
          )}
          {(evenement.resolu === 0 || evenement.resolu === 1) &&
            this.CAN_EDIT_EVENTS && (
              <ToggleChip
                sx={{ mx: "1px" }}
                color="primary"
                icon={
                  evenement.resolu === 1 ? (
                    <PlaylistAddCheckIcon />
                  ) : (
                    <NotificationImportantIcon />
                  )
                }
                label={evenement.resolu === 1 ? "Résolu" : "En cours"}
                on={Boolean(evenement.resolu)}
                onClick={
                  displayEditBtn ? () => this.handleSolve(evenement) : null
                }
              />
            )}
        </Box>
        {this.CAN_EDIT_EVENTS && (
          <Typography variant="body2" color="textSecondary">
            {userName} {Render.dateTime(evenement.date_time)} {updatedDateText}
          </Typography>
        )}
        <SubjectTypography variant="h5" lineClamp={lineClamp ? "1" : null}>
          {evenement.subject}
        </SubjectTypography>
        {this.CAN_EDIT_EVENTS && (
          <MultilineTypography
            variant="body1"
            lineClamp={lineClamp ? "3" : null}
          >
            {evenement.content}
          </MultilineTypography>
        )}
        {lineClamp && (
          <Link
            to={`${this.FRONT_URL}/${evenement.eventable_type_readable}/detail/${evenement.id}`}
          >
            <Button size="small">Voir plus</Button>
          </Link>
        )}
        {this.props.displayReplyButton && this.CAN_EDIT_EVENTS && (
          <Button
            size="small"
            onClick={() => this.evenementRepliesRef.current.openFormDialog()}
          >
            Répondre
          </Button>
        )}
        {this.props.withCountReplies && evenement.replies_count > 0 && (
          <Typography sx={{ ml: 1 }} variant="caption">
            Réponses ({evenement.replies_count})
          </Typography>
        )}
      </>
    );
  };

  toggleFilter = (type) => {
    let types = this.state.typeFilter;
    let index = types.indexOf(type);
    if (index !== -1) {
      types.splice(index, 1);
    } else {
      types.push(type);
    }
    this.resetItemSizeCache();
    this.setState({
      typeFilter: types,
    });
  };

  hasType = (type) => {
    return this.state.typeFilter.includes(type);
  };

  withType = (evenement) => {
    if (this.state.typeFilter.length === 0) {
      return this.state.types.some((t) => t.id === evenement.type.id);
    }
    return this.hasType(evenement.type.id);
  };

  withQuickFilterText = (evenement) => {
    let searchText = this.state.quickFilterText.toLowerCase();

    if (searchText.length <= 1) {
      return true;
    }

    let fields = [
      "subject",
      "content",
      "date_time",
      "origine.value",
      "type.value",
      "user.first_name",
      "user.last_name",
    ];

    for (const fieldPath of fields) {
      let value = evenement;
      for (const field of fieldPath.split(".")) {
        value = value[field] ?? "";
      }
      if (
        typeof value === "string" &&
        value.toLowerCase().includes(searchText)
      ) {
        return true;
      }
    }
  };

  toggleSort = () => {
    const { sortByUpdatedDate } = this.state;
    this.setState({ sortByUpdatedDate: !sortByUpdatedDate });
    this.resetItemSizeCache();
    localStorage.setItem("events_sortByUpdatedDate", !sortByUpdatedDate ?? "");
  };

  onDate = (fieldToCompare) => (valA, valB) => {
    if (valA[fieldToCompare] > valB[fieldToCompare]) return -1;
    if (valA[fieldToCompare] < valB[fieldToCompare]) return 1;

    return 0;
  };

  renderEventItemVirt = (props, events = []) => {
    const { index, style } = props;
    let evenement = events[index] ?? null;
    if (evenement == null) {
      return null;
    }
    return this.renderEventItem(evenement, index, null, style);
  };

  getItemSizeVirt = (index, events) => {
    let event = events[index] ?? null;
    if (event == null) {
      return 0;
    }
    return event.content ? 185 : 135;
  };
  resetItemSizeCache = () => {
    if (this.listRef.current) {
      this.listRef.current.resetAfterIndex(0, false);
    }
  };

  renderEventItem = (evenement, index, _, style = null) => {
    const { dense } = this.props;

    return (
      <TimelineItem key={index} style={style}>
        <StyledTimelineOppositeContent />
        <TimelineSeparator>
          {this.evenementDot(evenement.type.value)}
          <Typography variant="body2" color="textPrimary">
            {evenement.origine ? evenement.origine.value : ""}
          </Typography>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <StyledPaper elevation={3}>
            {dense
              ? this.timelineDenseContent(evenement)
              : this.timelineContent(evenement)}
          </StyledPaper>
          <EvenementReplies
            ref={this.evenementRepliesRef}
            evenement={evenement}
            refreshData={this.loadAsyncData}
          />
        </TimelineContent>
      </TimelineItem>
    );
  };

  render() {
    const { dense, evenementId, displaySearch, displayTotal } = this.props;
    const { data, alert } = this.state;
    const displayFilters = !dense && !evenementId;
    const fieldToCompare = this.state.sortByUpdatedDate
      ? "date_time_update"
      : "date_time";

    const eventsToDisplay = data
      .filter(this.withType)
      .filter(this.withQuickFilterText)
      .sort(this.onDate(fieldToCompare));

    return (
      <Box>
        {displayFilters && (
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <ToggleChip
                color="primary"
                icon={<ChatIcon />}
                label="Tous"
                on={this.state.typeFilter.length === 0}
                onClick={() => {
                  this.resetItemSizeCache();
                  this.setState({ typeFilter: [] });
                }}
              />
            </Grid>
            {this.state.types.map((type) => {
              return (
                <Grid item key={type.id}>
                  <ToggleChip
                    color="primary"
                    icon={<Icon>{type.icon}</Icon>}
                    label={type.value}
                    on={this.hasType(type.id)}
                    onClick={() => this.toggleFilter(type.id)}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Stack direction="row" spacing={1} alignItems="center">
              {displaySearch && (
                <SearchBar
                  fullWidth={false}
                  label="Rechercher"
                  name="quickFilterText"
                  variant="outlined"
                  margin="none"
                  value={this.state.quickFilterText}
                  onChange={(event) => {
                    this.resetItemSizeCache();
                    this.setState({ quickFilterText: event.target.value });
                  }}
                  resetSearchValue={() => {
                    this.resetItemSizeCache();
                    this.setState({ quickFilterText: "" });
                  }}
                />
              )}
              {displayTotal && (
                <Typography gutterBottom>
                  {Render.amount(
                    eventsToDisplay.length,
                    "évènement",
                    "évènements",
                  )}
                </Typography>
              )}
            </Stack>
          </Grid>
          {displayFilters && (
            <Grid item>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Tri par date de création</Typography>
                <Switch
                  checked={this.state.sortByUpdatedDate}
                  onChange={this.toggleSort}
                />
                <Typography>Tri par date de mise à jour</Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
        <CircularLoading display={this.state.loading} />
        {this.state.error && <span style={{ color: "red" }}>Erreur</span>}
        <StyledTimeline align="left" dense={dense}>
          {!displayFilters ? (
            eventsToDisplay.map(this.renderEventItem)
          ) : (
            // virtualized list
            <VariableSizeList
              ref={this.listRef}
              height={700}
              width="100%"
              itemSize={(index) => this.getItemSizeVirt(index, eventsToDisplay)}
              itemCount={eventsToDisplay.length}
              overscanCount={5}
            >
              {(props) => this.renderEventItemVirt(props, eventsToDisplay)}
            </VariableSizeList>
          )}
        </StyledTimeline>
        {alert}
      </Box>
    );
  }
}

Evenements.defaultProps = {
  loadOnMount: true,
  displaySearch: false,
  displayTotal: false,
  displayEntityBtn: false,
};

Evenements.propTypes = {
  loadOnMount: PropTypes.bool,
  getQueryFilters: PropTypes.func,
  modelId: PropTypes.number,
  modelType: PropTypes.string,
  section: PropTypes.string,
  evenementId: PropTypes.any,
  onDelete: PropTypes.func,
  lineClamp: PropTypes.bool,
  displaySearch: PropTypes.bool,
  displayTotal: PropTypes.bool,
  displayEntityBtn: PropTypes.bool,
  dense: PropTypes.bool,
  limit: PropTypes.any,
  classes: PropTypes.any,
  displayReplyButton: PropTypes.bool,
  withCountReplies: PropTypes.bool,
  user: PropTypes.object,
};

export default withUserContext(Evenements);
