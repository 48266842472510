import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import SelectInput from "components/CustomSelect/SelectInput";

class CheckBoxSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items ?? [],
      selectData: [],
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend.get(this.props.selectApiUrl).then((res) => {
      this.setState({ selectData: res.data });
    });
  }

  handleCheckboxChange = (name, isChecked, index) => {
    const newItems = [...this.state.items];
    newItems[index].checked = isChecked;
    this.setState({ items: newItems }, this.notifyParent);
  };

  handleSelectChange = (event, index) => {
    const newItems = [...this.state.items];
    newItems[index][this.props.selectValueKey] = event.target.value;
    this.setState({ items: newItems }, this.notifyParent);
  };

  notifyParent = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.items);
    }
  };

  render() {
    const { selectData, items } = this.state;
    const {
      checkBoxLabelKey,
      selectValueKey,
      selectLabel,
      selectEmptyLabelValue,
      checkBoxHintKey,
      checkBoxHintText,
    } = this.props;

    return (
      <Grid container columnSpacing={1} alignItems="center">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6}>
              <CheckBoxInput
                label={
                  item[checkBoxLabelKey] +
                  (item[checkBoxHintKey] ? " (" + checkBoxHintText + ")" : "")
                }
                name={"checkbox" + index}
                value={item.checked}
                onChange={(name, isChecked) =>
                  this.handleCheckboxChange(name, isChecked, index)
                }
              />
            </Grid>
            <Grid item xs={6}>
              {selectData.length > 0 && (
                <SelectInput
                  label={selectLabel}
                  name={"select" + index}
                  value={item[selectValueKey] ?? ""}
                  displayEmpty
                  disabled={!item.checked}
                  onChange={(e) => this.handleSelectChange(e, index)}
                >
                  <MenuItem value="">
                    <em>{selectEmptyLabelValue}</em>
                  </MenuItem>
                  {selectData.map((data, key) => (
                    <MenuItem key={key} value={data.id}>
                      {data.value}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  }
}

CheckBoxSelect.propTypes = {
  items: PropTypes.array.isRequired,
  checkBoxLabelKey: PropTypes.string.isRequired,
  checkBoxHintKey: PropTypes.string,
  checkBoxHintText: PropTypes.string,
  selectApiUrl: PropTypes.string.isRequired,
  selectValueKey: PropTypes.string.isRequired,
  selectLabel: PropTypes.string.isRequired,
  selectEmptyLabelValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default CheckBoxSelect;
