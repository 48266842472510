import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { Link } from "react-router-dom";

// @mui/material components
import withStyles from "@mui/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import DirectionsWalk from "@mui/icons-material/DirectionsWalk";
import MapsHomeWork from "@mui/icons-material/MapsHomeWork";
import Group from "@mui/icons-material/Group";
import GroupAdd from "@mui/icons-material/GroupAdd";
import Home from "@mui/icons-material/Home";
import HomeWork from "@mui/icons-material/HomeWork";
import HowToReg from "@mui/icons-material/HowToReg";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import NotificationImportant from "@mui/icons-material/NotificationImportant";
import Payment from "@mui/icons-material/Payment";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Refresh from "@mui/icons-material/Refresh";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import TrendingDown from "@mui/icons-material/TrendingDown";
import AccountBalance from "@mui/icons-material/AccountBalance";
import EuroSymbol from "@mui/icons-material/EuroSymbol";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import Build from "@mui/icons-material/Build";
import ConstructionIcon from "@mui/icons-material/Construction";
import FindInPage from "@mui/icons-material/FindInPage";
import NoteAdd from "@mui/icons-material/NoteAdd";

// core components
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import Button from "components/Button/Button.jsx";
import ExportComptable from "components/Popups/ExportComptable";
import ExportIml from "components/Popups/ExportIml";
import ExportImlMenage from "components/Popups/ExportImlMenage";
import ExportGLI from "components/Popups/ExportGLI";
import CafAnnualReviewPopup from "components/Popups/CafAnnualReviewPopup";
import CafBordereauxImportPopup from "components/Popups/CafBordereauxImportPopup";
import Render from "Utils/RenderUtils";
import ExportComptablePaiements from "components/Popups/ExportComptablePaiements";
import ExportComptableCafBordereaux from "components/Popups/ExportComptableCafBordereaux";
import Notifications from "@mui/icons-material/Notifications";
import ExportAllAvisEcheances from "components/Popups/ExportAllAvisEcheances";
import CalculDePointe from "components/Popups/CalculDePointe";
import pink from "@mui/material/colors/pink";
import purple from "@mui/material/colors/purple";
import { withUserContext } from "context/UserContext";
import SendAllAttestaionFiscale from "components/Popups/SendAllAttestaionFiscale";
import LightTooltip from "components/Tooltip/LightTooltip";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.USER = props.user;
    this.canUseTravaux = this.USER.can("use.dashboard.travaux");
    this.canUseImmobilier = this.USER.can("use.dashboard.immo");
    (this.canUseImmobilierTravaux =
      this.canUseImmobilier && this.canUseTravaux),
      (this.canUseImmobilier = this.USER.can("use.dashboard.immo"));
    this.canUseComptabilite = this.USER.can("use.comptabilite");
    this.canUseImportbordereauxcompta = this.USER.can(
      "use.importbordereauxcompta",
    );
    this.canViewComptabilite =
      this.canUseComptabilite || this.canUseImportbordereauxcompta;
    this.canUseAnnualreview = this.USER.can("use.annualreview");
    this.canUseImportbordereaux = this.USER.can("use.importbordereaux");
    this.canUseGetordresdepaiements = this.USER.can("use.getordresdepaiements");
    this.canUseGetordresdePrelevements = this.USER.can(
      "use.getordresdeprelevements",
    );
    this.canUseLocatairesecheancier = this.USER.can("use.locatairesecheancier");
    this.canEditCP = this.USER.can("edit.contratproprietaire");
    this.canViewGla =
      this.canUseAnnualreview ||
      this.canUseImportbordereaux ||
      this.canUseGetordresdepaiements ||
      this.canUseGetordresdePrelevements ||
      this.canUseLocatairesecheancier ||
      this.canEditCP;

    this.canUseExportiml = this.USER.can("use.exportiml");
    this.canUseCalculDePointe = this.USER.can("use.calculdepointe");

    this.state = {
      statistics: {},
      globalCumul: "",
      globalLoyers: "",
      globalSoldeMensuel: "",
      nombrefoyers: "",
      nombrelogementtravaux: "",
      nombreaudits: "",
      nombredossierstravaux: "",
    };
  }

  loadAsyncData() {
    if (this.canUseImmobilier) {
      axiosApiBackend.get("/reports/getglobalcumul").then((result) => {
        this.setState({
          globalCumul: result.data.globalCumul,
          globalLoyers: result.data.globalLoyers,
          globalSoldeMensuel: result.data.globalSoldeMensuel,
        });
      });
      axiosApiBackend.get("/statistics/logements").then((result) => {
        this.setState({ statistics: {} });
        this.setState({ statistics: result.data });
      });
      axiosApiBackend.get("/statistics/foyers").then((result) => {
        this.setState({ nombrefoyers: result.data.nombrefoyers });
      });
    }
    if (this.canUseTravaux) {
      axiosApiBackend.get("/statistics/logementstravaux").then((result) => {
        this.setState({
          nombrelogementtravaux: result.data,
        });
      });
      axiosApiBackend.get("/statistics/audits").then((result) => {
        this.setState({
          nombreaudits: result.data,
        });
      });
      axiosApiBackend.get("/statistics/dossierstravaux").then((result) => {
        this.setState({
          nombredossierstravaux: result.data,
        });
      });
    }
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer>
          {this.canUseImmobilier && (
            <GridItem xs={12} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: purple[500] }}>
                      <EuroSymbol />
                    </CardAvatar>
                  }
                  title={<h5 className={classes.cardCategory}>FINANCIER</h5>}
                />
                <CardContent>
                  <LightTooltip
                    title="Somme de tous les loyers du mois précédent"
                    placement="top"
                  >
                    <Typography
                      sx={{ py: 1 }}
                      variant="h5"
                      component="h4"
                      color="success.main"
                    >
                      <AccountBalance /> +{Render.euro(this.state.globalLoyers)}
                    </Typography>
                  </LightTooltip>
                  <LightTooltip
                    title="Somme de tous les soldes du mois en cours des contrats actifs"
                    placement="top"
                  >
                    <Typography
                      sx={{ py: 1 }}
                      variant="h5"
                      component="h4"
                      color="error.main"
                    >
                      <TrendingDown />{" "}
                      {Render.euro(this.state.globalSoldeMensuel)}
                    </Typography>
                  </LightTooltip>
                  <LightTooltip
                    title="Somme de tous les soldes des contrats actifs"
                    placement="top"
                  >
                    <Typography
                      sx={{ py: 1 }}
                      variant="h5"
                      component="h4"
                      color="warning.main"
                    >
                      <PeopleAlt /> {Render.euro(this.state.globalCumul)}
                    </Typography>
                  </LightTooltip>
                </CardContent>
              </Card>
            </GridItem>
          )}
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar big sx={{ bgcolor: "error.main" }}>
                    <NotificationImportant />
                  </CardAvatar>
                }
                title={<h5>NOTIFICATIONS</h5>}
              />
              <CardContent>
                <Grid container direction="column" rowSpacing={1}>
                  <Grid item>
                    <Link to="/notifications">
                      <Button
                        fullWidth
                        color="primary"
                        size="small"
                        startIcon={<Notifications />}
                      >
                        Mes notifications
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </GridItem>
          {this.canUseImmobilier && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: "prospection.main" }}>
                      <Home />
                    </CardAvatar>
                  }
                  title={
                    <h5>
                      {this.canUseImmobilierTravaux
                        ? "LOGEMENTS (Immobilier)"
                        : "LOGEMENTS"}
                    </h5>
                  }
                />
                <CardContent>
                  <Grid container direction="column" rowSpacing={1}>
                    <Grid item>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        variant="body1"
                        color="primary"
                      >
                        <DirectionsWalk />
                        &nbsp;Prospection :&nbsp;
                        <Link to="/tiers/logements?filters=prospection">
                          {this.state.statistics?.prospection}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link to="/tiers/proprietaires/ajouter">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<HowToReg />}
                        >
                          Créer un propriétaire
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/tiers/proprietaires">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<Group />}
                        >
                          Voir les propriétaires
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/tiers/logements/ajouter">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<Home />}
                        >
                          Créer un logement
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/tiers/logements">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<HomeWork />}
                        >
                          Voir les logements
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          )}
          {this.canUseImmobilier && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: "social.main" }}>
                      <AssignmentInd />
                    </CardAvatar>
                  }
                  title={<h5>CESF</h5>}
                />
                <CardContent>
                  <Grid container direction="column" rowSpacing={1}>
                    <Grid item>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        variant="body1"
                        color="primary"
                      >
                        <SupervisorAccount />
                        &nbsp;Foyers Actifs :&nbsp;
                        {this.state.nombrefoyers}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link to="/tiers/foyers/ajouter">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<GroupAdd />}
                        >
                          Créer un foyer
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/tiers/foyers">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<SupervisorAccount />}
                        >
                          Voir les foyers
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/tiers/locataires/ajouter">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<PersonAdd />}
                        >
                          Créer un locataire
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/tiers/locataires">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<PeopleAlt />}
                        >
                          Voir les locataires
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          )}
          {this.canViewComptabilite && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: "config.main" }}>
                      <InsertDriveFile />
                    </CardAvatar>
                  }
                  title={<h5 className={classes.cardCategory}>COMPTABILITÉ</h5>}
                />
                <CardContent>
                  <Grid container direction="column" rowSpacing={1}>
                    {this.canUseComptabilite && (
                      <Grid item>
                        <ExportComptable />
                      </Grid>
                    )}
                    {this.canUseComptabilite && (
                      <Grid item>
                        <ExportComptablePaiements />
                      </Grid>
                    )}
                    {this.canUseImportbordereauxcompta && (
                      <Grid item>
                        <ExportComptableCafBordereaux />
                      </Grid>
                    )}
                    {this.canUseComptabilite && (
                      <Grid item>
                        <ExportGLI />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          )}
          {this.canViewGla && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: "gla.main" }}>
                      <Description />
                    </CardAvatar>
                  }
                  title={<h5 className={classes.cardCategory}>GLA</h5>}
                />
                <CardContent>
                  <Grid container direction="column" rowSpacing={1}>
                    {this.canUseAnnualreview && (
                      <Grid item>
                        <CafAnnualReviewPopup />
                      </Grid>
                    )}
                    {this.canUseImportbordereaux && (
                      <Grid item>
                        <CafBordereauxImportPopup />
                      </Grid>
                    )}
                    {this.canUseGetordresdePrelevements && (
                      <Grid item>
                        <Link to="/banque/ordres/cl" style={{ width: "100%" }}>
                          <Button
                            fullWidth
                            color="primary"
                            size="small"
                            startIcon={<Payment />}
                          >
                            Ordres de Prélèvements CL
                          </Button>
                        </Link>
                      </Grid>
                    )}
                    {this.canUseGetordresdepaiements && (
                      <Grid item>
                        <Link to="/banque/ordres/cp" style={{ width: "100%" }}>
                          <Button
                            fullWidth
                            color="primary"
                            size="small"
                            startIcon={<Payment />}
                          >
                            Ordres de Paiements CP
                          </Button>
                        </Link>
                      </Grid>
                    )}
                    {this.canUseLocatairesecheancier && (
                      <Grid item>
                        <ExportAllAvisEcheances />
                      </Grid>
                    )}
                    {this.canEditCP && (
                      <Grid item>
                        <SendAllAttestaionFiscale />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          )}
          {this.canUseExportiml && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: pink[500] }}>
                      <Description />
                    </CardAvatar>
                  }
                  title={
                    <h5>
                      {this.canUseImmobilierTravaux
                        ? "EXPORTS (immobilier)"
                        : "EXPORTS"}
                    </h5>
                  }
                />
                <CardContent>
                  <Grid container direction="column" rowSpacing={1}>
                    {this.canUseExportiml && (
                      <Grid item>
                        <ExportIml />
                      </Grid>
                    )}
                    {this.canUseExportiml && (
                      <Grid item>
                        <ExportImlMenage />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          )}
          {this.canUseCalculDePointe && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: "warning.main" }}>
                      <Refresh />
                    </CardAvatar>
                  }
                  title={
                    <h5>
                      {this.canUseImmobilierTravaux
                        ? "ACTIONS (immobilier)"
                        : "ACTIONS"}
                    </h5>
                  }
                />
                <CardContent>
                  <Grid container direction="column" rowSpacing={1}>
                    {this.canUseCalculDePointe && (
                      <Grid item>
                        <CalculDePointe />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          )}
          {this.canUseTravaux && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: "prospection.main" }}>
                      <Home />
                    </CardAvatar>
                  }
                  title={
                    <h5>
                      {this.canUseImmobilierTravaux
                        ? "LOGEMENTS (travaux)"
                        : "LOGEMENTS"}
                    </h5>
                  }
                />
                <CardContent>
                  <Grid container direction="column" rowSpacing={1}>
                    <Grid item>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        variant="body1"
                        color="primary"
                      >
                        <MapsHomeWork />
                        &nbsp;Logements :&nbsp;
                        {this.state.nombrelogementtravaux}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link to="/primo-contact">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<PersonAdd />}
                        >
                          Primo contact
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/beneficiaires/ajouter">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<HowToReg />}
                        >
                          Créer un bénéficiaire
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/beneficiaires">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<Group />}
                        >
                          Voir les bénéficiaires
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/logements-travaux/ajouter">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<Home />}
                        >
                          Créer un logement
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/logements-travaux">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<HomeWork />}
                        >
                          Voir les logements
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          )}
          {this.canUseTravaux && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: "prospection.main" }}>
                      <Build />
                    </CardAvatar>
                  }
                  title={
                    <h5 className={classes.cardCategory}>DOSSIER TRAVAUX</h5>
                  }
                />
                <CardContent>
                  <Grid container direction="column" rowSpacing={1}>
                    <Grid item>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        variant="body1"
                        color="primary"
                      >
                        <Build />
                        &nbsp;Dossier Travaux :&nbsp;
                        {this.state.nombredossierstravaux}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link to="/primo-contact">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<PersonAdd />}
                        >
                          Primo contact
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/dossiers-travaux/ajouter">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<ConstructionIcon />}
                        >
                          Créer un dossier travaux
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/dossiers-travaux">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<ConstructionIcon />}
                        >
                          Voir les dossiers travaux
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          )}
          {this.canUseTravaux && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <CardAvatar big sx={{ bgcolor: "prospection.main" }}>
                      <FindInPage />
                    </CardAvatar>
                  }
                  title={
                    <h5 className={classes.cardCategory}>AUDITS/DIAGNOSTICS</h5>
                  }
                />
                <CardContent>
                  <Grid container direction="column" rowSpacing={1}>
                    <Grid item>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        variant="body1"
                        color="primary"
                      >
                        <FindInPage />
                        &nbsp;Audits/Diagnostics :&nbsp;
                        {this.state.nombreaudits}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link to="/audits/ajouter">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<NoteAdd />}
                        >
                          Créer un audit/diagnostic
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/audits">
                        <Button
                          fullWidth
                          color="primary"
                          size="small"
                          startIcon={<FindInPage />}
                        >
                          Voir les audits/diagnostics
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default withStyles(styles)(withUserContext(Dashboard));
