import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/icons-material
import Chat from "@mui/icons-material/Chat";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import AllIcons from "variables/AllIcons";
import Box from "@mui/system/Box";
import MenuItem from "@mui/material/MenuItem";
import Render from "Utils/RenderUtils";
import { axiosApiBackend } from "variables/axiosConfigs";
import EvenementIcon from "components/Evenements/EvenementIcon";
import ColorPicker from "components/ColorPicker/ColorPicker";
import SelectInput from "components/CustomSelect/SelectInput";
import Typography from "@mui/material/Typography";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import EvenementIconRenderer from "components/CustomAgRenderer/EvenementIconRenderer";

class ConfigEvenement extends Component {
  constructor(props) {
    super(props);
    this.LABEL = "évènement(s)";

    this.state = {
      id: "",
      value: "",
      color: "#bdbdbd",
      icon: "event",
      editable: false,
      type_model_id: "",
      create_from_model_ids: [],
      display_on_model_ids: [],
      typeModels: [],
      update_type_event_id: "",
      errors: null,
      existing_relationships: [], // For display_on_model_ids
      existing_active_relationships: [], // For create_from_model_ids
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    this.getTypeModels();
  }

  getTypeModels = () => {
    axiosApiBackend
      .get("/type-model?section=" + this.props.section)
      .then((result) => {
        this.setState({
          typeModels: result.data,
        });
      });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleTypeModel = (event) => {
    const modelId = event.target.value;
    const selectedModel = this.state.typeModels.find(
      (model) => model.id === modelId,
    );

    this.setState({
      [event.target.name]: modelId,
      existing_relationships: selectedModel?.existing_relationships ?? [],
      existing_active_relationships:
        selectedModel?.existing_active_relationships ?? [],
      create_from_model_ids: [],
      display_on_model_ids: [],
    });
  };

  handleAutoComplete = (event, value) => {
    this.setState({ icon: value });
  };

  handleChangeColor = (value) => {
    this.setState({ color: value });
  };

  renderModelOptions = () => {
    return this.state.typeModels.map((model, key) => (
      <MenuItem key={key} value={model.id}>
        {Render.modelPath(model.value, true)}
      </MenuItem>
    ));
  };

  renderCreateOrDisplayOptions = (existingRelationships) => {
    const { typeModels } = this.state;

    return typeModels
      .filter((model) => existingRelationships.includes(model.value))
      .map((model, key) => (
        <MenuItem key={key} value={model.id}>
          {Render.modelPath(model.value, true)}
        </MenuItem>
      ));
  };

  render() {
    const { section } = this.props;
    const {
      id,
      existing_relationships,
      existing_active_relationships,
      errors,
      editable,
    } = this.state;
    const disableSelectModel = id ? true : false;

    return (
      <TablePopUpContainer
        icon={<Chat />}
        dialogProps={{
          fullWidth: true,
        }}
        title="Types d'évènements"
        autoSize={false}
        backendUrl="/config-type-evenement"
        getParams={{
          section: section,
          append: true,
          with_same_model: true,
          with_count_evenements: true,
        }}
        colDef={[
          { field: "id", editable: false, hide: true },
          {
            headerName: "Libellé",
            field: "value",
            flex: 2,
          },
          {
            headerName: "Entité attachée",
            field: "typemodel.value",
            flex: 2,
            valueFormatter: (params) => Render.modelPath(params.value, true),
          },
          {
            headerName: "Création depuis",
            field: "create_from_models",
            flex: 3,
            valueFormatter: (params) =>
              Render.modelPathArray(params.value, true),
          },
          {
            headerName: "Affichage sur",
            field: "display_on_models",
            flex: 3,
            valueFormatter: (params) =>
              Render.modelPathArray(params.value, true),
          },
          {
            headerName: "Icône",
            field: "icon",
            flex: 1,
            cellRenderer: EvenementIconRenderer,
            cellRendererParams: (params) => ({
              color: params.data.color,
              icon: params.data.icon,
            }),
          },
        ]}
        formInitData={() => {
          this.setState({
            id: "",
            value: "",
            color: "#bdbdbd",
            icon: "event",
            editable: false,
            type_model_id: "",
            create_from_model_ids: [],
            display_on_model_ids: [],
            existing_relationships: [],
            existing_active_relationships: [],
            errors: null,
          });
        }}
        formSetData={(data) => {
          const selectedModel = this.state.typeModels.find(
            (model) => model.id === data.type_model_id,
          );

          this.setState({
            id: data.id ?? "",
            value: data.value ?? "",
            color: data.color ?? "#bdbdbd",
            icon: data.icon ?? "event",
            editable: data.editable ?? false,
            type_model_id: data.type_model_id ?? "",
            create_from_model_ids: data.create_from_model_ids ?? [],
            display_on_model_ids: data.display_on_model_ids ?? [],
            existing_relationships: selectedModel?.existing_relationships ?? [],
            existing_active_relationships:
              selectedModel?.existing_active_relationships ?? [],
            errors: null,
          });
        }}
        formGetData={() => ({
          id: this.state.id,
          value: this.state.value,
          color: this.state.color,
          icon: this.state.icon,
          type_model_id: this.state.type_model_id,
          create_from_model_ids: this.state.create_from_model_ids,
          display_on_model_ids: this.state.display_on_model_ids,
        })}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        onCloseDeleteModal={() => this.setState({ update_type_event_id: "" })}
        deleteParams={{
          update_type_event_id: this.state.update_type_event_id,
        }}
        getDeleteFormContent={(data) => {
          const typeEventsWithSameModel = data?.types_with_same_model ?? [];

          if (data.evenements_count < 1) {
            return <Typography>{data.value}</Typography>;
          }

          return (
            <>
              <Typography>
                Vous pouvez choisir de transférer les événements associés à ce
                type vers un autre type d&apos;événement équivalent avant de
                procéder à sa suppression.
              </Typography>
              <SelectInput
                label="Transférer vers"
                name="update_type_event_id"
                onChange={this.handleChange}
                displayEmpty
                value={this.state.update_type_event_id ?? ""}
                error={Boolean(errors?.update_type_event_id)}
              >
                <MenuItem value="">
                  <em>Ne pas transférer</em>
                </MenuItem>
                {typeEventsWithSameModel.map((typeEvent) => (
                  <MenuItem key={typeEvent.id} value={typeEvent.id}>
                    {typeEvent.value}
                  </MenuItem>
                ))}
              </SelectInput>
            </>
          );
        }}
        getDeleteError={(data, error) => {
          if (!error) {
            return (
              data.value +
              " ne peut pas être supprimé car il est utilisé dans des traiements automatiques"
            );
          }

          return (
            (data.value ?? "") +
            " est associé à " +
            (error ?? "") +
            " " +
            this.LABEL
          );
        }}
        formTitle={this.state.id ? "Modifier le type" : "Ajouter un type"}
        formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
        formContent={
          <GridContainer alignItems="center">
            <GridItem xs={12} lg={6}>
              <TextField
                label="Libellé"
                name="value"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.value}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.value)}
                disabled={Boolean(this.state.id) && !editable}
              />
            </GridItem>
            <GridItem xs={12} lg={6}>
              <SelectInput
                label={
                  <>
                    Lié à un{" "}
                    <InfoLightTooltip title="Une fois créé ce champ ne pourra plus être modifié." />
                  </>
                }
                name="type_model_id"
                value={this.state.type_model_id ?? ""}
                onChange={this.handleTypeModel}
                disabled={disableSelectModel}
                error={Boolean(errors?.type_model_id)}
              >
                {this.renderModelOptions()}
              </SelectInput>
            </GridItem>
            <GridItem xs={12}>
              <SelectInput
                label="Création depuis"
                name="create_from_model_ids"
                value={this.state.create_from_model_ids ?? []}
                onChange={this.handleChange}
                multiple
                error={Boolean(errors?.create_from_model_ids)}
              >
                {this.renderCreateOrDisplayOptions(
                  existing_active_relationships,
                )}
              </SelectInput>
            </GridItem>
            <GridItem xs={12}>
              <SelectInput
                label="Affichage sur"
                name="display_on_model_ids"
                value={this.state.display_on_model_ids ?? []}
                onChange={this.handleChange}
                multiple
                error={Boolean(errors?.display_on_model_ids)}
              >
                {this.renderCreateOrDisplayOptions(existing_relationships)}
              </SelectInput>
            </GridItem>
            <GridItem xs={12} lg={4}>
              <ColorPicker
                label="Couleur de l'icône"
                name="color"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.color}
                onChange={this.handleChange}
                onChangeColor={this.handleChangeColor}
                error={Boolean(errors?.color)}
              />
            </GridItem>
            <GridItem xs lg={6}>
              <Autocomplete
                disableClearable
                options={AllIcons}
                onChange={this.handleAutoComplete}
                value={this.state.icon}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <Icon>{option}</Icon>&nbsp;{option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Icône"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...params}
                  />
                )}
              />
            </GridItem>
            <GridItem lg={2}>
              <EvenementIcon color={this.state.color} icon={this.state.icon} />
            </GridItem>
          </GridContainer>
        }
      />
    );
  }
}

ConfigEvenement.propTypes = {
  section: PropTypes.oneOf(["immobilier", "travaux"]).isRequired,
};

export default ConfigEvenement;
